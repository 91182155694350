import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(@Inject(PLATFORM_ID) private platformId: object) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                map((event: any) => {
                    if (event instanceof HttpResponse) {
                        const responseBody = event.body || '';
                        // console.log('Event requested in the responseInterceptor: ', event, responseBody, typeof responseBody);
                        if (typeof responseBody === 'string' && responseBody !== '') {
                            event = event.clone({body: JSON.parse(responseBody)});
                        }
                        return event;
                    }
                }),
                catchError((err: HttpErrorResponse) => {
                    return throwError(err);
                })
            );
    }
}

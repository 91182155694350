<app-banner></app-banner>

<div class="container section mt-4 pt-5">
    <div class="row">
        <div class="col-lg-4 text-center">
            <picture class="img-responsive margin-bottom-15">
                <source srcset="/assets/images/about-img.webp 1x, /assets/images/about-img.webp 2x"
                        type="image/webp">
                <source srcset="/assets/images/about-img.jpg 1x, /assets/images/about-img.jpg 2x"
                        type="image/jpeg">
                <img [style]="{width: '320px'}"
                     alt="Echipa medicala Cluj"
                     src="/assets/images/about-img.jpg"
                     title="Echipa Medici Centrului Medical Review">
            </picture>
        </div>
        <div class="col-lg-8 px-lg-4">
            <h2 class="h1">Terapie hiperbară la Centrul Medical REVIEW</h2>
            <p>Îți propunem o nouă abordare pentru sănătatea ta: terapie hiperbară într-un spațiu sigur, confortabil și ultramodern. Te poți
                bucura de beneficiile unui program de terapie hiperbară chiar în inima Transilvaniei, în Cluj, la Centrul Medical
                Review. </p>
            <p>Pentru noi, halatul pe care îl îmbrăcăm zi de zi reprezintă încrederea pacienților în profesia medicală, precum și o
                responsabilitate de a le depăși așteptările. În acest sens, am depus toate eforturile necesare pentru ca procedurile de
                terapie hiperbară să se desfășoare în cele mai bune condiții.
            </p>
            <p>Prin achiziționarea de aparatură de înaltă performanță și o cameră hiperbarică performantă, Centrul Medical Review reușește
                să se afirme drept o clinică de referință în tratarea diverselor patologii cu ajutorul procedurilor de terapie hiperbară.
                Ne-am propus să redefinim modul în care serviciile medicale de terapie hiperbară se desfășoară – atât pentru pacienții
                noștri din Cluj, cât și pentru cei care aleg să ne viziteze din alte orașe și regiuni ale țării.
            </p>
            <p><strong>Oxygen Review</strong> reprezintă un departament din Centrul Medical Review din Cluj, deschis în anul 2020,
                specializat pe terapie hiperbară și menit să oferă o nouă abordare în tratamentul diverselor afecțiuni cu ajutorul acestei
                terapii. Personalul calificat, o echipă de specialiști aflați sub îndrumarea </p>
            <p>Pentru mai multe informații despre Centrul Medical Review te invităm să vizitezi pagina
                <a [title]="'Continuati spre pagina despre centrulmedicalreview.com'"
                   class="nav-link d-inline-block"
                   href="https://centrulmedicalreview.com/"
                   rel="noopener"
                   target="_blank">
                    <i class="fa fa-link"></i>
                    centrulmedicalreview.com</a>.</p>
        </div>
    </div>
    <div class="row my-3">
        <div class="col d-flex justify-content-center align-content-center">
            <div style="width: 100%; min-width: 400px; max-width: 800px;">
                <div style="position: relative; width: 100%; overflow: hidden; padding-top: 56.25%;">
                    <p><iframe
                        style="position: absolute; top: 0; left: 0; right: 0; width: 100%; height: 100%; border: none;"
                        src="https://www.youtube.com/embed/8IiSRiFDca8?si=iHFsJLX1Oute18gl"
                        width="560" height="315"
                        allowfullscreen="allowfullscreen"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid section mt-5 py-5 bg-light">
    <div class="row">
        <div class="col px-5">
            <h1>Ce presupun ședințele de terapie hiperbară?</h1>

            <p>Când vorbim de terapie hiperbară, ne referim la un tratament medical care presupune <strong>furnizarea de oxigen pur pentru
                organism,
                într-un mediu sub presiune mai mare decât cea normală.</strong> În acest sens, o cameră hiperbarică și aparatură
                profesională care să asigure aceste condiții sunt necesare.
                Ședințele de terapie hiperbară se desfășoară într-o manieră non-invazivă, permițând <strong>creșterea nivelului de
                    oxigen</strong> în toate zonele din organism.
            </p>
            <p>Bineînțeles, cei mai mulți oameni se gândesc automat la o cameră hiperbarică și scafandri cu boală de decompresie.
                Însă <a [routerLink]="['/terapie-cu-oxigen-hiperbaric']"
                        [title]="'Continuati spre pagina despre HBOT'"
                        class="nav-link d-inline-block">
                    <i class="fa fa-link"></i>
                    terapia cu oxigen hiperbaric</a> oferă numeroase alte beneficii medicale pentru o listă lungă de afecțiuni. La Centrul
                Medical Review din Cluj, Dr.
                Demea și echipa sa lucrează neîncetat pentru a găsi planul ideal de terapie hiperbară pentru fiecare pacient în parte, în
                funcție de situația medicală a fiecăruia.
            </p>
            <p>
                Pe măsură ce procedurile de terapie hiperbară au devenit tot mai cunoscute,
                au devenit disponibile <strong>diverse tipuri de cameră hiperbarică</strong>
                – cameră hiperbară cu pereți moi sau cameră hiperbară cu carcasă tare.
                Atenție, însă: deși o soluție atractivă, o cameră hiperbară cu pereți moi nu asigură aceleași
                beneficii pe care o cameră hiperbară profesională, cu o carcasă tare, le oferă.
                La Centrul Medical Review din Cluj, Dr. Demea și echipa sa utilizează Baroks O2mono – o cameră
                hiperbară cu carcasă tare, monoloc, de calitate superioară.
            </p>
            <a [routerLink]="['/terapie-cu-oxigen-hiperbaric']"
               [title]="'Continuati spre pagina despre HBOT'"
               class="nav-link">
                <i class="fa fa-link"></i>
                Citește mai departe
            </a>
        </div>
    </div>
</div>

<div class="container section mt-4 pt-5">
    <div class="row">
        <div class="col-lg-4 text-center">
            <picture [style]="{width: '320px'}" class="img-responsive margin-bottom-15">
                <source srcset="/assets/images/hbot-treatment-in-progress.webp 1x, /assets/images/hbot-treatment-in-progress.webp 2x"
                        type="image/webp">
                <source srcset="/assets/images/hbot-treatment-in-progress.jpg 1x, /assets/images/hbot-treatment-in-progress.jpg 2x"
                        type="image/jpeg">
                <img [style]="{width: '320px'}"
                     alt="Imagine de ansamblu aparat terapie hiperbară"
                     src="/assets/images/hbot-treatment-in-progress.jpg"
                     title="Echipament de terapie hiperbară de ultimă generație">
            </picture>
        </div>
        <div class="col-lg-8 px-lg-4">
            <h2 class="h1">Ce tratăm prin terapie hiperbară în Cluj?</h2>
            <p>„Aprobat de FDA” sau „În afara indicațiilor autorizate” sunt două concepte pe care le vei întâlni în momentul în care te
                decizi să iei în considerare un plan de tratament bazat pe terapie hiperbară (și nu numai). Dr. Demea și echipa sa de la
                Centrul Medical Review din Cluj vor avea însă grijă să îți explice exact ce presupun aceste aprobări oficiale și ce
                implicații au ele în ceea ce privește îngrijirea ta medicală.
            </p>
            <p>În prezent, există numeroase
                <strong>afecțiuni pentru care tratamentele HBOT sunt aprobate de FDA</strong>
                – de la insuficiențe arteriale, anemie severă, grefe și lambouri compromise,
                până la arsuri, mionecroză clostridială, abces intracranian sau osteomielită refractară.
                Pe lângă acestea, procedurile de terapie hiperbară aduc rezultate excelente în tratarea
                și altor boli și afecțiuni – cum sunt scleroza multiplă, boala Lyme, Alzheimer,
                accidente vasculare cerebrale, fibromialgie, migrene, SDRC, traumatisme cerebrale, leziuni sportive,
                tulburare de stres post-traumatic și multe altele.
            </p>
            <a [routerLink]="['/tratamente-si-beneficii']"
               [title]="'Citiți mai departe despre afecțiunile tratate'"
               class="nav-link">
                <i class="fa fa-link"></i>
                Citește mai departe
            </a>
        </div>
    </div>
</div>


<div class="container-fluid section mt-5 pt-5 pb-3 bg-light">
    <div class="row">
        <div class="col px-5">
            <h2 class="h1">Care sunt beneficiile HBOT?</h2>
            <p>
                Un plan de tratament eficient de terapie hiperbară, cu ședințe desfășurate într-o cameră hiperbarică profesională, asigură
                numeroase avantaje organismului – de la creșterea producției de neurotransmițători, eliminarea zonelor de hipoxie din
                organism, stimularea țesutului conjunctiv (deci vindecarea mai rapidă a rănilor), până la stimularea răspunsului sistemului
                imunitar, efecte antiinflamatorii, inhibarea dezvoltării anumitor bacterii sau creșterea capacității de a elimina corpuri
                străine din fluxul sanguin (bacterii, ciuperci, celule moarte etc).
            </p>
            <p>
                Centrul Medical Review își bazează activitatea de terapie hiperbară pe beneficii fundamentate științific – prin studii și
                demersuri de cercetare actualizate. Astfel, poți găsi informații despre beneficiile HBOT (tratament cu oxigen hiperbaric)
                pentru fiecare afecțiune pe care o tratăm în cadrul centrului nostru medical.
            </p>
            <a [routerLink]="['/tratamente-si-beneficii']"
               [title]="'Citiți mai departe despre afecțiunile tratate'"
               class="nav-link">
                <i class="fa fa-link"></i>
                Descoperă lista de afecțiuni
            </a>
        </div>
    </div>
</div>

<div class="container-fluid section mt-5 pb-3">
    <div class="row">
        <div class="col px-5">
            <h2 class="h1">Cum decurge o ședință de terapie hiperbară?</h2>
            <p>
                În primul rând, dacă te gândești să iei în considerare un plan de tratament și terapie hiperbară la Centrul Medical Review
                din Cluj și dorești să afli mai multe informații despre cum te-am putea sprijini în demersul pentru o stare de sănătate mai
                bună, te invităm să completezi <a [routerLink]="['/contact']"
                                                  [title]="'Continuati spre pagina de contact'"
                                                  class="nav-link d-inline-block"> <i class="fa fa-link"></i>
                formularul de contact </a> sau să ne apelezi telefonic pentru a stabili o
                <strong>programare</strong>.
                În cadrul <strong>primei consultații</strong>, pornind de la istoricul tău medical, condiția medicală și starea de sănătate,
                vom stabili <strong>planul de tratament</strong> potrivit, inclusiv numărul de ședințe de terapie hiperbară necesar pentru a
                obține beneficiile dorite.
                Acesta poate varia de la 2-3 ședințe, până la 20 - 40 de ședințe, în funcție de gravitatea și tipul afecțiunii.
            </p>
            <strong>O ședință de terapie hiperbară va decurge astfel:</strong>
            <ul>
                <li>Te pregătești pentru sesiune, respectând îndrumările primite; de exemplu, ai grijă să nu introduci în camera hiperbarică
                    obiecte ce conțin baterii, nici brichete sau chibrituri, nu folosești creme sau alte produse cosmetice care conțin
                    petrol și derivați.
                <li>Vei ocupa locul destinat în camera hiperbarică din Centrul Medical Review Cluj. Camera hiperbarică pe care o utilizăm
                    este un model Baroks O2mono – adică o cameră hiperbară cu carcasă tare, monoloc, profesională, sigură și eficientă.
                <li>În camera hiperbară ai la dispoziție diverse opțiuni recreative: poți să asculți muzică sau să vizionezi un film. Dacă
                    preferi, poți să dormi.
                <li>Pe parcursul tratamentului din camera hiperbară este posibil să simți o oarecare senzație de înfundare a urechilor, iar
                    ulterior – foame sau puțină oboseală.
                <li>Ședința în camera hiperbarică durează aproximativ 2 ore.
            </ul>
            <p>
                Dorești mai multe informații despre tot ce înseamnă o terapie hiperbară și cum te poți bucura de beneficiile acesteia în
                Cluj?
                <a [routerLink]="['/contact']"
                   [title]="'Continuati spre pagina de contact'"
                   class="nav-link">
                    <i class="fa fa-link"></i>
                    Contactează-ne chiar acum!
                </a>
            </p>
        </div>
    </div>
</div>



<button (click)="topFunction()"
        class="btn btn-success"
        id="myBtn"
        title="Înapoi la început">Sus
</button>

<div class="container pt-5 pb-5">
    <div class="row">
        <div class="col">
            <h1>Ce este terapia cu oxigen hiperbaric?</h1>
            <p>Terapia cu oxigen hiperbaric este un tratament medical cu oxigen hiperbaric care furnizează oxigen pur organismului în medii
                sub înaltă presiune – o presiune mai mare decât cea normală.
            </p>
            <p>Tratament neinvaziv, terapia cu oxigen hiperbaric permite creșterea nivelului de oxigen în toate zonele corpului. În timp ce
                mulți oameni se gândesc automat la camerele de oxigen hiperbaric și scafandri cu boală de decompresie, terapia cu oxigen
                hiperbaric asigură, de fapt, enorm de multe beneficii medicale pentru o gamă largă de afecțiuni.
            </p>
            <p>Aici, la Clinica Review Cluj, Dr. Demea și echipa sa lucrează cu dedicare și meticulozitate pentru a găsi planul ideal în
                ceea ce privește oxigenoterapia hiperbară potrivită pentru tine și pentru starea ta medicală.
            </p>

            <h2>Ce sunt camerele de oxigen hiperbaric?</h2>

            <p>Camerele de oxigen hiperbaric permit livrarea de oxigen pur de înaltă presiune către un pacient. La
                <a [routerLink]="'/'" class="nav-link d-inline-block p-0"
                   title="'Centrul Medical Review'">
                    <i class="fa fa-link mt-0 mb-0"></i> Centrul Medical Review</a>,
                oxigenoterapia hiperbară se face cu ajutorul unei camere monoloc.
            </p>

            <p> Aceasta are structura unui tub transparent, din plexiglas, care permite pacientului să stea întins confortabil în timp ce
                ascultă muzică sau se uită la televizor. Camera este umplută cu oxigen pur la o presiune a aerului mai mare decât cea
                normală.
            </p>

            <p>De exemplu, în cazurile de vindecare a rănilor, o mono-cameră oferă un avantaj prin faptul că oxigenul intră direct prin
                suprafața corpului, precum și prin respirație.
            </p>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12">
            <h2>Cum lucrează oxigenul în corp?</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 d-flex justify-content-center flex-row pt-2">
            <picture [style]="{'max-height': '200px'}" class="img-responsive margin-bottom-15">
                <source srcset="/assets/images/docs/celule-sange.webp 1x, /assets/images/docs/celule-sange.webp 2x"
                        type="image/webp">
                <source srcset="/assets/images/docs/celule-sange.png 1x, /assets/images/docs/celule-sange.png 2x"
                        type="image/png">
                <img [style]="{'max-height': '200px'}"
                     alt="Celule sânge"
                     src="/assets/images/docs/celule-sange.png"
                     title="Cum lucrează oxigenul in corp">
            </picture>
        </div>
        <div class="col-md-8">
            <p> Corpul tău are nevoie de oxigen pentru a supraviețui, cu siguranță știi deja acest lucru. În termeni simpli, pe măsură ce
                respiri, oxigenul se deplasează în plămâni. Apoi, oxigenul trece prin mici saci de aer din plămâni, numiți alveole. Odată
                ajuns acolo, oxigenul se transferă în vasele de sânge capilare, unde hemoglobina din celulele roșii din sânge transportă
                oxigenul. Oxigenul, transportat în hemoglobina sângelui, se deplasează apoi către inimă, unde trece, pentru a livra oxigen
                către celulele din tot corpul. Mitocondriile celulei convertesc apoi oxigenul în energie pentru a alimenta celulele
                specifice. În condiții normale, oxigenul este transportat de hemoglobină și doar cantități foarte mici de urme sunt
                transportate de plasma din sânge.
            </p>
            <p class="py-3 px-3 border border-dark bg-success text-white">
                Nu ești sigur dacă terapia cu oxigen hiperbaric te poate ajuta?
                <a [routerLink]="'/contact'" class="nav-link d-inline-block p-0 text-white"
                   title="'Continuați spre pagina de contact'">
                    <i class="fa fa-link mt-0 mb-0"></i> Programează-te</a> și putem stabili împreună un plan de tratament adecvat situației
                tale medicale! </p>
        </div>
    </div>


    <div class="row mt-5">
        <div class="col">
            <h2> În ce mod este diferită terapia cu oxigen hiperbaric de respirația obișnuită? </h2>
            <p>
                Oxigenul reprezintă aproximativ 21% din compoziția aerului pe care îl respiri în fiecare zi. Cu toate acestea, cea mai mare
                parte a aerului pe care îl respiri este, de fapt, azot. Cât de mult oxigen respiri depinde și de presiunea atmosferică în
                care te afli. De exemplu, la nivelul mării, presiunea atmosferică este la un ATA = 760 mm Hg sau 14,7 psi. Pe măsură ce urci
                în altitudine, presiunea scade, ceea ce face ca corpului să îi fie mai greu să obţină aportul de oxigen. Zonele aflate cu
                mult deasupra nivelului mării experimentează aer cu o concentrație redusă de oxigen. În consecință, corpul se luptă să
                absoarbă cât mai mult oxigen din aer.
            </p>
            <p>
                Oxigenoterapia hiperbară asigură într-un sistem HBOT ca aerul să fie 100% oxigen și presiunea atmosferică crește, permițând
                o absorbție mai mare de oxigen de către organism cu fiecare respirație. Cantitatea crescută de oxigen este preluată atât de
                hemoglobina din sânge, cât și de plasmă. Acest lucru permite o mai mare distribuție a oxigenului în tot corpul și în zonele
                care pot avea nevoie de mai multă energie, cum ar fi rănile în curs de vindecare.
            </p>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12">
            <h2>Avantaje: Ce face terapia cu oxigen hiperbaric în corp?</h2>
        </div>
        <div class="col-md-4 d-flex justify-content-center flex-row pt-2">
            <picture [style]="{'max-height': '250px'}" class="img-responsive margin-bottom-15">
                <source srcset="/assets/images/docs/echipament-hbot1.webp 1x, /assets/images/docs/echipament-hbot1.webp 2x"
                        type="image/webp">
                <source srcset="/assets/images/docs/echipament-hbot1.png 1x, /assets/images/docs/echipament-hbot1.png 2x"
                        type="image/png">
                <img [style]="{'max-height': '250px'}"
                     alt="Echipament HBOT"
                     src="/assets/images/docs/echipament-hbot1.png"
                     title="Ce face HBOT în corp?">
            </picture>
        </div>
        <div class="col-md-8">
            <p> Oxigenoterapia hiperbară oferă multe beneficii organismului: </p>

            <ul class="">
                <li>Crește nivelul de oxigen din organism de până la 10 ori cantitatea normală;</li>
                <li>Permite oxigenului să treacă în creier, crescând producția și disponibilitatea neurotransmițătorilor;</li>
                <li>Crește numărul de celule stem aflate în circulație în tot corpul;</li>
                <li>Elimină zonele de hipoxie prin stimularea creșterii capilare;</li>
                <li>Oxigenoterapia hiperbară stimulează, totodată, celulele țesutului conjunctiv, care la rândul lor stimulează vindecarea
                    rănilor și creșterea pielii noi;
                </li>
                <li>Stimulează răspunsul sistemului imunitar – cu alte cuvinte: îți ajuți corpul să se vindece singur;</li>
                <li>Generează efecte antiinflamatorii asupra organismului – cum ar fi la nivelul inflamației articulațiilor;</li>
                <li>Oxigenoterapia hiperbară ajută la tratarea traumatismelor și a leziunilor sportive;</li>
                <li>Declanșează răspunsul de vindecare la leziunile provocate de radiații la nivelul oaselor, țesuturilor moi și
                    organelor;
                </li>
                <li>Inhibă dezvoltarea unor bacterii și ucide organismele anaerobe care nu tolerează oxigenul – cum ar fi cele care cauzează
                    boala Lyme;
                </li>
                <li>Oxigenoterapia hiperbară îmbunătățește performanța unor antibiotice și medicamente;</li>
                <li>Îndepărtează bulele de gaz care cauzează decompresia din fluxul sanguin;</li>
                <li>Oxigenoterapia hiperbară crește capacitatea de eliminare a corpurilor străine din fluxul sanguin, inclusiv a
                    bacteriilor, ciupercilor, celulelor moarte și deșeurilor de produse secundare, reducând astfel riscul de infecție.
                </li>
            </ul>
            <p class="py-3 px-3 border border-dark bg-success text-white">
                Vrei să afli mai multe informații despre terapia cu oxigen hiperbaric? Clinica noastră îți stă la dispoziție.
                <a [routerLink]="'/contact'" class="nav-link d-inline-block p-0 text-white"
                   title="'Continuați spre pagina de contact'">
                    <i class="fa fa-link mt-0 mb-0"></i> Programează-te</a> fără ezitări!</p>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col">
            <h3>Este terapia cu oxigen hiperbaric potrivită pentru mine?</h3>
            <p>
                Terapia cu oxigen hiperbaric (sau oxigenoterapia hiperbară) oferă multe beneficii și opțiuni de tratament promițătoare
                pentru o varietate de afecțiuni medicale diferite. De fapt, Administrația pentru Medicamente și Alimente din Statele Unite
                ale Americii (<i>Food and Drug Administration</i> – FDA) a determinat și aprobat până acum <b>14 afecţiuni</b>
                diferite pentru
                <a [routerLink]="'/tratamente-si-beneficii-hbot'"
                   class="nav-link d-inline-block p-0"
                   title="'Continuați spre pagina de tratamente si beneficii HBOT'">
                    <i class="fa fa-link mt-0 mb-0"></i> tratamente HBOT</a>.
                Aceste afecţiuni aprobate de FDA sunt de obicei acoperite de majoritatea asigurărilor.
            </p>
            <p>
                În plus față de afecţiunile aprobate de FDA, terapia cu oxigen hiperbaric a demonstrat succes în tratarea multor alte
                afecţiuni. De exemplu, multe tratamente și medicamente farmaceutice sunt folosite în fiecare zi în afara indicaţiilor
                autorizate, cu mare succes. Centrul Medical Review tratează numeroase condiții și afecțiuni medicale apelând la
                oxigenoterapia hiperbară.
            </p>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12">
            <h3>Afecţiuni care beneficiază de terapia cu oxigen hiperbaric</h3>
            <p>
                Prin administrarea unui nivel crescut de oxigen sub presiune în organism, oxigenoterapia hiperbară asigură o serie de
                beneficii în cazul mai multor boli și afecțiuni.
            </p>
        </div>
        <div class="col-md-4 d-flex justify-content-center flex-row pt-2">
            <picture [style]="{'max-height': '300px'}" class="img-responsive margin-bottom-15">
                <source srcset="/assets/images/docs/echipament-hbot3.webp 1x, /assets/images/docs/echipament-hbot3.webp 2x"
                        type="image/webp">
                <source srcset="/assets/images/docs/echipament-hbot3.png 1x, /assets/images/docs/echipament-hbot3.png 2x"
                        type="image/png">
                <img [style]="{'max-height': '300px'}"
                     alt="Echipament HBOT"
                     src="/assets/images/docs/echipament-hbot3.png"
                     title="Avantaje HBOT">
            </picture>
        </div>
        <div class="col">
            <p class="font-weight-bold">
                FDA a aprobat terapia cu oxigen hiperbaric în cazul următoarelor 14 afecțiuni:
            </p>

            <ul class="">
                <li>Embolie aeriană sau gazoasă</li>
                <li>Intoxicare cu monoxid de carbon</li>
                <li>Miozită și mionecroză clostridială (cangrenă gazoasă)</li>
                <li>Leziuni prin zdrobire, sindromul de compartiment și alte ischemii traumatice acute</li>
                <li>Boala de decompresie, adesea observată la scafandri, de exemplu</li>
                <li>Insuficiențe arteriale, cum ar fi ocluzia arterială retiniană centrală</li>
                <li>Anemie severă</li>
                <li>Abces intracranian</li>
                <li>Infecții ale țesuturilor moi necrotizante</li>
                <li>Osteomielită (refractară)</li>
                <li>Leziune întârziată la radiații (țesut moale și necroză osoasă)</li>
                <li>Grefe și lambouri compromise</li>
                <li>Leziune provocată de arsură termică acută</li>
                <li>Pierderea idiopatică subită a auzului senzorineural</li>
            </ul>

            <p>
                Pe lângă acestea, multe alte afecțiuni medicale, leziuni și probleme generale de sănătate au beneficii din terapia cu oxigen
                hiperbaric.
            </p>

            <p>
                Această listă oferă doar câteva alte exemple de afecţiuni în cazul cărora oxigenoterapia hiperbară are succes:
            </p>

            <ul>
                <li><u>Distrofia simpatică reflexă (DSR) / Sindromul dureros regional complex (SDRC)</u></li>
                <li>Boala Lyme</li>
                <li>Boala Alzheimer</li>
                <li>Scleroză multiplă</li>
                <li><u>Accidente vasculare cerebrale</u></li>
                <li>Degenerescenţă maculară</li>
                <li>A demonstrat că reduce dimensiunea tumorii în unele tipuri de cancer</li>
                <li>Fibromialgie</li>
                <li>Boala Parkinson</li>
                <li>Paralizie cerebrală</li>
                <li>Autism</li>
                <li><u>Traumatisme cerebrale</u></li>
                <li>Tulburare de stres post-traumatic (TSPT)</li>
                <li>Sănătate generală și stare de bine, cum ar fi proprietățile anti-îmbătrânire</li>
                <li>Leziuni sportive și recuperare după antrenament</li>
                <li>Oboseală severă, cum ar fi oboseala cronică obișnuită cu multe afecțiuni autoimune</li>
                <li>Efecte secundare ale chimioterapiei, cum ar fi creierul chimio</li>
                <li>Recuperare după chirurgie plastică</li>
                <li>Aproape înecuri</li>
                <li>Dureri de cap de tip migrene</li>
                <li>Sindrom post-poliomielitic</li>
            </ul>

            <p class="py-3 px-3 border border-dark bg-success text-white">
                Dacă te confrunți cu una dintre aceste probleme de sănătate (dar nu numai) și dorești să afli mai multe informații despre
                oxigenoterapia hiperbară,
                <a [routerLink]="'/contact'" class="nav-link d-inline-block p-0 text-white"
                   title="'Continuați spre pagina de contact'">
                    <i class="fa fa-link mt-0 mb-0"></i> ne poți contacta.
                </a>
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <h2>Istoria HBOT</h2>
            <p>
                Terapia cu oxigen hiperbaric nu este o opțiune de tratament nouă, deoarece medicii s-au orientat spre ea încă de la
                sfârșitul anilor 1600! Medicii au folosit oxigenoterapia hiperbară pentru a trata o varietate de afecțiuni medicale de-a
                lungul anilor și îi descoperă în permanenţă noi aplicații. Pe măsură ce tot mai mulți medici și cercetători află despre
                terapia cu oxigen hiperbaric, aceştia descoperă tot mai multe beneficii cu impact semnificativ în viețile pacienților.
            </p>

            <p>
                Terapia cu oxigen hiperbaric nu mai este doar un tratament pentru scafandri și boala de decompresie. Cercetările continuă în
                toate domeniile medicinii, generând creșterea și extinderea medicinei pe bază de oxigen hiperbaric.
            </p>

            <p>
                Oxigenoterapia hiperbară devine o opțiune obișnuită de tratament pentru o varietate de afecțiuni. Pentru a vedea dacă
                aceasta este potrivită pentru tine, consultă pagina noastră
                <a [routerLink]="'/tratamente-si-beneficii-hbot'" class="nav-link d-inline-block p-0"
                   title="'Continuați spre pagina ce tratam cu HBOT'">
                    <i class="fa fa-link mt-0 mb-0"></i>Tratamente și beneficii HBOT</a>.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <h3>
                Începutul terapiei cu oxigen
            </h3>
            <p>
                Terapia cu oxigen a început oficial în anul 1662, când medicul britanic Nathaniel Henshaw a construit prima cameră
                presurizată pentru tratarea afecțiunilor pulmonare și digestive. Ulterior, utilizarea oxigenoterapiei s-a oprit până în anul
                1788, când au folosit aer hiperbaric comprimat într-un clopot de scufundare pentru o reparaţie subacvatică. Acest demers i-a
                oferit lui August Siebe ideea creării primului costum de scufundări la adâncime, în anul 1819. Primul rezervor hiperbaric
                adevărat a sosit în 1834, sub îndrumarea doctorului Junod. Buletinul Academiei de Medicină a raportat succesul Dr. Junod, cu
                o recuperare completă dintr-o varietate de afecțiuni medicale.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h3>
                Terapia HBOT de la începuturi se arată promiţătoare
            </h3>
            <p>
                În anii 1900, atenția s-a îndreptat din nou către terapia cu oxigen. Medicii francezi au descoperit că pacienții supuși unei
                intervenții chirurgicale asistate prin oxigenare hiperbarică s-au recuperat cu mai puține complicații. Dr. John S. Haldane a
                studiat efectele oxigenului comprimat și ulterior a dezvoltat tabelele de scufundare pentru Royal Navy. Datorită
                cercetărilor sale, oamenii se referă la el ca la „Tatăl terapiei cu oxigen”.
            </p>
            <p>
                În 1918, Dr. Orville Cunningham a descoperit o diferență în rata mortalității între pacienții cu gripă care trăiesc la
                altitudini mai mari comparativ cu pacienții care trăiesc la altitudini mai mici. La altitudini mai mari, respiri mai puțin
                oxigen. Această descoperire a determinat mulți pacienți cu gripă să apeleze, cu succes, la terapia cu oxigen hiperbaric.

            </p>
            <p>
                În 1928, pe malul lacului Erie, în Cleveland, Statele Unite, Dr. Cunningham a creat Sanatorium Cunningham – o sferă
                gigantică din oțel, înaltă de 7.5 metri. În interiorul sferei, existau 38 de camere în care pacienții doctorului Cunningham
                petreceau până la două săptămâni, respirând, la un moment dat, aer la dublul presiunii atmosferice.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <picture [style]="{'max-height': '360px'}" class="img-responsive">
                <source srcset="/assets/images/docs/echipament-hbot-old-1.webp 1x, /assets/images/docs/echipament-hbot-old-1.webp 2x"
                        type="image/webp">
                <source srcset="/assets/images/docs/echipament-hbot-old-1.png 1x, /assets/images/docs/echipament-hbot-old-1.png 2x"
                        type="image/png">
                <img [style]="{'max-height': '360px'}"
                     alt="Echipament HBOT"
                     src="/assets/images/docs/echipament-hbot-old-1.png"
                     title="Imagini istorice HBOT 1 - Echipament">
            </picture>
        </div>
        <div class="col">

            <picture [style]="{'max-height': '360px', 'max-width': '100%'}" class="img-responsive">
                <source srcset="/assets/images/docs/echipament-hbot-old-2.webp 1x, /assets/images/docs/echipament-hbot-old-2.webp 2x"
                        type="image/webp">
                <source srcset="/assets/images/docs/echipament-hbot-old-2.png 1x, /assets/images/docs/echipament-hbot-old-2.png 2x"
                        type="image/png">
                <img [style]="{'max-height': '360px', 'max-width': '100%'}"
                     alt="Echipament HBOT"
                     src="/assets/images/docs/echipament-hbot-old-2.png"
                     title="Imagini istorice HBOT 2 - Interior">
            </picture>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col">
            <p>
                Milionarul Henry Timken, în calitate de fost pacient al dr. Cunningham, a fost finanțatorul investiției în valoare de 1.5
                milione de dolari, o sumă princiară la acea vreme, pentru construirea unui spital și a unei camere mari hiperbarice în formă
                de sferă de oțel pe malul lacului din Cleveland, Ohio. Locul a fost ales datorită liniștii și frumuseții din împrejurimi.
                Deși sfera de oțel arăta ca un gazometru industrial, interioarele erau la fel de luxoase ca ale unui hotel cu cinci etaje de
                dormitoare, zone de recreere și toalete. În centrul instalației se afla o mașină puternică de aer condiționat, formată din
                trei compresoare, două cazane de încălzire, un compresor de 27 de tone de amoniac care funcționa ca dezumidificator și
                diferite filtre de aer care permiteau un control precis nu numai al presiunii, ci și al temperaturii, al umidității și al
                ratei de schimb a aerului din instalație. Întregul sistem a fost reglat și controlat de termostate care ajustau automat
                proprietățile aerului din rezervoare.

            </p>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col">
            <h3>Președintele Kennedy suscită un interes crescut</h3>
            <p>De-a lungul anilor, cercetările au continuat să analizeze terapia cu oxigen hiperbaric sau oxigenoterapia hiperbară, însă
                abia în anii 1960 interesul public a atins un nivel maxim. Copilul bolnav al președintelui Kennedy a fost transportat de la
                Baza Forțelor Aeriene Otis la Spitalul pentru Copii din Boston, unde medicii l-au pus într-o cameră hiperbarică pentru a
                trata ceea ce numim acum sindromul de detresă respiratorie sau SDR. Deși nu au putut salva fiul nou-născut al lui Kennedy,
                aceasta a fost scânteia din care comunitatea medicală avea nevoie să învețe și să își îndrepte atenția spre oxigenoterapia
                hiperbară. Terapia cu oxigen hiperbaric a reușit să obțină finanțări pentru cercetare, ducând, în cele din urmă, la
                actualele unități moderne de terapie intensivă neonatală sau NICU. Acum, 95% dintre sugarii născuți cu SDR supraviețuiesc cu
                tratament medical.
            </p>

            <h3>La ce nivel se află terapia HBOT astăzi</h3>

            <p>Pe măsură ce oxigenoterapia hiperbară continuă să fie utilizată, mulți medici și cercetători avansează în cercetare,
                analizând și alte afecţiuni care ar putea beneficia de terapia cu oxigen hiperbaric. Studiile arată rezultate pozitive în
                cazul unor afecțiuni neurologice, cum ar fi accidentul vascular cerebral și leziunile cerebrale. În multe dintre aceste
                cazuri, terapia cu oxigen hiperbaric îmbunătățește starea pacienților, chiar și în cazul celor cu prognoze foarte slabe.
                Studii privind oxigenoterapia hiperbară în tratamentul afecțiunilor precum autismul, fibromialgia, scleroza multiplă și
                chiar cancerul sunt în lucru.
            </p>

            <p class="py-3 px-3 border border-dark bg-success text-white">
                Pentru mai multe informații despre terapia cu oxigen hiperbaric și cum te poate ajuta aceasta, sună la Centrul Medical
                Oxygen Review → <strong>0364 107 206</strong> sau <strong>0745 659 945</strong>.
            </p>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col">
            <h2>HBOT și sănătatea generală şi starea de bine</h2>

            <p>Oxigenoterapia hiperbară tratează o varietate de afecțiuni medicale și leziuni. În plus, oferă beneficii și pentru persoanele
                sănătoase. Deoarece celulele din corp necesită oxigen pentru a supraviețui, adăugarea unor niveluri mai ridicate de oxigen
                pe care le asigură terapia cu oxigen hiperbaric stimulează funcția celulară crescută. Oamenii experimentează astfel o
                sănătate generală mai bună și o stare de bine ca urmare a terapiei cu oxigen hiperbaric, incluzând semne reduse de
                îmbătrânire și timp de recuperare mai rapid pentru sportivi.
            </p>

            <h3>HBOT ajută la &icirc;mbunătățirea sănătății generale și a stării de bine</h3>

            <p>Terapia cu oxigen hiperbaric funcționează prin inundarea corpului cu niveluri crescute de oxigen. Într-o singură oră de
                tratament, corpul preia aproximativ 2,4 livre (1,09 kg) de oxigen în țesut, ceea ce determină o creștere a nivelului de
                oxigen din țesuturi de 10 până la 15 ori cantitatea primită prin respirație normală. Ca urmare, acest nivel crescut de
                oxigen în sistem oferă multe beneficii, dintre care amintim:
            </p>
            <ul>
                <li>Stimulează dezvoltarea de noi vase de s&acirc;nge</li>
                <li>Scade tumefacţia și inflamația</li>
                <li>Dezactivează toxinele</li>
                <li>Crește capacitatea organismului de a combate infecțiile</li>
                <li>&Icirc;ndepărtează toxinele și deșeurile</li>
                <li>&Icirc;mbunătățește funcția cognitivă</li>
                <li>Stimulează o nouă creștere capilară care poate ajuta la vindecarea simplă a rănilor</li>
                <li>Stimulează celulele creierului și crește excitarea activă a acestor celule</li>
            </ul>

            <h3>Oferă efecte de &icirc;ntinerirea pielii</h3>
            <p>În lumea de astăzi, oamenii caută orice și totul pentru a opri efectele îmbătrânirii. Cine are cea mai nouă și cea mai bună
                cremă facială pentru reducerea ridurilor? Ce suplimente îmi vor ajuta pielea să arate din nou tânără? Pot face o procedură
                de chirurgie plastică pentru a mă face să par mai tânără? În timp ce unele dintre aceste produse sau proceduri ar putea
                funcționa la suprafață, terapia cu oxigen hiperbaric îţi poate ajuta corpul și pielea să întinerească în mod natural. De
                fapt, oxigenoterapia hiperbară poate, în esență, să readucă tinereţea pe faţa ta. Terapia cu oxigen hiperbaric livrează până
                la 15 ori mai mult oxigen în corpul tău, saturând celulele și accelerând procesul natural de vindecare. În plus, nivelul de
                oxigen crescut ajută la stimularea producției de colagen și contribuie la elasticitatea pielii.
            </p>
            <p>Acest lucru sporește în mod natural capacitatea organismului de a combate stresul oxidativ și activitatea lentă a celulelor.
                La fel de important, terapia cu oxigen hiperbaric stimulează metabolismul celular și ajută la apărarea antioxidantă.
                Nivelurile crescute de oxigen contribuie la îmbunătățirea fluxului sanguin necesar pentru a menține membranele celulare
                flexibile, rezultând o elasticitate crescută a pielii și producerea de colagen. Acest lucru ajută la repararea texturii
                pielii îmbătrânite și la reducerea aspectului liniilor fine și a ridurilor.
            </p>

            <h3>Ajută performanța sportivă și recuperarea</h3>

            <p>Sportivii văd beneficiile pe care oxigenoterapia hiperbară le are, mulți sportivi profesioniști și organizații adăugând
                camere hiperbarice echipamentelor lor de antrenament. Nivelul crescut de oxigen din oxigenoterapia hiperbară favorizează
                fluxul sanguin și îndepărtarea deșeurilor, permițând un timp de recuperare mai rapid. În plus, nivelurile crescute de oxigen
                favorizează funcționarea mai rapidă a creierului și luarea deciziilor. Drept urmare, sportivii au o concentrare îmbunătățită
                și sunt capabili să ia decizii rapide în câteva secunde. Acest lucru face diferența când vine vorba de câștigarea unui joc.
                În cazul unei leziuni, nivelurile mai ridicate de oxigen din sânge favorizează perioade de vindecare mai rapide. Cu alte
                cuvinte, sportivii se pot întoarce la antrenamente sau jocuri mult mai repede.
            </p>

            <p>Exemple de la sportivii care apelează la tratamentele HBOT pentru a-și &icirc;mbunătăți abilitățile:</p>

            <ul>
                <li>
                    Sportivii olimpici americani folosesc camere hiperbarice ca o modalitate de recuperare după antrenamente extinse și
                    exerciţii
                    de rezistenţă;
                </li>
                <li>Jucătorii NFL precum <a
                    href="https://ftw.usatoday.com/2015/12/steve-weatherford-explains-why-nfl-players-often-sleep-in-hyperbaric-chambers"
                    target="_blank">Steve
                    Weatherford</a>, Terrell Owens, Rashad Jennings, Matt Schaub, Reggie Bush, James Harrison și Tim Tebow, toți folosesc
                    camere hiperbarice pentru a-i ajuta la antrenament și vindecare, mulți dormind &icirc;n propriile camere hiperbarice
                    personale;
                </li>
                <li>Jucătorii MLB Matt Kemp și Adrian Beltre folosesc tratamente HBOT pentru a-i ajuta la antrenament și recuperare;</li>
                <li>Printre alți sportivi de top care folosesc HBOT se numără Tiger Woods, luptătorul UFC Urijah Faber și &icirc;notătorul
                    olimpic Michael Phelps.
                </li>
            </ul>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <h2>Diferența dintre camerele hiperbarice cu carcasă tare și camerele hiperbarice cu pereţi moi</h2>
            <p>Numărul unităţilor medicale care oferă oxigenoterapie hiperbară a crescut în ultimii 50 de ani. În anii 1970, aproximativ 27
                de unităţi din SUA ofereau terapia cu oxigen hiperbaric. Pe măsură ce cercetătorii au aflat despre beneficiile acesteia, tot
                mai multe unități au început să ofere tratamente cu camere hiperbarice. Rapoarte recente afirmă că între 1.800 și 2.000 de
                spitale oferă terapie cu oxigen hiperbaric în Statele Unite. Acestora li se adaugă între 500 și 700 de clinici sau cabinete
                medicale non-spitalicești. Iar aceste date nu includ camerele hiperbarice cu pereţi moi pe care oamenii le dețin în casele
                lor.
            </p>
            <p>Noi cercetări continuă să arate beneficiile aduse de terapia cu oxigen hiperbaric într-o varietate de afecțiuni medicale
                aprobate de FDA, precum și în afara indicaţiilor autorizate. Acest lucru creează o nouă opțiune excelentă de tratament
                medical pentru mulți pacienți. Dar creează și o deschidere pentru profituri mari în detrimentul pacienților. Camerele
                hiperbarice cu pereţi moi oferă pacienților posibilitatea de a achiziționa o cameră pentru utilizare acasă. Din păcate, nu
                toate camerele sunt egale ca performanţe. Camerele cu pereţi moi nu oferă aceleași beneficii și pot, în unele cazuri, să
                înrăutățească lucrurile.
            </p>

            <h3>Camere de oxigen hiperbaric cu carcasă tare</h3>
            <p>În spitale și clinici, medicii folosesc camere de oxigen hiperbaric cu carcasă tare. Aceste camere sunt dispozitive medicale
                aprobate de FDA pentru tratamentul a 14 afecțiuni. În plus, acestea arată rezultate pozitive în studii pentru considerabil
                mai multe afecţiuni. Camerele cu carcasă tare sunt camere închise din oțel care furnizează 100% oxigen la presiuni
                atmosferice peste 2,0 ATA. Instalarea acestor camere într-o unitate medicală necesită respectarea diferitelor reglementări
                de construcție, inclusiv NFPA-99 de la Asociația Națională de Protecție împotriva Incendiilor.
            </p>

            <h3>Camere hiperbarice cu pereţi moi</h3>
            <p>Camerele cu pereţi moi, cunoscute sub numele de HBOT ușoare sau mHBOT, sunt camere portabile care se etanșează cu fermoar.
                Pot atinge niveluri de presiune de aproximativ 1,3 ATA și, de obicei, livrează niveluri de oxigen de 24%. Concepute inițial
                pentru scafandri și alpiniști, pentru a fi utilizate până când aceștia puteau fi transportați în camere cu carcasă tare,
                intenția nu a fost niciodată utilizarea camerelor cu pereţi moi pentru tratament.
            </p>

            <h3>Nivelurile de oxigen nu se compară</h3>
            <p>Marea diferență dintre camerele cu carcasă tare și cele cu pereţi moi vine din concentrația de oxigen în corp. Pentru a
                înțelege mai bine acest lucru, pe măsură ce citeşti aceste rânduri, respiri aerul din cameră cu o concentrație de 21%
                oxigen. O cameră cu pereţi moi folosește 24% oxigen. În schimb, când vezi oameni transportați într-o ambulanță și conectați
                la o mască de oxigen, aceştia primesc 55% oxigen. Camerele hiperbarice cu carcasă tare furnizează 100% oxigen. Aceste cifre
                nici nu iau în considerare diferențele de nivel de presiune.
            </p>
            <p>Pentru a merge mai în profunzime, să ne uităm la nivelurile de gaze din sângele arterial care indică oxigenul din sânge. În
                condiții normale de aer și la presiune normală, oxigenul din sânge va fi 100 mmHg. Cu toate acestea, într-o cameră cu pereţi
                moi la 1,3 ATA și 24% oxigen, valoarea va crește la aprox. 180 mmHg. O mască de oxigen, precum cele utilizate în transportul
                cu ambulanța, furnizează 55% oxigen la presiune normală iar presiunea parțială a oxigenului în sânge va fi de 360 mmHg.
                Desigur, diferența reală apare într-o cameră cu carcasă tare. Într-o cameră cu carcasă tare la 2,4 ATA și 100% oxigen,
                nivelurile de oxigen arterial ajung la 1.824 mmHg.
            </p>
            <p>Ideea pe care se bazează terapia cu oxigen hiperbaric și succesul acesteia ca opțiune de tratament depind de inundarea
                organismului cu niveluri mai ridicate de oxigen. Din păcate, camerele cu pereţi moi nu oferă cu adevărat mult mai mult decât
                aerul pe care îl respiri în mod natural. Cu toată sinceritatea, ai mai multe beneficii de la o simplă mască de oxigen.
            </p>

            <h3>Camerele cu pereţi moi nu-şi ating scopul</h3>
            <p>În timp ce în prezent terapia cu oxigen hiperbaric este aprobată de FDA pentru tratamentul a 14 afecțiuni, multe afecțiuni au
                fost tratate în afara indicaţiilor autorizate cu mare succes atât în mediile clinicilor medicale, cât și în cadrul a
                numeroase studii clinice, care arată că inundarea corpului cu niveluri ridicate de oxigen poate vindeca și, în multe cazuri,
                poate reda calitatea vieții multor pacienți. Cu toate acestea, în toate aceste studii administrarea de oxigen a fost la 100%
                și la niveluri de presiune de 2,0 ATA sau mai mari.
            </p>

            <p>Până în prezent, niciun studiu real nu a analizat beneficiile pe care le-ar avea oxigenoterapia hiperbară ușoară sau cu nivel
                scăzut de oxigen. Având în vedere cifrele, nivelurile de oxigen pe care le furnizează nu sunt mult diferite de ceea ce
                respiri în mod normal. Camerele cu pereţi moi nu sunt aprobate de FDA ca dispozitiv medical pentru utilizarea în
                oxigenoterapia hiperbară, cu excepția utilizării de către alpiniști și scafandri cu boală de altitudine în timpul
                transportului la o unitate medicală dotată cu o cameră hiperbarică.
            </p>

            <h3>C&acirc;nd tratamentul cauzează mai multe probleme</h3>
            <p>Terapia cu oxigen hiperbaric tratează rănile care nu se vindecă, precum și infecțiile bacteriene, virale și fungice.
                Cercetările științifice arată că oxigenul devine bacteriostatic la 1,5 ATA. Acest lucru înseamnă că la niveluri peste 1,5
                ATA, oxigenul previne creșterea bacteriană și fungică. Cu terapia cu oxigen hiperbaric cu carcasă tare, nivelurile de
                presiune sunt de obicei peste 2,0 ATA, ceea ce o face să aibă succes în suprimarea creșterii bacteriene. Din păcate, cu
                niveluri medii de 1,3 ATA, camerele cu pereţi moi nu pot suprima creșterea bacteriană. Dimpotrivă, ele sporesc creșterea
                bacteriilor, mucegaiului și ciupercilor. Expunerea la bacterii, mucegai și ciuperci înrăutățește afecţiunile sau duce la
                complicații medicale suplimentare.
            </p>

            <h3>Ce folosim pentru tratamentele noastre de terapie cu oxigen hiperbaric</h3>
            <p>În cadrul Centrului Medical Review, Dr. Demea și echipa sa tratează pacienții cu ajutorul unor camere cu carcasă tare de
                calitate superioară. Camera noastră, Baroks O2mono, are un sistem controlat pneumatic care oferă un diametru interior de 90
                cm, făcându-l spațios și confortabil pentru pacienții noștri. Această cameră permite monitorizarea completă a pacientului în
                timpul tratamentelor cu oxigen și are un sistem de divertisment pentru pacient. În plus, sistemul nostru oferă un sistem de
                intercomunicaţie bidirecțională, facilitând comunicarea în timpul procedurii.
            </p>

            <p class="py-3 px-3 border border-dark bg-success text-white">
                Dacă doreşti mai multe informații despre camerele noastre, ce oferă acestea și cum poate ajuta oxigenoterapia hiperbară în
                tratarea afecțiunii tale, ne poţi suna astăzi pentru a stabili o consultație inițială cu Dr. Demea și echipa sa ori
                <a [routerLink]="'/contact'" class="nav-link d-inline-block p-0 text-white"
                   title="'Continuați spre pagina de contact'">
                    <i class="fa fa-link mt-0 mb-0"></i> te poți programa online</a> – rapid și simplu!
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <h2>Precauție în folosirea HBOT și posibile efecte secundare</h2>
            <p>Oxigenoterapia hiperbară este o opțiune de tratament neinvaziv disponibilă pentru o varietate de afecțiuni medicale. Pentru
                majoritatea pacienților, terapia cu oxigen hiperbaric produce efecte secundare puține sau chiar deloc, iar pacienții sunt
                capabili să se relaxeze în timpul tratamentului. Ca și în cazul oricărui tratament sau proceduri medicale, terapia cu oxigen
                hiperbaric vine cu considerații și există un risc mic de posibile reacții adverse. Pentru a evita orice reacții adverse
                potențiale sau posibile complicații, Dr. Demea va analiza istoricul medical în timpul consultaţiei inițiale și va discuta
                despre modul în care funcționează oxigenoterapia hiperbară, precum și despre cea mai bună modalitate de a beneficia la
                maximum de tratamentele urmate.
            </p>

            <h3>C&acirc;nd trebuie manifestată precauţia?</h3>
            <p>Anumite afecțiuni medicale pot crește șansa de posibile reacții adverse la terapia cu oxigen hiperbaric. Dacă ai oricare
                dintre următoarele afecţiuni, te rugăm să le aduci la cunoștința Dr. Demea la consultaţia inițială:
            </p>
            <ul>
                <li>Pneumotorax, cu excepția cazului &icirc;n care este tratat cu o valvă Heimlich</li>
                <li>Infecție acută de căi respiratorii superioare sau sinuzită</li>
                <li>Emfizem</li>
                <li>Febra mare necontrolată</li>
                <li>Istoric de complicații ale urechii</li>
                <li>Chirurgie toracică sau pneumotorax spontan</li>
                <li>Tulburări convulsive</li>
            </ul>

            <h3>Efecte secundare posibile</h3>
            <p>Cu terapia cu oxigen hiperbaric, efectele secundare sunt în general ușoare și sunt legate de modificările semnificative ale
                presiunii din interiorul camerei, precum și de nivelurile crescute de oxigen utilizate. În majoritatea cazurilor,
                oxigenoterapia hiperbară este bine tolerată, neinvazivă și produce foarte puține efecte secundare. Dr. Demea va discuta
                posibilele reacții adverse și va aborda riscul pe baza istoricului de sănătate și a oricăror afecțiuni ce necesită
                precauție, menționate mai sus. Reacțiile adverse posibile includ:
            </p>
            <p class="font-weight-bold">Barotrauma urechii</p>
            <p>Principalul efect secundar raportat în terapia cu oxigen hiperbaric este disconfortul din ureche sau leziunea datorată
                creșterii presiunii aerului. În majoritatea cazurilor, acest lucru este similar cu durerea sau disconfortul pe care îl poți
                experimenta într-o călătorie prin munți sau presiunea din cabină pe care o simți atunci când zbori cu avionul. Pacienții cu
                sensibilitate la urechi pot necesita tuburi pentru urechi înainte de oxigenoterapia hiperbară.
            </p>

            <p class="font-weight-bold">Toxicitatea oxigenului</p>
            <p>Terapia cu oxigen hiperbaric funcționează bine în limitele de siguranță ale nivelurilor de oxigen, iar riscul de toxicitate a
                oxigenului este rar. În caz de toxicitate a oxigenului, pot apărea convulsii. Este mai probabil să apară la pacienții cu
                tulburări convulsive preexistente sau hipoglicemie. Dacă se întâmplă acest lucru, tratamentul constă pur şi simplu în
                oprirea terapiei HBOT, convulsiile încetând.
            </p>

            <p class="font-weight-bold">Modificări de vedere</p>
            <p><a href="https://uihc.org/health-topics/medical-risks-hyperbaric-oxygen-therapy">Modificările presiunii aerului care apar în
                timpul tratamentelor cu oxigen hiperbaric pot provoca modificări de vedere</a>. Aceste modificări sunt temporare și de
                obicei
                revin la normal după ce terapia cu oxigen hiperbaric este finalizată. Dintre acestea, pot fi menționate eventuale modificări
                ale formei cristalinului și corneei, care pot duce la agravarea miopiei sau la miopie, dar în același timp la îmbunătățirea
                focusării asupra obiectelor din apropierea ochiului.
            </p>

            <p class="font-weight-bold">Hipoglicemie</p>
            <p>Pacienții cu diabet zaharat pot experimenta o scădere a glicemiei din sânge în timpul tratamentelor cu oxigen hiperbaric. În
                aceste cazuri, se poate ca Dr. Demea și personalul său să te încurajeze să mănânci înainte de a intra la tratament și poate
                necesita monitorizarea regulată a glicemiei în timpul tratamentelor.
            </p>

            <p class="font-weight-bold">Tasarea sinusurilor sau a dinților</p>
            <p>Deoarece cavitatea sinusală este formată din spații umplute cu aer, poţi experimenta modificări de presiune și disconfort
                similare cu cele observate în ureche. În plus, lucrările stomatologice recente pot lăsa buzunare umplute cu aer care pot fi
                afectate și de modificările de presiune. Această presiune poate duce la disconfort și la o posibilă crăpare a dinților.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <h3>Studiul efectelor secundare și experiența ta cu terapia cu oxigen hiperbaric</h3>
            <p>Deși există posibilitatea apariției efectelor secundare în timpul tratamentului cu oxigen hiperbaric, la fel ca în cazul
                oricărei proceduri medicale, cea mai frecventă complicație este egalizarea presiunii crescute în urechi. <U>Un studiu recent
                    publicat în Medicina Aeriană, Spațială și de Mediu</U> a analizat complicațiile și efectele secundare pe care
                oxigenoterapia hiperbară le poate avea. Studiul a concluzionat că terapia cu oxigen hiperbaric este o opțiune benefică de
                tratament cu o rată normală de complicații, similară cu cea a altor proceduri medicale. De asemenea, a stabilit că rareori
                apar complicații grave.
            </p>
        </div>
        <div class="col-md-4 d-flex justify-content-center flex-row pt-2">
            <picture [style]="{'max-height': '250px'}" class="img-responsive margin-bottom-15">
                <source srcset="/assets/images/docs/echipament-hbot2.webp 1x, /assets/images/docs/echipament-hbot2.webp 2x"
                        type="image/webp">
                <source srcset="/assets/images/docs/echipament-hbot2.png 1x, /assets/images/docs/echipament-hbot2.png 2x"
                        type="image/png">
                <img [style]="{'max-height': '250px'}"
                     alt="Echipament HBOT"
                     src="/assets/images/docs/echipament-hbot2.png"
                     title="Efecte secundare">
            </picture>
        </div>
        <div class="col-md-8">
            <p>
                Dr. Demea și echipa sa lucrează pentru a reduce riscul eventualelor complicații, aflându-ţi istoricul medical și lucrând cu
                tine pentru a profita la maximum de experiența ta cu terapia cu oxigen hiperbaric. Ședințele de terapie nu durează mai mult
                de două ore, iar nivelurile de presiune nu depășesc niciodată de trei ori presiunea normală a aerului.
            </p>
            <p>
                Înainte de a începe orice curs de tratament cu oxigen hiperbaric, Dr. Demea și echipa sa îţi vor evalua istoricul medical și
                vor aborda orice preocupări individuale.
            </p>
            <p>Dacă doreşti să afli mai multe despre oxigenoterapia hiperbară neinvazivă sau terapia cu oxigen hiperbaric, sună la cabinetul
                nostru chiar astăzi. De asemenea, poți completa formularul nostru pentru solicitarea unei
                <a [routerLink]="'/contact'" class="nav-link d-inline-block p-0"
                   title="'Continuați spre pagina de contact'">
                    <i class="fa fa-link mt-0 mb-0"></i> PROGRAMĂRI</a>.</p>
        </div>
    </div>
</div>

<button (click)="topFunction()"
        class="btn btn-success"
        id="myBtn"
        title="Înapoi la început">Sus
</button>

<div class="container pt-5 pb-5">
    <div class="row">
        <div class="col">
            <h1>HBOT și Medicina Hiperbară: tratamente și beneficii</h1>
            <p>În cadrul centrului nostru medical, beneficiezi de un departament specializat în HBOT și medicina hiperbară, pentru
                consultații și servicii medicale în acest domeniu – cu ajutorul unor echipamente de ultimă generație! Echipa de specialiști,
                sub îndrumarea Dr. Medea, vine în sprijinul tău prin dezvoltarea unui plan de tratament hiperbaric potrivit situației tale
                medicale.
            </p>
            <p>
                În cele ce urmează, îți prezentăm afecțiuni și traumatisme pentru care FDA a aprobat intervenții de medicina hiperbară de
                tipul HBOT (<a [routerLink]="['/terapie-cu-oxigen-hiperbaric']"
                               [title]="'Continuati spre pagina despre hbot'"
                               class="nav-link d-inline-block"><i class="fa fa-link"></i> terapia cu oxigen hiperbaric</a>), îți explicăm
                ce înseamnă acest lucru, de ce anumite afecțiuni nu au această
                aprobare deși HBOT le poate trata cu succes, urmând să analizăm afecțiunile pe care le tratăm prin terapie <a
                [routerLink]="['/']"
                [title]="'terapiehiperbara.com'"
                class="nav-link d-inline-block"><i class="fa fa-link"></i> hiperbară</a> în cadrul Centrului Medical Review din Cluj-Napoca.
            </p>
            <p class="py-3 px-3 border border-dark bg-success text-white">
                Vrei să afli mai multe informații despre serviciile noastre de medicină hiperbară? Ne poți contacta fie telefonic, fie
                completând formularul
                <a [routerLink]="'/contact'" [title]="'Continuați spre pagina de contact'"
                   class="nav-link d-inline-block p-0 text-white">
                    <i class="fa fa-link mt-0 mb-0"></i> CONTACT</a>!
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <h2>Lista afecțiunilor tratate prin HBOT și aprobările FDA</h2>
            <p>Dacă iei în considerare un tratament cu oxigen hiperbaric (HBOT), există mari șanse să întâlneşti termenii „Aprobat de FDA”
                și „În afara indicaţiilor autorizate” atunci când discuți despre condiții. Pentru mulți oameni, aceşti termeni generează
                confuzie și îngrijorare atunci când vine vorba de îngrijirea lor medicală. În timp ce multe afecţiuni și traumatisme sunt în
                prezent aprobate de FDA pentru HBOT, multe încă nu sunt. Totuși, acest lucru nu înseamnă că HBOT și medicina hiperbară nu
                reprezintă o opțiune de tratament acceptabilă și pentru acele alte afecțiuni. Pentru început, să ne uităm la ceea ce
                înseamnă cu adevărat „Aprobat de FDA” și la modul în care acest lucru vă afectează opțiunile de îngrijire medicală.
            </p>
            <h2>Ce este Aprobarea FDA?</h2>
            <p><a href="https://www.usa.gov/federal-agencies/food-and-drug-administration" target="_blank">
                Administrația pentru Alimente și Medicamente a Departamentului pentru Sănătate și Servicii Umane</a> sau, pe scurt, FDA, este
                agenția responsabilă în SUA pentru protejarea sănătății publice și reglementarea medicamentelor farmaceutice, produselor
                biologice, dispozitivelor medicale, produselor din tutun, alimentelor, produselor cosmetice și multe altele. Pentru ca un
                medicament, o procedură medicală sau un dispozitiv să primească aprobarea FDA, producătorul trebuie să demonstreze că sunt
                sigure și eficiente. FDA ia decizia examinând rezultatele de laborator, rezultatele testelor și studiile clinice. Dacă un
                medicament sau dispozitiv primește aprobare, aceasta înseamnă că FDA a stabilit că beneficiile produsului sau ale
                tratamentului au depășit riscurile posibile.
            </p>
            <p>În mod similar funcționează în România Agenția Națională a Medicamentelor și Dispozitivelor Medicale din România (ANDMR).
            </p>

            <h2>Afecţiunile actuale aprobate de FDA pentru tratamentele HBOT</h2>
            <p>În cazul HBOT, există în prezent 14 afecțiuni aprobate de FDA pentru tratament cu oxigen hiperbaric. Cea mai nouă a primit
                aprobare pe 8 octombrie 2011. Aceste afecţiuni și traumatisme aprobate de FDA când vine vorba de medicina hiperbară includ:
            </p>
            <ul>
                <li>
                    <a [routerLink]="['/HBOT-si-emboliile-aeriene-sau-gazoase']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Embolie aeriană sau gazoasă</a>;
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-intoxicatia-cu-monoxid-de-carbon']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Intoxicare cu monoxid de carbon</a>;
                </li>
                <li>
                    Miozită și <a [routerLink]="['/HBOT-si-mionecroza-clostridiala']"
                                  class="nav-link d-inline-block p-0 cursor-pointer">
                    <i class="fa fa-link mt-0 mb-0"></i>
                    mionecroză clostridială</a> (gangrenă gazoasă);
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-leziunile-prin-zdrobire-sindromul-de-compartiment']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Leziunile prin zdrobire / sindromul de compartiment</a> și alte ischemii traumatice acute;
                </li>
                <li>
                    Boala de decompresie (de exemplu, adesea observată la scafandri);
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-boala-arteriala-periferica']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Insuficiențe arteriale periferice</a> și centrale (de exemplu, ocluzia de arteră centrală a retinei);
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-tratamentul-anemiei']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Anemie severă</a>;
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-abcesul-intracranian']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Abces intracranian</a>;
                </li>
                <li>
                    Infecții ale țesuturilor moi necrotizante;
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-osteomielita-refractara']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Osteomielită (refractară)</a>;
                </li>
                <li>
                    Leziune întârziată la radiații (țesut moale și
                    <a [routerLink]="['HBOT-si-necroza-radiationala']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        osos</a>);
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-grefele-lambourile-compromise']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Grefe și lambouri compromise</a>;
                </li>
                <li>
                    Leziune provocată de arsură termică acută;
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-pierderea-subita-a-auzului']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Pierderea idiopatică subită a auzului senzorineural</a>.
                </li>
            </ul>

            <h2>De ce unele afecţiuni nu sunt aprobate de FDA, chiar dacă tratamentele HBOT au succes?</h2>
            <p>Deoarece medicina hiperbară și HBOT câștigă în continuare popularitate și utilizare, studiile clinice pentru toate
                afecţiunile potențiale pentru care ar exista beneficii HBOT încă nu au îndeplinit standardele FDA. Acest lucru nu înseamnă,
                totuși, că HBOT și medicina hiperbară nu ajută sau nu tratează aceste afecțiuni. În multe cazuri, există studii care arată
                deja rezultate și beneficii HBOT promițătoare, dar acestea nu sunt suficiente. Afecţiunile actuale în care rezultatele aduse
                de medicina hiperbară sunt promițătoare includ Distrofia Simpatică Reflexă sau DSR (cunoscută și sub denumirea de <a
                    [routerLink]="['/HBOT-si-tratamentele-pentru-dsr-sau-sdrc']"
                    class="nav-link d-inline-block p-0 cursor-pointer"><i class="fa fa-link mt-0 mb-0"></i> Sindromul Dureros Regional
                    Complex sau SDRC</a>), boala Lyme, <a
                    [routerLink]="['/HBOT-si-boala-Alzheimer']"
                    class="nav-link d-inline-block p-0 cursor-pointer"><i
                    class="fa fa-link mt-0 mb-0"></i> boala Alzheimer</a>, scleroza
                multiplă, degenerarea maculară, hipoxie, accidente vasculare cerebrale și multe altele.
            </p>
            <h2>Teste și tratamente actuale care promit</h2>
            <p>&Icirc;n timp ce multe dintre aceste afecţiuni nu au aprobarea FDA, au loc studii pentru a evalua tratamentele HBOT și modul
                &icirc;n care acestea ajută. De exemplu, profesorul Uri Ashery de la Școala de Neuroștiințe Sagol de la TAU și Facultatea de
                Științe ale Vieții arată că terapia cu oxigen hiperbaric &icirc;mbunătățește patologia pacienților cu Alzheimer și
                corectează deficiențele comportamentale asociate bolii.</p>
            <p>Dr. Daphne Denham, principalul expert național &icirc;n tratamentul HBOT al contuziei, de exemplu, raportează că 98 la sută
                dintre pacienții săi tratați cu HBOT &icirc;n decurs de 10 zile de la suferirea unei contuzii observă ameliorarea
                simptomelor &icirc;n decurs de 5 tratamente sau mai puțin.</p>
            <p>Departamentul American al Problemelor Veteranilor a anunțat că va oferi tratament HBOT veteranilor care suferă de tulburare
                persistentă de stres post-traumatic (TSPT). Un neurolog de la Florida VA, &icirc;mpreună cu alte șapte state, tratează
                &icirc;n prezent leziunile cerebrale traumatice (LCT) și TSPT cu tratament HBOT.</p>
            <p>Un studiu realizat de Dr. Gary Steinburg de la Universitatea Stanford arată cum terapia HBOT stimulează producția de celule
                stem &icirc;n măduva osoasă a pacienților care suferă de un accident vascular cerebral. Această producție de celule stem
                duce la vindecarea creierului, la creșterea de noi celule cerebrale, a țesutului neuronal și la repararea substanței albe și
                cenușii din creier.</p>

            <h3>De ce afecţiunea mea este considerată în afara indicaţiilor autorizate și ce înseamnă asta?</h3>
            <p>Termenul „Aprobat de FDA” este ceva cu care ați putea fi familiarizat. În schimb, un medicament sau tratament primește
                termenul „În afara indicaţiilor autorizate” atunci când medicii îl folosesc pentru a trata o afecțiune sau traumatisme care
                nu sunt aprobată de FDA. Tratamentele HBOT și intervențiile de medicină hiperbară sunt aprobate până în prezent doar pentru
                14 afecțiuni medicale diferite. Cu toate acestea, medicii și cercetătorii văd rezultate pozitive în cazul multor alte
                afecţiuni și traumatisme. Prin urmare, medicina hiperbară și HBOT tratează zilnic afecţiuni în afara indicaţiilor
                autorizate. Această practică nu este nouă pentru medicină. Ai fi uimit să afli cât de multe afecţiuni găsesc ameliorare prin
                utilizarea tratamentelor în afara indicaţiilor autorizate în fiecare zi.</p>


            <h3>Ce înseamnă în afara indicaţiilor autorizate?</h3>
            <p>Termenul „în afara indicaţiilor autorizate” înseamnă exact ceea ce spune. Un medic prescrie un medicament care nu este
                aprobat pentru o anumită afecțiune. Utilizarea tratamentului în afara indicaţiilor autorizate nu vine dintr-un moft.
                Medicii, cercetătorii și studiile clinice inițiale au prezentat rezultate pozitive sau promițătoare în ceea ce priveşte
                tratamentul cu pricina. Deși s-ar putea să nu aibă încă aprobarea FDA, este o opțiune pentru pacienți atunci când vine vorba
                de îngrijirea lor. În multe cazuri, medicii pot prescrie medicamente și nici măcar nu menționează că este în afara
                indicaţiilor autorizate. În plus, deși tratamentul nu are aprobarea FDA, alte țări îl folosesc cu mare succes și pe bază de
                aprobare.</p>

            <h3>Tratamentele în afara indicaţiilor autorizate nu sunt o practică nouă în medicină</h3>
            <p>Utilizarea medicamentelor și tratamentelor în afara indicaţiilor autorizate nu este nimic nou în medicina de zi cu zi. De
                asemenea, nu este ceva specific HBOT sau pentru medicina hiperbară. Conform regulilor FDA, medicii au capacitatea de a-și
                trata pacienții cu medicamente în afara indicaţiilor autorizate și, ca urmare, au adesea rezultate surprinzătoare. Când
                medicii folosesc un medicament în afara indicaţiilor autorizate și au rezultate pozitive, aceștia declanșează începutul
                cercetărilor medicale și al studiilor clinice necesare pentru a primi o nouă aprobare FDA. În cazul HBOT, medicii folosesc
                și au succes în tratarea multor afecțiuni și traumatisme în afara indicaţiilor autorizate. Aceste condiții includ DSR, boala
                Alzheimer, <a [routerLink]="['/HBOT-si-boala-lyme']"
                              class="nav-link d-inline-block p-0 cursor-pointer">
                    <i class="fa fa-link mt-0 mb-0"></i> boala Lyme</a>,
                <a [routerLink]="['/HBOT-si-leziunile-cerebrale-traumatice']"
                   class="nav-link d-inline-block p-0 cursor-pointer">
                    <i class="fa fa-link mt-0 mb-0"></i> leziuni cerebrale traumatice</a>,
                <a [routerLink]="['/HBOT-si-tulburarea-de-stres-post-traumatic-tspt']"
                   class="nav-link d-inline-block p-0 cursor-pointer">
                    <i class="fa fa-link mt-0 mb-0"></i> TSPT</a>,
                <a [routerLink]="['/HBOT-si-tratamentul-fibromialgiei']"
                   class="nav-link d-inline-block p-0 cursor-pointer">
                    <i class="fa fa-link mt-0 mb-0"></i> fibromalgie</a>, hipoxie,
                <a [routerLink]="['/HBOT-si-accidente-vasculare-cerebrale']"
                   class="nav-link d-inline-block p-0 cursor-pointer">
                    <i class="fa fa-link mt-0 mb-0"></i> accidente vasculare cerebrale</a>,
                <a [routerLink]="['/HBOT-si-scleroza-multipla']"
                   class="nav-link d-inline-block p-0 cursor-pointer">
                    <i class="fa fa-link mt-0 mb-0"></i> scleroză multiplă</a> și multe altele.</p>

            <h3>Tratamentele obișnuite în afara indicaţiilor autorizate pe care s-ar putea să nu le cunoști sunt în afara indicaţiilor
                autorizate</h3>
            <p>Medicii folosesc tratamente în afara indicaţiilor autorizate în fiecare zi în medicină, cu mare succes. Un exemplu popular
                este medicamentul Bupropion. Denumirile comerciale pentru acest medicament pe care le-ai putea recunoaște sunt Wellbutrin și
                Zyban. Acest medicament a primit inițial aprobarea FDA ca antidepresiv. După tratarea pacienților, un psihiatru a observat
                că pacienții săi au renunțat spontan la fumat. Împreună cu alți medici, psihiatrul a început să prescrie medicamentul pentru
                renunțarea la fumat. În consecință, această constatare a declanşat trei studii clinice majore. Ca urmare, medicamentul a
                primit aprobarea FDA pentru renunțarea la fumat și denumirea comercială suplimentară, Zyban.</p>
            <p>Multe alte antidepresive sunt aprobate de FDA doar pentru depresie. În afara indicaţiilor autorizate, tratează migrenele,
                sindroamele dureroase cronice și leziunile cerebrale. De exemplu, medicamentul Neurontin este aprobat de FDA pentru
                convulsii. Cu toate acestea, pe lângă convulsii, majoritatea rețetelor pentru Neurontin tratează o varietate de afecțiuni în
                afara indicaţiilor autorizate, inclusiv zona Zoster și neuropatia periferică.</p>
            <p> După cum poți constata, deși HBOT s-ar putea să nu fie un tratament aprobat de FDA pentru afecţiunea de care suferi, este
                posibil să fie totuși foarte eficientă în tratarea acesteia. Dacă doreşti să afli mai multe despre modul în care HBOT te
                poate ajuta și cum decurge un tratament cu oxigen hiperbaric,
                <a [routerLink]="'/contact'" [title]="'Continuați spre pagina de contact'"
                   class="nav-link d-inline-block">
                    <i class="fa fa-link mt-0 mb-0"></i> contactează cabinetul</a> nostru pentru o consultație la Dr.
                Demea. Acesta va răspunde îngrijorărilor tale și îţi va arăta cum te poate ajuta utilizarea HBOT și medicina hiperbară în
                afara indicaţiilor autorizate. </p>

            <h3>Dezavantajul tratamentelor HBOT în afara indicaţiilor autorizate şi afecţiunile implicate</h3>
            <p>Există, desigur, și dezavantaje care pot să apară în cazul unui tratamente cu oxigen hiperbaric (HBOT) în afara indicaţiilor
                autorizate. Companiile de asigurări nu acoperă de obicei tratamentele HBOT care nu sunt aprobate de FDA. În ciuda
                numeroaselor studii și a succesului dovedit al tratamentelor în afara indicaţiilor autorizate, acoperirea este de obicei
                refuzată. Din păcate, este posibil să treacă mult timp până când multe afecţiuni sau traumatisme vor primi aprobarea să fie
                tratate de medicina hiperbară prin HBOT. Brevetele pentru camerele hiperbarice s-au epuizat cu mult timp în urmă, deci nu
                există mulţi bani investiţi în medicina hiperbară și HBOT, spre deosebire de produsele farmaceutice noi. Nu sunt disponibile
                fonduri pentru studiile necesare pentru a primi o nouă aprobare FDA. </p>
            <p>Dr. Demea, împreună cu alți medici și cercetători, continuă să pledeze pe lângă agențiile guvernamentale pentru finanțarea
                HBOT. </p>
            <p>Fă următorul pas în procesul tău de vindecare și vezi cum poţi beneficia de HBOT!!</p>

            <p class="py-3 px-3 border border-dark bg-success text-white">
                Sună la cabinetul nostru astăzi pentru mai multe informații sau pentru a programa o primă consultație la Dr. Demea și echipa
                sa. De asemenea, poți vizita pagina de
                <a [routerLink]="'/contact'" [title]="'Continuați spre pagina de contact'"
                   class="nav-link d-inline-block p-0 text-white">
                    <i class="fa fa-link mt-0 mb-0"></i> contact </a> pentru a completa formularul, iar în cel mai scurt timp vei fi
                contactat de către
                noi!
            </p>

            <h3>AFECŢIUNI PE CARE LE TRATĂM</h3>
            <p>Fă click pe un diagnostic din lista de afecțiuni și traumatisme pe care le tratăm la Centrul Medical Review pentru a afla cum
                te poate ajuta un tratament cu oxigen hiperbaric (HBOT) și medicina hiperbară. </p>

            <ul class="list-unstyled">
                <li>
                    <a [routerLink]="['/HBOT-si-abcesul-intracranian']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Abcesul intracranian</a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-tratamentul-arsurilor']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Arsurile </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-boala-Alzheimer']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Boala Alzheimer </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-boala-arteriala-periferica']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Boala arterială periferică </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-boala-lyme']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Boala Lyme</a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-pacientii-cu-cancer']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Cancerul </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-durerile-de-cap-de-tip-migrena']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Durerile de cap de tip migrenă</a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-emboliile-aeriene-sau-gazoase']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Emboliile aeriene sau gazoase</a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-expunerea-la-mucegai-si-toxicitate-de-mucegai']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Expunerea la mucegai și toxicitate de mucegai </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-grefele-lambourile-compromise']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Grefele și lambourile compromise </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-intoxicatia-cu-monoxid-de-carbon']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Intoxicaţia cu monoxid de carbon </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-leziunile-cerebrale-traumatice']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Leziunile cerebrale traumatice </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-leziunile-prin-zdrobire-sindromul-de-compartiment']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Leziunile prin zdrobire / sindromul de compartiment </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-mionecroza-clostridiala']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Mionecroza clostridială</a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-necroza-radiationala']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Necroza provocată de radiație </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-osteomielita-refractara']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Osteomielita (refractară)</a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-tratamentele-pentru-paralizie-cerebrala-la-copii']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Paralizia cerebrală </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-pierderea-subita-a-auzului']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Pierderea subită a auzului </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-boala-arteriala-periferica']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Regenerarea nervilor periferici</a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-scleroza-multipla']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Scleroza multiplă </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-sindromul-post-poliomielitic']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Sindromul post-poliomielitic </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-tratamentul-pentru-ingrijirea-plagilor']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Tratament pentru &icirc;ngrijirea rănilor </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-accidente-vasculare-cerebrale']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Tratamentul accidentului vascular cerebral </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-tratamentul-anemiei']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Tratamentul anemiei </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-tratamentele-pentru-dsr-sau-sdrc']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Tratamentul DSR sau SDRC </a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-tratamentul-fibromialgiei']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Tratamentul fibromialgiei</a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-tulburarea-de-stres-post-traumatic-tspt']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Tulburarea de stres post-traumatic (TSPT)</a>
                </li>
                <li>
                    <a [routerLink]="['/HBOT-si-fracturile-osoase']"
                       class="nav-link d-inline-block p-0 cursor-pointer">
                        <i class="fa fa-link mt-0 mb-0"></i>
                        Tulburările sau leziunile osoase </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<carousel class="banner border-success border-bottom d-none d-md-block">
    <slide>
        <picture style="display: block; width: 100%;">
            <source srcset="assets/images/banners/banner4.webp 1x, assets/images/banners/banner4.webp 2x"
                    type="image/webp">
            <source srcset="assets/images/banners/banner4.jpg 1x, assets/images/banners/banner4.jpg 2x"
                    type="image/png">
            <img alt="Aparat HBOT"
                 src="assets/images/banners/banner4.jpg"
                 style="display: block; width: 100%;"
                 title="Aparatura HBOT - terapie hiperbara CLUJ">
        </picture>
        <!--<img alt="second slide" src="assets/images/banners/banner4.webp"
             style="display: block; width: 100%;">-->
        <div class="carousel-caption d-none d-md-flex justify-content-end align-content-end">
            <div>
                <h6 class="h2">Modern</h6>
                <h1 class="h3">TERAPIE HIPERBARĂ CLUJ</h1>
                <p class="">Beneficiază de terapie hiperbară cu aparatură de ultimă generație!</p>
            </div>
        </div>
    </slide>
    <slide>
        <picture style="display: block; width: 100%;">
            <source srcset="assets/images/banners/banner1.webp 1x, assets/images/banners/banner1.webp 2x"
                    type="image/webp">
            <source srcset="assets/images/banners/banner1.jpg 1x, assets/images/banners/banner1.jpg 2x"
                    type="image/png">
            <img alt="Sala asteptare"
                 src="assets/images/banners/banner1.jpg"
                 style="display: block; width: 100%;"
                 title="Sala de asteptare - Review Cluj">
        </picture>
        <!--<img alt="first slide" src="assets/images/banners/banner1.webp">-->
        <div class="carousel-caption d-none d-md-block">
            <h6 class="h2">Primitor</h6>
            <h2 class="h3">Centrul Medical Review Cluj</h2>
            <p>Bucură-te de confort într-o clinică dotată ultramodern.</p>
        </div>
    </slide>
    <slide>
        <picture style="display: block; width: 100%;">
            <source srcset="assets/images/banners/banner2.webp 1x, assets/images/banners/banner2.webp 2x"
                    type="image/webp">
            <source srcset="assets/images/banners/banner2.jpg 1x, assets/images/banners/banner2.jpg 2x"
                    type="image/png">
            <img alt="Relaxare in aparat"
                 src="assets/images/banners/banner2.jpg"
                 style="display: block; width: 100%;"
                 title="Poți să vizionezi un film sau chiar să dormi în timpul procedurii!">
        </picture>
        <!--<img alt="third slide" src="assets/images/banners/banner2.webp">-->
        <div class="carousel-caption d-none d-md-flex justify-content-end align-content-end">
            <div>
                <h6 class="h2">Relaxant</h6>
                <h3>HBOT este non-invaziv</h3>
                <p>Poți să vizionezi un film sau chiar să dormi în timpul procedurii!</p>
            </div>
        </div>
    </slide>
</carousel>
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hbot-si-pierderea-subita-aauzului',
  templateUrl: './hbot-si-pierderea-subita-aauzului.component.html',
  styleUrls: ['./hbot-si-pierderea-subita-aauzului.component.scss']
})
export class HbotSiPierdereaSubitaAAuzuluiComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/HBOT_si_pierderea_subita_a_auzului.webp',
        jpegUrl: '/assets/images/docs/2022/HBOT_si_pierderea_subita_a_auzului.png',
        title: 'HBOT și pierderea subită a auzului'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Pierderea idiopatică subită a auzului senzorineural (PSAS), sau surditatea subită, apare de obicei pe o parte, fără o cauză de bază. Pierderea ...'
            },
            {
                name: 'keywords', content: 'hbot'
            },
        ]);
        this.title.setTitle('HBOT și pierderea subită a auzului | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

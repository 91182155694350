import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
    selector: 'app-hbot-si-tratamentul-arsurilor',
    templateUrl: './hbot-si-tratamentul-arsurilor.component.html',
    styleUrls: ['./hbot-si-tratamentul-arsurilor.component.scss']
})
export class HbotSiTratamentulArsurilorComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/HBOT_si_tratamentul_arsurilor_(1).webp',
        jpegUrl: '/assets/images/docs/2022/HBOT_si_tratamentul_arsurilor_(1).png',
        title: 'HBOT și tratamentul arsurilor'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Arsurile termice sunt rezultatul contactului pielii cu un obiect fierbinte. Arsurile pot apărea și din contactul cu focul, apa clocotită...'
            },
            {
                name: 'keywords', content: 'hbot'
            },
        ]);
        this.title.setTitle('HBOT și tratamentul arsurilor | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

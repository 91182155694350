<app-segment [image]="imageConfig">
    <header>HBOT și necroza radiațională</header>

    <p>Necroza radiațională apare ca urmare a radioterapiei pentru cancer. În timp ce radiațiile ţintite se concentrează pe distrugerea
        celulelor canceroase, țesutul sănătos din zonă este de asemenea, adesea distrus. Ca urmare, apare necroza radiațională.</p>
    <p>În timp ce unele simptome pot apărea imediat după tratament, necroza radiațională și simptomele acesteia pot să nu apară luni sau ani
        după tratamentul cu radiații. Tratamentul de primă linie este de obicei medicaţia pe bază de steroizi. Cu toate acestea, alte
        tratamente aprobate includ intervenții chirurgicale pentru îndepărtarea țesutului mort, terapia termică interstițială cu laser,
        anticoagulare, alte medicamente și HBOT. Având în vedere acest lucru, rezultatele observate la HBOT îl fac o opțiune de tratament
        neinvazivă, extrem de eficientă. Medicina hiperbară și HBOT sunt aprobate de FDA și AMA pentru tratamentul necrozei
        radiaționale.</p>
    <h2>Cancerele abdominale inferioare și necroza radiațională</h2>
    <p>La tratarea tumorilor din abdomenul inferior, cum ar fi cancerul rectal, de prostată, cervical, uterin, vezical și testicular,
        necroza radiațională este frecventă. Cu toate acestea, simptomele nu apar adesea luni de zile după tratamentele cu radiații.
        Simptomele necrozei radiaționale ca urmare a acestor tipuri de cancer pot include:</p>
    <ul>
        <li>Sângerări cronice</li>
        <li>Diaree</li>
        <li>Dureri severe</li>
        <li>Inflamație</li>
        <li>Ulcere rectale</li>
        <li>Îngustarea intestinului</li>
        <li>Fistule - conexiuni anormale formate între două spații goale, cum ar fi vasele de sânge, intestinele sau alte organe goale</li>
        <li>Cistita radiațională - Inflamația vezicii urinare</li>
    </ul>
    <p>Aceste simptome duc adesea la necesitatea unei intervenții chirurgicale, cum ar fi re-secționarea intestinului și colostomii. Cu
        toate acestea, medicina hiperbară oferă o alternativă neinvazivă cu mare succes. De exemplu, mai multe studii arată cum tratează
        HBOT necroza radiaţională abdominală:</p>
    <ul>
        <li>Într-un studiu, 53% dintre pacienții cu radiații abdominale care au primit tratament cu oxigen hiperbaric – HBOT – au prezentat
            îmbunătățiri imediate. În plus, 66% au prezentat îmbunătățiri sau recuperări complete pe termen lung.
        </li>
        <li>Un alt studiu a arătat că 87% dintre pacienții cărora li s-a administrat HBOT pentru necroză abdominală și pelviană au prezentat
            o recuperare completă.
        </li>
        <li>În acest studiu, 36% dintre pacienții cu proctită (inflamație rectală) și enterită (inflamația intestinului subțire) indusă de
            radiații au prezentat o ameliorare completă a simptomelor, în timp ce 60% au prezentat o îmbunătățire clinică marcată.
        </li>
    </ul>
    <h3>Cancerele la cap și la creier</h3>
    <p>Cancerele și tumorile capului pot afecta gâtul, gura și creierul. Radioterapia ţintită ucide celulele canceroase. Totuși, în
        același timp, dăunează și celulelor sănătoase. În special în cazul capului și creierului, zonele de țesut mort joacă un rol
        important în simptomele care apar cu necroza radiațională.</p>
    <p>Simptomele pot apărea la luni sau chiar ani după tratamentul cu radiații. Astfel:</p>
    <p>La iradierea capului, gâtului și gurii pot apărea:</p>
    <ol>
        <li>Plăgi deschise</li>
        <li>Ulcere bucale</li>
        <li>Durere la înghițire</li>
        <li>Afectarea oaselor și a țesuturilor</li>
        <li>Hemoragii (sângerări) necontrolate</li>
    </ol>

    <p> La iradierea tumorilor cerebrale pot apărea:</p>
    <ol>
        <li>Pierderea memoriei</li>
        <li>Hidrocefalie - dilatarea ventriculilor cerebrali cu exces de lichid spinal</li>
        <li>Convulsii</li>
        <li>Creșterea presiunii intracraniene</li>
        <li>Slăbiciune pe o parte a corpului</li>
        <li>Gândire afectată</li>
        <li>Modificări hormonale</li>
        <li>Tulburări de somn</li>
        <li>Scăderea dorinței sexuale</li>
        <li>Tulburări de dispoziție</li>
        <li>Greață</li>
        <li>Amețeală</li>
        <li>Schimbări de vedere</li>
        <li>Deces</li>
    </ol>
    <p>Medicina hiperbară și tratamentul HBOT prezintă un mare succes în necroza provocată de iradierea capului, gâtului și creierului.
        De exemplu, ratele de succes actuale variază de la 83 la 90 la sută dintre pacienții care se confruntă cu recuperare completă și
        chiar o reversie a posibilelor leziuni cerebrale. Ca urmare, medicii apelează adesea la medicina hiperbară și HBOT, evitând
        necesitatea intervenției chirurgicale.</p>
    <h3>Cum tratează HBOT necroza radiațională?</h3>
    <p>Terapia HBOT furnizează 100% oxigen sub presiune mai mare decât cea normală, inundând în esență corpul cu oxigen. Ca urmare,
        nivelurile mai ridicate de oxigen reduc tumefacţia și vasele de sânge și țesuturile deteriorate încep să se repare. În plus,
        nivelurile mai ridicate de oxigen favorizează creșterea țesuturilor și a capilarelor, ajutând la procesul de vindecare. Pe
        scurt, un tratament cu oxigen hiperbaric reduce necesitatea îndepărtării chirurgicale a țesutului deteriorat, ajutând corpul
        să se vindece.</p>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        Având în vedere cele de mai sus, dacă suferi de o anumită formă de necroză radiațională și doreşti mai multe informații
        despre modul în care medicina hiperbară și HBOT te pot ajuta, sună la cabinetul nostru chiar astăzi și stabileşte o
        primă consultație. Dr. Demea și echipa sa îţi vor evalua istoricul medical și vor colabora cu tine pentru a crea un plan
        de tratament. De asemenea, poți completa formularul nostru de solicitare online aici.
    </app-advert>
</app-segment>


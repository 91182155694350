import { Component, HostListener, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
    selector: 'app-ce-tratam',
    templateUrl: './ce-tratam.component.html',
    styleUrls: ['./ce-tratam.component.scss']
})
export class CeTratamComponent implements OnInit {
    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'În cadrul centrului nostru medical, beneficiezi de un departament specializat în HBOT și medicină hiperbară, pentru consultații și servicii medicale.'
            },
            {
                name: 'keywords', content: 'hbot, medicina hiperbara, tratament hiperbaric, tratament cu oxigen hiperbaric, beneficii hbot'
            },
        ]);
        this.title.setTitle('HBOT și Medicina Hiperbară: tratamente și beneficii | Oxygen Review Cluj');
    }

    @HostListener('window:scroll', []) onWindowScroll() {
        this.scrollFunction();
    }

    ngOnInit(): void {
    }

    // When the user scrolls down 20px from the top of the document, show the button
    scrollFunction() {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            document.getElementById('myBtn').style.display = 'block';
        } else {
            document.getElementById('myBtn').style.display = 'none';
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    goTo(url: string, fragment: string) {
        setTimeout(() => {
            const route = url + '/' + fragment;
            return window.location.href = route;
            // this.router.navigate([route]);
        }, 500);
    }
}

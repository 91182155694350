import { AbstractControl, ValidatorFn } from '@angular/forms';

export function InputSanityValidator(type?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value) {
            let replaced;
            let sanitized;

            // eliminate whitespace characters
            const whiteSpace = /[^\w\d\S\s]/;
            replaced = control.value.match(whiteSpace, '');
            sanitized = control.value.replace(whiteSpace, '');
            if (replaced && replaced.length) {
                console.log('The whitespace result', replaced, sanitized, replaced[0], replaced.index);
                return {hiddenCharacters: {replaced: replaced[0], sanitized}};
            }

            // eliminate scripts
            const brackets = /(<+(?:.+)>+)|(?:<+\/+(?:.+)>+)/i;
            replaced = control.value.match(brackets, '');
            sanitized = control.value.replace(brackets, '');
            if (replaced && replaced.length) {
                console.log('The scripts result', replaced, sanitized, replaced[0], replaced.index);
                return {scripts: {replaced: replaced[0], sanitized}};
            }

            // eliminate special characters
            const specialCharacters = /["]+/gi;
            replaced = control.value.match(specialCharacters, '');
            sanitized = control.value.replace(specialCharacters, '');
            if (replaced && replaced.length) {
                console.log('The special characters result', replaced, sanitized, replaced[0], replaced.index);
                return {specialCharacters: {replaced: replaced[0], sanitized}};
            }

            if (type === 'isAscii') {
                console.log('Ascii');
                // eliminate not ascii characters
                const asciiValidation = /^[a-zA-Z]([-.a-zA-Z0-9]*[a-zA-Z0-9])?$/;
                replaced = asciiValidation.test(control.value);
                if (!replaced) {
                    return {asciiErrorMessage: true};
                }
            }
        }
        return null;
    };
}

import { Component, HostListener, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-despre-hbot',
    templateUrl: './despre-hbot.component.html',
    styleUrls: ['./despre-hbot.component.scss']
})
export class DespreHbotComponent implements OnInit {
    constructor(private meta: Meta,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description', content: 'Terapia cu oxigen hiperbaric este un tratament medical cu oxigen hiperbaric care furnizează oxigen pur organismului în medii sub înaltă presiune.'
            },
            {name: 'keywords', content: 'terapia cu oxigen hiperbaric, oxigen hiperbaric, oxigenoterapia, oxigenoterapia hiperbara'},
        ]);
        this.title.setTitle('Ce este terapia cu oxigen hiperbaric? – Oxygen Review Cluj');
    }

    @HostListener('window:scroll', []) onWindowScroll() {
        this.scrollFunction();
    }

    ngOnInit(): void {
    }

    // When the user scrolls down 20px from the top of the document, show the button
    scrollFunction() {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            document.getElementById('myBtn').style.display = 'block';
        } else {
            document.getElementById('myBtn').style.display = 'none';
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
}

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
    selector: 'app-hbot-si-intocticaita-cu-monoxid-de-carbon',
    templateUrl: './hbot-si-intocticaita-cu-monoxid-de-carbon.component.html',
    styleUrls: ['./hbot-si-intocticaita-cu-monoxid-de-carbon.component.scss']
})
export class HbotSiIntocticaitaCuMonoxidDeCarbonComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/HBOT_si_intoxicatia_cu_monoxid_de_carbon_(2).webp',
        jpegUrl: '/assets/images/docs/2022/HBOT_si_intoxicatia_cu_monoxid_de_carbon_(2).png',
        title: 'HBOT și intoxicaţia cu monoxid de carbon'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Monoxidul de carbon sau CO este un gaz incolor, inodor și lipsit de gust care apare atunci când sunt arse produse precum benzina, propanul, cărbunele, lemnul sau alte surse de combustibil.'
            },
            {
                name: 'keywords', content: 'hbot'
            },
        ]);
        this.title.setTitle('HBOT și intoxicaţia cu monoxid de carbon | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { PostService } from './post.service';
import { TitleCasePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class SiteService {
    editorContentSubject = new Subject<string>();
    progressSubject = new Subject<number>();
    previousUrl: any;
    currentUrl: string;
    private editorContent: string;

    constructor(private titlecasePipe: TitleCasePipe,
                private http: HttpClient,
                private postService: PostService) {
    }

    sendMessage(messageObject: any, token?: string): Observable<any> {
        if (token) messageObject['captcha'] = token;
        return this.postService.post(messageObject, 'sendMessage');
    }
}

import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { SubscriptionsContainer } from './app.subscriptions-container';
import { SiteService } from './_services';
import * as moment from 'moment';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    isBrowser: boolean;
    subscription = new SubscriptionsContainer();
    accessibilityData: any;

    constructor(private router: Router,
                private meta: Meta,
                private title: Title,
                private siteService: SiteService,
                @Inject(PLATFORM_ID) private platformId) {
        this.isBrowser = isPlatformBrowser(platformId);
        this.meta.addTags([
            {name: 'author', content: 'klmdbase'},
            {name: 'robots', content: 'index, follow'},
            {name: 'date', content: moment().format('YYYY-MM-DD'), scheme: 'YYYY-MM-DD'}
        ]);
    }

    ngOnInit(): void {
        if (this.isBrowser) {
            // generic subscription event which will store the current and the previous urls on service level
            this.subscription.addSubscription = this.router.events.subscribe((event) => {
                if (event instanceof NavigationStart) {
                    const url = event.url;
                }

                if (event instanceof NavigationEnd) {
                    this.siteService.previousUrl = this.siteService.currentUrl;
                    this.siteService.currentUrl = event.url;
                    console.log('Previous and current url: ',
                        this.siteService.previousUrl, this.siteService.currentUrl);
                    window.scrollTo(0, 0);
                }
            });
        }
    }

    ngAfterViewInit(): void {
        if (!this.isBrowser) {
            return;
        }
        document.body.classList.remove('modal-open');
    }

    ngOnDestroy() {
        this.subscription.dispose();
    }
}

import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {AlertService} from '../../_services';
import {SubscriptionsContainer} from '../../app.subscriptions-container';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnDestroy {
    messages: any[] = [];
    subscription = new SubscriptionsContainer();

    constructor(private alertService: AlertService,
                private changeDetectorRef: ChangeDetectorRef) {
        // subscribe to home-module component messages
        this.subscription.addSubscription = this.alertService.messageSubject().subscribe(message => {
            if (message) {
                this.messages.push(message);
            } else {
                // clear messages when empty message received
                this.messages = [];
            }
            this.changeDetectorRef.detectChanges();
        });
    }

    removeAlert(dismissedMessage: any): void {
        this.alertService.clear();
        this.messages = this.messages.filter(message => message !== dismissedMessage);
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.dispose();
    }
}

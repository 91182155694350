import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { SiteService } from '../../_services';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
    downloadProgress = 0;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private siteService: SiteService,
        private router: Router) {
    }

    ngOnInit(): void {
        this.siteService.progressSubject.subscribe(value => {
            this.downloadProgress = value;
        });
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContactRoutingModule } from './contact-routing.module';
import { ContactComponent } from './contact/contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from '../_common/alert/alert.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { NgxCaptchaModule } from 'ngx-captcha';


@NgModule({
    declarations: [
        ContactComponent,
        AlertComponent
    ],
    exports: [
        AlertComponent
    ],
    imports: [
        CommonModule,
        ContactRoutingModule,
        ReactiveFormsModule,
        NgxCaptchaModule,
        AlertModule
    ]
})
export class ContactModule {
}

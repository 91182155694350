<app-segment [image]="imageConfig">
    <header>HBOT și pierderea subită a auzului</header>
    <p>Pierderea idiopatică subită a auzului senzorineural (PSAS), sau surditatea subită, apare de obicei pe o parte, fără o cauză de bază.
        Pierderea completă a auzului are loc în mai puțin de 72 de ore. Această afecțiune afectează aproximativ 15.000 de persoane în
        fiecare an, deși acest număr poate fi scăzut. Acest lucru se datorează faptului că mulți presupun că pierderea se datorează unei
        cauze simple, cum ar fi o răceală sau alergii, sau chiar cerii din ureche. În timp ce unii oameni își recuperează auzul singuri fără
        tratament medical, pierderea subită a auzului este o urgență medicală. Tratamentul întârziat al pierderii idiopatice subite a
        auzului senzorineural poate reduce șansele de recăpătare a auzului.</p>
    <p>Medicina hiperbară și tratamentele HBOT poate ajuta la tratarea pierderii auzului senzorineural.</p>
    <h2>HBOT şi pierderea idiopatică subită a auzului senzorineural</h2>
    <p>HBOT – tratament cu oxigen hiperbaric – este aprobat de FDA pentru tratamentul pierderii idiopatice subite a auzului senzorineural.
        Medicii îl prescriu adesea împreună cu corticosteroizi. Un studiu realizat în anul 2018 analizează 2041 de pacienți cu PSAS care au
        fost supuși tratamentului cu corticosteroizi singur sau coroborat cu un tratament cu oxigen hiperbaric – HBOT. Rezultatele studiului
        arată că recâştigarea absolută a auzului a fost semnificativ mai mare la pacienții supuşi HBOT, în special la pacienții cu pierderi
        severe până la profunde ale auzului la momentul inițial.</p>
    <p>În timp ce medicina hiperbară și HBOT funcționează bine în asociere cu tratamentele tradiționale, HBOT benefic pentru pacienți și ca
        terapie inițială. De asemenea, are beneficii pentru pacienții care nu răspund la tratamentele tradiționale. Un raport de caz din
        2012 se referea la un bărbat în vârstă de 37 de ani care prezenta o pierdere idiopatică subită a auzului senzorineural la nivelul
        urechii stângi. După o serie de tratamente cu corticosteroizi, acesta a dezvoltat pierderea auzului și la urechea dreaptă. Medicii
        au administrat steroizi pe membrana intratimpanică de trei ori în urechea stângă, demonstrând din nou o îmbunătățire minimă. După 36
        de zile de la debutul inițial, pacientul a decis să fie supus unui tratament cu oxigen hiperbaric sau HBOT. După a treia ședință,
        auzul normal a revenit la ambele urechi.</p>
    <h2>Ce este pierderea idiopatică subită a auzului senzorineural (PSAS)?</h2>
    <p>Pierderea idiopatică subită a auzului senzorineural este o afecțiune în care există ceva în neregulă cu organele senzoriale ale
        urechii interne sau căile nervoase dintre urechea internă și creier. În timp ce 10% dintre pacienți au la bază o cauză pentru
        această surditate subită, majoritatea pacienților nu au. Mulți oameni observă pierderea auzului atunci când se trezesc dimineața sau
        unii pot experimenta un "poc" în ureche înainte ca pierderea să aibă loc. Este posibil să simtă senzația de plinătate la nivelul
        urechii, amețeli sau ţiuituri în urechi. Pierderea auzului poate fi imediată sau pierderea poate apărea treptat, sunetele devenind
        înăbușite sau slabe.</p>
    <h3>Cauze potențiale</h3>
    <p>Termenul idiopatic se referă la o afecțiune fără cauză identificabilă. Cu toate acestea, deși este cazul la majoritatea pacienților
        cu hipoacuzie, unele cauze potențiale subiacente includ infecții bacteriene sau virale, cum ar fi boala Lyme și sifilisul, afecțiuni
        autoimune subiacente, cum ar fi lupusul sau boala Behcet, o leziune traumatică, o tulburare metabolică, cum ar fi diabet zaharat,
        sau o afecțiune neurologică subiacentă, cum ar fi scleroza multiplă. În majoritatea cazurilor în care nu se găsește nicio cauză
        subiacentă, ipotezele includ compromisul vascular, o ruptură a membranei cohleare sau o infecție virală neidentificată.</p>
    <h3>Cum se diagnostichează pierderea idiopatică subită a auzului senzorineural?</h3>
    <p>Dacă vă confruntați cu pierderea subită a auzului, medicul dumneavoastră va efectua o serie de teste pentru a exclude orice cauză
        potențială subiacentă, începând cu pierderea cauzată de o obstrucție a urechii, cum ar fi acumularea de lichid sau ceară. Următorul
        test este de obicei audiometria cu ton pur, care analizează cât de puternice trebuie să fie frecvențele înainte de a le putea auzi.
        O pierdere de cel puțin 30 de decibeli prezintă semne de PSAS. În acest caz, nivelurile normale de discurs ar suna ca o șoaptă
        slabă.</p>
    <h3>Tratamente tradiționale</h3>
    <p>În majoritatea cazurilor de pierdere a auzului în care nu există o cauză cunoscută, cursul standard al tratamentului are la bază
        administrarea de corticosteroizi. Aceştia acționează prin reducerea inflamației și tumefacţiei, precum și prin ajutarea organismului
        să lupte împotriva oricărei posibile boli. Deși pot fi administraţi sub formă de pilule, corticosteroizii sunt adesea injectați în
        urechea medie, de unde pot curge în urechea internă. Alte tratamente disponibile pot include vasodilatatoare, imunosupresoare și
        medicamente antivirale. Tratamentul trebuie să înceapă cât mai devreme posibil, pentru a evita pierderea permanentă a auzului. După
        două până la patru săptămâni de la debut, pierderea auzului este adesea ireversibilă.</p>
    <p>Un tratament cu oxigen hiperbaric și HBOT realizat timpuriu ajută la reducerea riscului de pierdere ireversibilă a auzului.
        Specialiștii noștri în medicină hiperbară îți stau la dispoziție!</p>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        SOLICITĂ O CONSULTAŢIE TELEFONICĂ CHIAR ASTĂZI!
    </app-advert>
</app-segment>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { PostConfiguration } from './post-configuration';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PostService {
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new PostConfiguration();
    protected basePath = environment.apiURL;

    constructor(private http: HttpClient) {
    }

    /**
     * Generic post operation - always POST with this service
     * @param postBody - body to send over
     * @param postUrl - URL to post to
     * @param observe - observing the ...
     * @param reportProgress - should it report the progress
     */
    public post(postBody: object, postUrl: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public post(postBody: object, postUrl: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public post(postBody: object, postUrl: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public post(postBody: object, postUrl: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (postBody === null || postBody === undefined || !postUrl) {
            throw new Error('Required parameter was null or undefined when calling generic post service.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.http.post<any>(`${this.basePath}/${postUrl}`,
            postBody,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }
}

<app-segment [image]="imageConfig">
    <header>HBOT și tratamentul arsurilor</header>

    <p>Arsurile termice sunt rezultatul contactului pielii cu un obiect fierbinte. Arsurile pot apărea și din contactul cu focul, apa
        clocotită, aburul, grăsimile, substanțele chimice, procedurile medicale cu laser și chiar arsurile solare obișnuite. În timp ce
        multe arsuri sunt simple și se vindecă singure, altele necesită îngrijiri medicale extinse și sunt predispuse la infecții, ducând în
        cele din urmă la spitalizare pe termen lung sau chiar la deces. Medicina hiperbară și HBOT (tratament cu oxigen hiperbaric) oferă o
        opțiune suplimentară de tratament pentru arsuri, care ajută la reducerea timpului necesar pentru recuperare.
    </p>

    <h2>HBOT și tratamentul arsurilor</h2>
    <p>În 1965, prin HBOT a fost tratat un grup de mineri de cărbune pentru intoxicaţia cu monoxid de carbon după un accident. În același
        timp, medicii au observat că arsurile de gradul II ale unui miner s-au vindecat mai repede decât în cazul celor care nu au
        beneficiat de un tratament cu oxigen hiperbaric. </p>

    <h3>Tratamentul HBOT al arsurilor inundă țesutul cu oxigen.</h3>

    <p>Pielea din jurul unei arsuri de gradul al doilea sau al treilea devine mai hipoxică decât în mod normal. Aceasta înseamnă că țesutul
        este deprivat de oxigen. Deoarece celulele din corp au nevoie de oxigen pentru a se vindeca, această lipsă de oxigen încetinește
        procesul de vindecare. Astfel, un tratament cu oxigen hiperbaric – HBOT – devine un instrument excelent în procesul de vindecare.
        Prin utilizarea HBOT, medicina hiperbară reușește să reducă foarte mult riscul victimelor arsurilor de a se infecta sever sau de a
        muri.</p>

    <h3>HBOT funcționează cu tratamentul tradițional al arsurilor</h3>

    <p>Pe lângă tratamentele tradiționale ale arsurilor, HBOT inundă corpul și țesutul deteriorat cu niveluri ridicate de oxigen. Acest
        lucru funcționează pentru a reduce la minimum tumefacţia, ajută la conservarea țesuturilor deteriorate și la reducerea riscului de
        moarte a țesutului. În plus, ajută la stimularea celulelor albe din sânge, care ajută la combaterea infecției și ajută închiderea
        rănilor. Oxigenul suplimentar îmbunătățește fluxul sanguin în vasele de sânge îngustate care ajută la menținerea integrității
        structurale a țesutului conjunctiv al pielii. Adăugarea unui plan de tratament hiperbaric HBOT pentru victimele arse reduce
        semnificativ timpul de vindecare. De asemenea, HBOT reduce șansa de cicatrizare și complicații severe cu până la 80%.</p>

    <h3>Statistici privind arsurile de bază</h3>

    <p>În fiecare an, în Statele Unite, peste două milioane de leziuni cauzate de arsuri necesită un fel de îngrijire medicală. Dintre
        acestea, aproximativ 75.000 necesită spitalizare, iar o treime dintre acestea necesită internare în spital timp de două luni sau mai
        mult. În medie, 14.000 de victime arse spitalizate vor muri, cel mai adesea din cauza infecțiilor avansate. Cei care se recuperează
        pot suferi de traume emoționale, traumatisme și cicatrici fizice pe termen lung.</p>

    <h3>Ce este o arsură și ce presupun diferitele tipuri?</h3>
    <p>Pielea este alcătuită din trei straturi: epiderma, derma și țesutul subcutanat.Tipul de arsură și gravitatea acesteia depind de câte
        dintre aceste trei straturi suferă daune. Arsurile simple, cum ar fi arsurile solare minore, afectează doar epiderma sau stratul
        exterior. Arsurile grave afectează stratul exterior și pătrund adânc în celelalte straturi, provocând deteriorarea pielii și a
        țesutului interior. Acest lucru provoacă tumefacţie, cicatrici și duce adesea la infecție.</p>
    <p>Arsurile sunt împărțite pe categorii în funcție de cât de profunde sunt leziunile provocate de arsură:</p>
    <ul>
        <li>Arsură de gradul al patrulea</li>
        <li>Arsură de gradul al treilea</li>
        <li>Arsură de gradul al doilea</li>
        <li>Arsură de gradul al întâi</li>
    </ul>

    <h4>Arsurile de gradul întâi</h4>
    <p>Arsurile de gradul întâi implică doar epiderma sau stratul superior al pielii. Arsurile solare medii sunt un exemplu. Semnele tipice
        includ roșeață, dureroasă la atingere, iar pielea poate prezenta ușoare umflături. Tratamentul arsurilor de gradul întâi include
        aplicarea de comprese reci și umede pentru a reduce tumefacţia și durerea. Arsura poate fi acoperită cu un bandaj steril, neadeziv,
        sau o cârpă, pentru a evita posibila infectare. De obicei, arsurile de gradul I se vindecă bine singure și îngrijirea medicală este
        necesară numai dacă victima este în vârstă sau este sugar sau arsurile acoperă o mare parte a corpului.</p>
    <h4>Arsurile de gradul al doilea</h4>
    <p>Arsurile de gradul al doilea implică nivelul epidermei și al dermei pielii. Simptomele includ o înroșire profundă a pielii, durere,
        vezicule, un aspect lucios cauzat de scurgeri de lichid și posibila pierdere a unor porţiuni de piele. Arsurile de gradul al doilea
        necesită tratament medical.</p>
    <h4>Arsurile de gradul al treilea</h4>
    <p>Arsurile de gradul al treilea sunt cele mai grave arsuri și afectează toate cele trei straturi ale pielii și sunt suficient de severe
        pentru a distruge permanent țesutul. Semnele unei arsuri de gradul III includ pierderea straturilor pielii, pielea este uscată și
        carnea tare și poate părea carbonizată sau prezintă pete care apar alb, maro sau negru. În multe cazuri, este posibil ca victima să
        nu simtă durere, deoarece țesutul este mort. Durerea poate apărea în zonele în care arsura a afectat doar primele două straturi.
        Arsurile de gradul al treilea necesită asistență medicală imediată și sunt foarte predispuse la infecții. Aceste arsuri necesită de
        obicei spitalizare și tratament pe termen lung.</p>
    <h4>Arsurile de gradul al patrulea</h4>
    <p>Arsurile de gradul al patrulea se extind dincolo de nivelul pielii și provoacă leziuni ale mușchilor, tendoanelor și chiar oaselor.
        De obicei, nu există nicio senzație în zonă, deoarece terminațiile nervoase au fost, de asemenea, distruse. Riscul de infectare în
        cazul acestor arsuri este mare. Este necesară îngrijire spitalicească pe termen lung și multe dintre aceste cazuri pot fi fatale. În
        majoritatea cazurilor, sunt necesare mai multe intervenții chirurgicale pentru îndepărtarea țesutului ars, şi sunt necesare grefe de
        piele pentru a acoperi zonele deteriorate.</p>

    <p> Medicina hiperbară își poate aduce contribuția în îmbunătățirea șanselor de vindecare în cazul acestor pacienții. Dacă dorești mai
        multe informații despre tipurile de beneficii HBOT în tratamentul arsurilor, te rugăm să ne contactezi.</p>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        SOLICITĂ O CONSULTAŢIE TELEFONICĂ CHIAR ASTĂZI!
    </app-advert>
</app-segment>


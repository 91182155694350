<main class="flex-shrink-0" role="main">
    <div class="mh-full-screen d-flex align-items-center dark-overlay">
        <img [src]="'/assets/images/logo_mare.webp'" alt="Not found" class="bg-image">
        <div class="container text-white text-lg overlay-content py-6 py-lg-0">
            <h1 class="display-3 font-weight-bold mb-5">Oops, that page is not here.</h1>
            <p class="font-weight-light mb-5">We are terribly sorry, the page you are looking for is not
                available...</p>
            <p class="mb-6"><a class="btn btn-outline-light" href="/">
                <i class="fa fa-home mr-2"></i>Start from the Homepage</a>
            </p>
            <p class="h4 text-shadow">Error 404</p>
        </div>
    </div>
</main>

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
    selector: 'app-hbot-si-accidente-vasculare-cerebrale',
    templateUrl: './hbot-si-accidente-vasculare-cerebrale.component.html',
    styleUrls: ['./hbot-si-accidente-vasculare-cerebrale.component.scss']
})
export class HbotSiAccidenteVasculareCerebraleComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/HBOT_si_accidente_vasculare_cerebrale.webp',
        jpegUrl: '/assets/images/docs/2022/HBOT_si_accidente_vasculare_cerebrale.png',
        title: 'HBOT și accidente vasculare cerebrale'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Apariția unor accidente vasculare cerebrale are loc atunci când ceva blochează fluxul sanguin către creier, lăsând zona ...'
            },
            {
                name: 'keywords', content: 'hbot'
            },
        ]);
        this.title.setTitle('HBOT și accidente vasculare cerebrale | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

<app-segment [image]="imageConfig">
    <header>HBOT și tratamentele pentru paralizie cerebrală la copii</header>

    <p>Dacă descompunem numele, cerebral înseamnă creier, iar paralizie înseamnă pierderea sau afectarea funcției motorii. Paralizia
        cerebrală reprezintă un grup de tulburări neurologice care apar la naștere sau în copilăria timpurie, care determină o pierdere a
        controlului asupra coordonării musculare și a mișcării corpului. Există numeroase cauze diferite și fiecare pacient este unic. Fără
        tratament, medicii se concentrează pe simptome și pe creșterea calității vieții. În acest punct, un plan de tratament cu oxigen
        hiperbaric sau HBOT poate face diferența la mulți pacienți cu paralizie cerebrală.
    </p>

    <h2>HBOT și paralizia cerebrală</h2>
    <p>Ideea din spatele HBOT și paralizie cerebrală este că pot exista celule cerebrale deteriorate sau inactive în jurul zonei lezate.
        Administrarea de 100% oxigen la presiune crescută inundă creierul cu oxigen, stimulând creșterea celulelor creierului, creșterea
        fluxului sanguin și stimularea celulelor inactive. Deși nu toți pacienții resimt o îmbunătățire a simptomelor în urma unui tratament
        cu oxigen hiperbaric, mulți experimentează îmbunătățiri ale vederii, auzului, abilităților cognitive și vorbirii.</p>

    <h2>Cercetare, paralizie cerebrală și HBOT</h2>
    <p>Studiile legate tratarea paraliziei cerebrale prin tratament cu oxigen hiperbaric și HBOT au arătat rezultate mixte. În timp ce
        starea unora dintre pacienți se îmbunătățeşte, pentru alții poate să nu se vadă nicio diferență. Paralizia cerebrală este o boală
        foarte individualizată, fără ca niciun pacient să fie același, așa că nu se așteaptă un răspuns pozitiv în toate cazurile.</p>
    <p>Un studiu realizat în Sao Palo, Brazilia, a examinat 230 de pacienți tratați în stadiile incipiente ale afecțiunii. Tratamentul a
        constat în 20 de ședințe de tratament cu oxigen hiperbaric (HBOT) a câte o oră, o dată sau de două ori pe zi. Rezultatele au arătat
        că participanții au manifestat o reducere semnificativă a spasticității musculare și un control muscular îmbunătățit. Părinții
        participanților au observat o creștere semnificativă a echilibrului și îmbunătățiri cognitive. Alte beneficii HBOT au inclus atenția
        sporită, memoria, înțelegerea și percepția vizuală. Studiul a arătat că oxigenul hiperbaric administrat timpuriu și tratamentul HBOT
        oferă cele mai bune rezultate la pacienții cu paralizie cerebrală.</p>
    <p>Spitalul Specializat pentru Tratamentul Rezidențial pentru Reabilitarea Copiilor cu Paralizie Cerebrală din Bulgaria a apelat la
        medicina hiperbară, utilizând HBOT ca principală formă de tratament pentru copiii cu paralizie cerebrală încă din 1997. Aceştia
        raportează îmbunătățirea funcției motorii și cognitive la 86% dintre pacienții lor după 20 de ședințe de tratament hiperbaric HBOT,
        dovedind impactul pe care medicina hiperbară îl poate avea. </p>
    <p>Un studiu din anul 2000 realizat de Dr. Maureen Packard de la Universitatea Cornell a analizat 26 de copii cu diferite grade și
        tipuri de paralizie cerebrală. Pacienții au primit 40 de sesiuni a câte o oră de HBOT – tratament cu oxigen hiperbaric. S-au
        observat îmbunătățiri în spasticitatea musculară, precum și îmbunătățiri cognitive. La unii pacienți, îmbunătățirile musculare au
        scăzut după oprirea tratamentului, dar îmbunătățirile cognitive au rămas.</p>

    <h2>Ce este paralizia cerebrală?</h2>
    <p>Paralizia cerebrală reprezintă o afecțiune în care există o dezvoltare anormală a creierului sau o întrerupere a funcției creierului
        care determină creierul să controleze în mod necorespunzător funcția și mișcarea muşchilor. Este cea mai frecventă dizabilitate
        motorie la copii. Spre deosebire de multe afecțiuni, paralizia cerebrală nu are doar o singură cauză. În unele cazuri, cortexul
        motor cerebral al creierului nu se dezvoltă corespunzător în timpul creșterii fetale. Acest copil se naște cu paralizie cerebrală
        congenitală, deși simptomele pot să nu apară luni sau chiar ani după naștere.</p>
    <p>Lezarea creierului în timpul dezvoltării fetale, în timpul nașterii sau chiar după naștere poate duce la paralizie cerebrală. Acest
        lucru are ca rezultat paralizia cerebrală dobândită și vătămările pot apărea din incidente precum meningita bacteriană, encefalita
        virală, o perturbare a fluxului sanguin către creier, o leziune a capului sau abuzul asupra copiilor. În timpul unei nașteri
        problematice, este posibil ca sugarul să aibă o întrerupere a oxigenului spre creier. Această întrerupere a oxigenului poate duce la
        encefalopatie hipoxico-ischemică, care distruge țesutul cerebral din cortexul motor cerebral și din alte zone ale creierului.</p>

    <h3>Simptome</h3>
    <p>Fiecare persoană cu paralizie cerebrală este diferită, la fel și simptomele lor. Sugarii și copiii pot prezenta semne de întârzieri
        de dezvoltare în zonele de mișcare, cum ar fi rostogolirea, așezarea, târârea sau mersul pe jos. O pierdere a tonusului muscular
        poate avea ca rezultat un copil care se simte „flasc” atunci când este ținut sau tonusul muscular crescut poate face ca copilul să
        se simtă rigid și cu şira spinării proeminentă. În multe cazuri, poate dura luni sau ani până la primirea unui diagnostic de
        paralizie cerebrală. În timp ce aceste simptome sunt semne de avertizare timpurie, pe măsură ce copilul înaintează în vârstă,
        simptomele pot include:</p>
    <ul>
        <li>Ataxie - lipsa coordonării musculare;</li>
        <li>Spasticitate - mușchi rigizi și strânși cu reflexe exagerate;</li>
        <li>Slăbiciune în brațe și picioare;</li>
        <li>Mers alterat - acesta poate include mersul pe degetele de la picioare sau mersul în foarfecă, când coapsele și genunchii lovesc
            sau chiar se încrucișează, precum o foarfecă;
        </li>
        <li>Favorizarea unei părți a corpului față de cealaltă;</li>
        <li>Variații ale tonusului muscular - mușchii pot fi rigizi sau flasci;</li>
        <li>Dificultăți la înghițire și salivare excesivă - acest lucru poate afecta și alimentația;</li>
        <li>Dificultate în dezvoltarea vorbirii;</li>
        <li>Tremurături și mișcări musculare involuntare;</li>
        <li>Probleme cu abilități motorii mici, cum ar fi încheierea nasturilor unei cămăși, ținerea unui creion sau hrănirea.</li>
    </ul>

    <h3>Alte afecțiuni asociate cu paralizia cerebrală</h3>
    <p>În plus față de simptomele comune, alte afecțiuni sunt, de asemenea, frecvente și asociate cu paralizie cerebrală. Unele dintre
        aceste afecţiuni pot include:</p>
    <ul>
        <li>Convulsii;</li>
        <li>Dizabilitate intelectuală - 30 până la 50% dintre pacienții cu paralizie cerebrală experimentează o formă de dizabilitate
            intelectuală;
        </li>
        <li>Deformări ale coloanei vertebrale - din cauza afectării tonusului muscular, pot apărea deformări precum scolioza, cifoza și
            lordoza coloanei vertebrale; în plus, presiunea anormală și dezalinierea articulațiilor pot duce la osteoporoză;
        </li>
        <li>Deficiență de vedere - strabismul, sau ochii încrucișați, este frecvent și poate duce la deficiențe de vedere; alte complicații
            ale vederii pot include incapacitatea creierului de a procesa stimuli vizuali, vederea neclară sau orbirea;
        </li>
        <li>Pierderea auzului - adesea aceasta apare atunci când creierul a fost lipsit de oxigen;</li>
        <li>Incontinență - un control slab al mușchilor poate duce la pierderea controlului vezicii urinare;</li>
        <li>Senzații anormale - persoanele cu paralizie cerebrală pot prezenta dureri anormale faţă de ceva la fel de simplu ca o
            atingere;
        </li>
        <li>Contracturi - acestea apar atunci când mușchii se fixează strâns în poziții anormale și pot duce la deformare.</li>
    </ul>

    <h3>Diferite tipuri de paralizie cerebrală</h3>

    <p>Există patru tipuri diferite de paralizie cerebrală și sunt determinate de localizarea anomaliilor, de tipul anomaliilor și de gradul
        de dizabilitate. Cele patru tipuri diferite includ:</p>

    <ul>
        <li>Paralizie cerebrală spastică - Acesta este cel mai frecvent tip de paralizie cerebrală și afectează aproximativ 80% dintre
            pacienți. Pacienții cu paralizie cerebrală spastică au un tonus muscular crescut al mușchilor rigizi. Este împărțită în trei
            categorii. Prima este diplegia / dipareza spastică și afectează în principal mușchii picioarelor. A doua este hemiplegia /
            hemipareza spastică și afectează o parte a corpului. A treia este cvadriplegia / cvadripareza spastică, fiind cea mai severă
            formă și afectează toate cele patru membre, corpul și fața;
        </li>
        <li>Paralizia cerebrală diskinetică - Caracterizată prin mișcări necontrolate de scuturare a mâinilor și brațelor, precum și ale
            picioarelor și labelor picioarelor. Această activitate musculară hiperactivă poate afecta fața și limba;
        </li>
        <li>Paralizia cerebrală ataxică - Această formă de paralizie cerebrală afectează echilibrul și coordonarea, rezultând un mers
            nesigur și probleme cu sarcini cum ar fi întinderea după o carte;
        </li>
        <li>Paralizia cerebrală mixtă - Pacienții diagnosticați cu paralizie cerebrală mixtă au semne și simptome de la mai mult de unul
            dintre celelalte tipuri. O persoană poate avea unele membre cu mușchi liniștiți, în timp ce altele sunt prea relaxate și flasce.
        </li>
    </ul>

    <h3>Opțiuni tradiționale de tratament</h3>
    <p>Nu există nici un tratament sau o formă standard de tratament pentru paralizia cerebrală, deoarece fiecare afecțiune este diferită.
        Tratamentul se concentrează pe îmbunătățirea abilităţii pacientului, astfel încât tratamentele precum fizioterapia, terapia
        ocupațională, logopedia și terapia recreativă sunt cursul principal al tratamentului. Medicamentele sunt adesea prescrise pentru a
        relaxa mușchii rigizi, precum și injecțiile cu toxină botulinică sau o pompă implantabilă care furnizează relaxante musculare
        constante. Când spasticitatea este severă, este adesea necesară intervenția chirurgicală, prin care se pot prelungi mușchii sau
        tendoanele, se pot remedia deformările coloanei vertebrale sau se pot tăia nervii hiperactivi. Dispozitivele de asistență, cum ar fi
        aparatele dentare, penele, scaunele cu rotile, mergătoarele și multe altele pot ajuta la activitatea zilnică regulată.</p>

    <p>Medicina hiperbară poate contribui, de asemenea, semnificativ la îmbunătățirea calității vieții acestor pacienți. Un plan de
        tratament
        cu oxigen hiperbaric adecvat poate să facă, într-adevăr, o diferență notabilă.</p>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        SOLICITĂ O CONSULTAŢIE TELEFONICĂ CHIAR ASTĂZI!
    </app-advert>
</app-segment>

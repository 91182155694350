import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-segment',
    templateUrl: './segment.component.html',
    styleUrls: ['./segment.component.scss']
})
export class SegmentComponent implements OnInit {
    @Input() image: {
        width: string;
        webpUrl: string;
        jpegUrl: string;
        title: string
    } = {
        width: '',
        webpUrl: '',
        jpegUrl: '',
        title: ''
    };

    constructor() {
    }

    ngOnInit(): void {
    }

}

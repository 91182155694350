<app-segment [image]="imageConfig">
    <header>HBOT și tratamentul anemiei</header>
    <p>Anemia apare atunci când nu sunt suficiente globule roșii pentru a transporta oxigenul în tot corpul. Această lipsă de oxigen duce la
        o varietate de simptome diferite, precum și la posibile deteriorări permanente ale țesuturilor, mușchilor și vaselor de sânge.
        Anemia poate varia de la cazuri simple care se îmbunătățesc odată cu modificările dietei, până la cazuri mai severe care necesită
        proceduri medicale. Una dintre aceste proceduri constă în efectuarea unui tratament cu oxigen hiperbaric (sau HBOT) în scopul
        evitării deteriorării permanente. Utilizarea tratamentului HBOT este aprobată de FDA în cazurile severe de anemie.</p>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        SOLICITĂ O CONSULTAŢIE TELEFONICĂ CHIAR ASTĂZI!
    </app-advert>


    <h2>Când se recomandă HBOT pentru anemie?</h2>
    <p>Un tratament cu oxigen hiperbaric, terapie cunoscută sub numele de HBOT, un pacient se relaxează într-o cameră hiperbarică unde
        primește 100% oxigen la presiune crescută a aerului. Corpul anemic suferă de niveluri scăzute de oxigen din cauza globulelor roșii
        limitate, iar HBOT inundă sistemul cu oxigen. Ca urmare, nivelurile de oxigen din țesutul corpului revin la normal. În cazurile
        severe, în care apare epuizarea severă a oxigenului, HBOT poate readuce corpul într-o zonă sigură, permițând tratarea cauzelor care
        stau la baza acesteia. Medicina hiperbară sau HBOT are aprobare FDA pentru tratamentul anemiei severe și este, de asemenea, o
        alegere de primă linie pentru tratamentul anemiei cu celule în seceră (anemia falciformă).</p>
    <h3>Semne și simptome ale anemiei</h3>
    <p>Deoarece corpul nu are o cantitate adecvată de globule roșii pentru a transporta oxigenul, multe zone ale corpului suferă de
        deprivare de oxigen. Unele semne și simptome ale anemiei includ:</p>
    <ul>
        <li>Oboseală - Aceasta este mai mult decât oboseala după o zi lungă de muncă. Aceasta este constantă și nicio odihnă nu o
            ameliorează.
        </li>
        <li>Slăbiciune</li>
        <li>Piele palidă sau gălbuie</li>
        <li>Bătăi neregulate ale inimii</li>
        <li>Dispnee</li>
        <li>Dureri în piept</li>
        <li>Mâini și picioare reci</li>
        <li>O durere de cap</li>
        <li>Amețeală sau zăpăceală</li>
        <li>Pocnituri în urechi</li>
        <li>Afte pe limba netedă</li>
        <li>Poftă de gheață</li>
        <li>Unghii casante sau cădere a părului</li>
    </ul>

    <h3>Ce cauzează anemia?</h3>
    <p>Globulele roșii din corp alcătuiesc sistemul de transport al oxigenului. Când se întâmplă ceva care perturbă sistemul, apare anemia.
        Mulți factori diferiți pot contribui la anemie, și includ:</p>
    <ul>
        <li>Anemie feriprivă - Organismul necesită fier pentru producerea de globule roșii (eitrocite) în măduva osoasă. Când nivelul
            fierului scade prea scăzut, producția de globule roşii scade. Cauzele frecvente ale deficitului de fier includ:
            <ul>
                <li>Menstruație
                <li>
                <li>Boli gastro-intestinale, cum ar fi Crohn, colită ulcerativă, boală celiacă, esofagită, gastrită, diverticulită și ulcere
                    gastrice.
                <li>
                <li>O dietă vegetariană sau vegană săracă în alimente bogate în fier
                <li>
                <li>Pierdere de sânge din sângerări nazale cronice sau tulburări de sângerare
                <li>
                <li>Hemoliză intravasculară
                <li>
            </ul>
        </li>
        <li>Anemie cu deficit de vitamine - Fierul nu este singurul lucru de care organismul are nevoie pentru a produce globule roșii
            sănătoase. Deficiențele în folat și vitamina B12 pot contribui, de asemenea, la scăderea producției de globule roșii.
        <li>
        <li>Anemie cu boli cronice - Anumite afecţiuni medicale cronice, cum ar fi artrita reumatoidă, boli de rinichi, boli ale
            intestinului iritabil și orice afecțiune inflamatorie cronică poate afecta, de asemenea, producția de globule roșii.
        <li>
        <li>Anemie aplastică - Aceasta este o afecțiune rară și gravă în care corpul încetează să producă suficiente globule roșii.
        <li>
        <li>Anemie datorată bolii măduvei osoase - Mai multe boli ale măduvei osoase, cum ar fi leucemia și mielofibroza, afectează
            producția de globule roșii.
        <li>
        <li>Anemia hemolitică - În anemia hemolitică, globulele roșii mor mai repede decât pot fi produse. Ca urmare, nivelurile de oxigen
            scad.
        <li>
        <li>Anemie falciformă - Aceasta este o formă moștenită de anemie în care globulele roșii devin rigide, lipicioase și de formă
            similară cu o seceră sau semilună. Această modificare a structurii determină lipirea globulelor în vasele de sânge și
            încetinirea fluxului de sânge și oxigen în organism.
        <li>
    </ul>

    <h3>Tratamente tradiționale pentru anemie, altele decât HBOT</h3>
    <p>Cauza anemiei va determina ce opțiuni de tratament sunt disponibile. Tratamentul se concentrează de obicei pe această cauză, în
        speranța de a corecta producția de globule roșii. Opțiunile de tratament disponibile includ suplimentarea cu fier sau vitamine,
        medicamente pentru combaterea bolilor cronice, transplanturi de măduvă osoasă, transfuzii de sânge, plasmafereză sau chiar
        extirparea splinei.</p>


    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        Dacă suferi de anemie severă, sună la cabinetul nostru chiar astăzi pentru a stabili o consultație inițială. Dr. Demea și echipa sa
        îţi vor evalua istoricul medical și vor determina modul în care medicina hiperbară și un tratament HBOT te pot ajuta. De asemenea,
        poți face click aici și poţi completa formularul nostru online pentru programări.
    </app-advert>
</app-segment>

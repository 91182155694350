import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
    selector: 'app-hirudoterapie',
    templateUrl: './hirudoterapie.component.html',
    styleUrls: ['./hirudoterapie.component.scss']
})
export class HirudoterapieComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/Hirudoterapia_02.webp',
        jpegUrl: '/assets/images/docs/2022/Hirudoterapia_02.png',
        title: 'Hirudoterapie'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'În cadrul clinicii noastre, îți punem la dispoziție proceduri de hirudoterapie\n' +
                    '(terapie cu lipitori) în vederea tratării sau ameliorării...'
            },
            {
                name: 'keywords', content: 'hirudoterapie; terapie cu lipitori; terapie cu lipitori cluj ; tratament cu lipitori ;\n' +
                    'tratament cu lipitori cluj'
            },
        ]);
        this.title.setTitle('Hirudoterapie - Terapie cu lipitori | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

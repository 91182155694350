<app-segment [image]="imageConfig">
    <header>HBOT și leziunile prin zdrobire / sindromul de compartiment</header>
    <p>Leziunile prin zdrobire și sindromul de compartiment sunt două afecțiuni care rezultă din forță, traume sau presiune neobișnuită în
        organism. În multe cazuri, aceste leziuni sau modificări ale fluxului sanguin duc la pierderea oxigenului în țesut, mușchi, capilare
        și nervi. Dacă această lipsă de oxigen nu este abordată imediat, aceasta poate duce la moartea celulelor. Această moarte celulară
        are ca rezultat invaliditate permanentă sau deces. Din cauza pierderii aportului de oxigen implicat în aceste afecțiuni, opțiunea de
        tratament cu oxigen hiperbaric și HBOT este aprobată de FDA pentru a trata leziunile prin zdrobire și sindromul de compartiment.
        Este adesea una dintre primele linii de tratament.</p>
    <h2>Cum funcționează HBOT pentru tratarea leziunilor prin zdrobire și a sindromului de compartiment</h2>
    <p>Cu un tratament cu oxigen hiperbaric (HBOT), un pacient primește 100% oxigen sub presiune atmosferică crescută. Această formă de
        tratament inundă corpul cu oxigen care, la rândul său, inundă zonele deteriorate cu oxigen. Acest lucru ajută la repararea țesutului
        deteriorat. HBOT reduce, de asemenea, edemul din zonă și ajută la controlul infecției. De asemenea, stimulează formarea vaselor de
        sânge și a colagenului și reduce radicalii liberi din organism.</p>
    <p>HBOT este un tratament aprobat de FDA atât pentru leziuni prin zdrobire, cât și pentru sindromul de compartiment. Este adesea
        utilizat împreună cu tratamentul tradițional. În cazul leziunilor prin zdrobire, medicina hiperbară și HBOT cresc șansa unui
        rezultat pozitiv în urma leziunii. În sindromul de compartiment, HBOT încetinește progresia și complicațiile tumefacţiei înainte de
        a fi necesară o fasciotomie, reducând deseori nevoia de intervenție chirurgicală. Cel mai bun moment pentru a începe un tratament cu
        oxigen hiperbaric – HBOT – este imediat după accidentare, pentru a obține cele mai bune rezultate.</p>
    <h3>Ce sunt leziunile prin zdrobire?</h3>
    <p>O leziune prin zdrobire apare atunci când o vătămare sau un accident pune forță sau presiune pe o parte a corpului. Acest tip de
        leziune apare cel mai adesea atunci când două obiecte grele strâng o parte a corpului între ele. Veți vedea deseori leziuni prin
        zdrobire după un dezastru natural, cum ar fi un cutremur. Dacă un obiect, cum ar fi o bibliotecă, cade peste o persoană, prinzând-o
        dedesubt, aceasta poate provoca o accidentare. Vătămările frecvente asociate cu o leziune prin zdrobire includ:</p>
    <ul>
        <li>Sângerări
        <li>Vânătăi</li>
        <li>Fractură</li>
        <li>Lacerare</li>
        <li>Leziuni ale nervilor</li>
        <li>Infecție secundară</li>
        <li>Leziuni ale nervilor și tendoanelor</li>
        <li>Rabdomioliză</li>
        <li>Sindrom de compartiment</li>
    </ul>
    <p>Atunci când apare o leziune prin zdrobire, cu cât persoana rămâne mai mult sub un obiect zdrobit, cu atât este mai mare șansa de
        producere a unor complicații. Acestea includ leziuni tisulare, musculare, capilare și nervoase. Pe măsură ce obiectul exercită
        presiune asupra corpului, acesta perturbă fluxul de sânge, înfometând zona de oxigen. Cu cât țesutul este deprivat de oxigen un timp
        mai lung, cu atât este mai mare riscul de vătămare gravă. Dacă lipsa de oxigen spre mușchi durează prea mult, țesutul muscular
        începe să moară și eliberează mioglobina în sânge. Aceasta duce la o afecțiune numită Rabdomioliză. Această mioglobină se deplasează
        către rinichi, unde dăunează celulelor renale. Acest lucru poate duce la leziuni renale sau insuficiență renală. Alte complicații
        ale leziunilor prin zdrobire pot include osteomielita, neuniunea fracturii, lamboul eșuat și amputările.</p>
    <p>Cu ajutorul unui tratament cu oxigen hiperbaric sau HBOT, zona leziunii este inundată prin zdrobire cu niveluri crescute de oxigen,
        ajutând celulele și țesutul să se repare și să se vindece.</p>
    <h3>Ce este sindromul de compartiment?</h3>
    <p>Corpul tău este împărțit în diferite compartimente. Aceste compartimente constau din mușchi, nervi și vase de sânge separate în
        secțiuni de fascia. Fascia este un țesut strâns care menține fiecare compartiment închis. Fascia nu se extinde. Dacă o vătămare a
        unui compartiment provoacă tumefacţie, presiunea crescută împinge mușchii, nervii și vasele de sânge. Când tumefacţia și tensiunea
        arterială devin prea mari, restricționează fluxul de sânge în compartiment și pot duce la deteriorare permanentă. Dacă presiunea nu
        este eliberată, poate duce la moartea completă a țesuturilor. Atunci când moartea completă a țesutului apare la un membru, amputarea
        este adesea singura opțiune.</p>
    <p>Sindromul de compartiment apare cel mai adesea la nivelul piciorului inferior sau al antebrațului, deși poate apărea și la nivelul
        mâinilor, labelor picioarelor, coapselor, feselor și brațului. Simptomele includ:</p>
    <ul>
        <li>Pierderea senzației, amorțeală, furnicături la nivelul membrului afectat</li>
        <li>Scăderea culorii pielii</li>
        <li>Durere care nu dispare</li>
        <li>Umflături vizibile în zona afectată</li>
        <li>Încordarea mușchiului afectat</li>
        <li>Zona este sensibilă la atingere</li>
        <li>Incapacitatea de a mișca membrul afectat
        </li>
    </ul>
    <h3>Tratamente tradiționale pentru leziunile prin zdrobire și sindromul de compartiment</h3>
    <p>Leziunile prin zdrobire și sindromul de compartiment sunt urgențe medicale. În caz de leziune prin zdrobire, îngrijirea și
        tratamentul trebuie să înceapă înainte de a îndepărta chiar elementul care cauzează leziunea. În funcție de perioada de timp în care
        o zonă rămâne într-o stare zdrobită, îndepărtarea obiectului poate duce la complicații imediate. Din cauza lipsei de oxigen și a
        fluxului sanguin, celulele tisulare din zona zdrobită încep să moară. În timp ce fac mor, eliberează toxine în zona zdrobită. Prin
        urmare, atunci când îndepărtați obiectul și fluxul de sânge se reia, aceste substanțe toxice se deplasează în tot corpul. Dacă nu
        este tratat, un pacient poate experimenta rabdomioliză, insuficiență renală, aritmii cardiace sau stop cardiac din cauza creșterii
        potasiului, leziuni respiratorii sau îi poate fi afectat ficatul.</p>
    <p>În cazurile de sindrom de compartiment, cursul imediat de acțiune este tăierea chirurgicală deschisă a fasciei. Acest lucru
        ameliorează presiunea cauzată de tumefacţie, intervenția chirurgicală numindu-se fasciotomie. Odată ce medicii eliberează umflătura,
        incizia este reparată.</p>
    <p>În unele cazuri, incizia trebuie să rămână deschisă pentru a menține presiunea scăzută. În acest caz, o grefă de piele este adesea
        necesară. HBOT sau un tratament cu oxigen hiperbaric poate ajuta la reducerea tumefacţiei cauzate de sindromul de compartiment și de
        complicațiile înainte de fasciotomie. În multe cazuri, reduce suficient încât să se evite o fasciotomie.</p>
    <p class="font-weight-bold">Cu cele expuse mai sus în minte, dacă doreşti mai multe informații despre tipurile de beneficii HBOT,
        medicina hiperbară și beneficiile acesteia pentru leziuni prin zdrobire sau sindromul de compartiment, vizitează pagina noastră <a
            [routerLink]="'/contact'" [title]="'Continuați spre pagina de contact'"
            class="nav-link d-inline-block p-0">
            <i class="fa fa-link mt-0 mb-0"></i> aici</a> și completează
        formularul de solicitare sau sună la cabinetul nostru pentru a stabili o consultație inițială.</p>
</app-segment>


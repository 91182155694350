import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SubscriptionsContainer } from '../../app.subscriptions-container';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
    onContact = false;
    onDashboard = false;
    subscription = new SubscriptionsContainer();

    constructor(private route: Router) {
    }

    ngOnInit(): void {
        this.subscription.addSubscription = this.route.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.url.indexOf('contact') > -1) {
                    return this.onContact = true;
                }
                if (event.url.indexOf('acasa') > -1 || event.url === '/') {
                    return this.onDashboard = true;
                }
                this.onContact = false;
                this.onDashboard = false;
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription.dispose();
    }
}

<app-segment [image]="imageConfig">
    <header>HBOT şi emboliile aeriene sau gazoase</header>

    <p>Emboliile aeriene sau gazoase apar atunci când bulele de aer sau gaz intră în vasele de sânge. Aceste bule împiedică fluxul
        de sânge către zonele corpului și reduc aportul de oxigen. Cea mai frecventă formă a acestei afecțiuni este boala de decompresie,
        care apare atunci când scafandrii fac o ascensiune rapidă, spre exterior. Aceasta afecțiune poate apărea și după traumatisme la
        plămâni, în timpul anumitor proceduri chirurgicale sau în alte condiții în care se folosește gaz comprimat. Tratamentul pentru
        emboliile aeriene sau gazoase este tratamentul cu oxigen hiperbaric sau HBOT.
    </p>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        SOLICITĂ O CONSULTAŢIE TELEFONICĂ CHIAR ASTĂZI!
    </app-advert>

    <h2>Tratament pentru emboliile aeriene și gazoase</h2>
    <p>Atunci când apare o embolie aeriană sau gazoasă, cursul imediat al tratamentului este ca pacientul să fie întins și să i se
        administreze oxigen. Pacientul este transportat apoi într-o locație cu o cameră hiperbarică. Fiind principalul tratament aprobat de
        FDA pentru emboliile aeriene și gazoase, HBOT furnizează 100% oxigen la presiuni ridicate. Acesta ajută la reducerea dimensiunii
        emboliei și forțează aerul sau gazul care ajunge înapoi în corp să se dizolve în sânge.</p>
    <h2>Ce este o embolie aeriană sau gazoasă?</h2>
    <p>O embolie aeriană sau gazoasă apare atunci când se formează bule în interiorul arterelor sau venelor și blochează fluxul de sânge
        către organele vitale. În cazul bolii de decompresie, sau curburi, de exemplu, scafandrii respiră aer comprimat în timpul unei
        scufundări. Sub presiune, fiecare respirație este umplută cu mai multe molecule de azot și oxigen decât inspiri la suprafață. Acel
        surplus de azot se acumulează în organism și pe măsură ce presiunea scade în momentul în care scafandrul urcă spre suprafața apei,
        acel exces de azot poate forma bule în sânge și în țesuturile din corp. Din această cauză, scafandrii trebuie să facă o ascensiune
        lentă și treptată, permițând eliberarea azotului. Ascensiunile care nu decurg în condiții optime reprezintă principala cauză de
        deces în rândul scafandrilor.</p>
    <p>În timp ce boala de decompresie este o cauză obișnuită a emboliei aeriene și gazoase, aceasta poate să apară din multe diverse
        motive. Câteva cauze posibile includ:</p>
    <ul>
        <li>Perfuzia intravenoasă;</li>
        <li>Hemodializa;</li>
        <li>Insuflațiile laparoscopice – în timpul anumitor intervenții chirurgicale laparoscopice, aerul este suflat în zona chirurgicală
            pentru a lăsa loc chirurgului să opereze;
        </li>
        <li>Operația pe cord deschis;</li>
        <li>Biopsia pulmonară;</li>
        <li>Procedurile radiologice în care se injectează substanţă de contrast;</li>
        <li>Nașterea, în special cezariana;</li>
        <li>Colangiopancreatografia retrogradă endoscopică (ERCP) - o procedură utilizată pentru examinarea canalelor pancreatice și
            biliare;
        </li>
        <li>Irigarea plăgilor cu peroxid de hidrogen;</li>
        <li>Resuscitarea cardiopulmonară (RCP);</li>
        <li>Endoscopia gastrointestinală.</li>
    </ul>
    <h3>Simptomele unei embolii aeriene sau gazoase</h3>
    <p>Simptomele unei embolii aeriene sau gazoase variază în funcție de locul în care apare blocajul. Se pot dezvolta imediat sau pot să
        apară în timp. Unele simptome comune includ:</p>
    <ul>
        <li>Dureri musculare și articulare;</li>
        <li>Ritmuri cardiace neregulate;</li>
        <li>Vedere încețoșată sau afectată;</li>
        <li>Anxietate sau neliniște extremă;</li>
        <li>Piele iritată;</li>
        <li>Convulsii;</li>
        <li>Spumă sângeroasă din gură;</li>
        <li>Amețeli, adesea datorită tensiunii arteriale scăzute;</li>
        <li>Probleme de respirație și recuperarea respirației;</li>
        <li>Dureri în piept;</li>
        <li>Oboseală extremă;</li>
        <li>Pierderea coordonării;</li>
        <li>Halucinații;</li>
        <li>Greață sau vărsături;</li>
        <li>Cianoză (o culoare albăstruie a pielii);</li>
        <li>Paralizia sau slăbiciunea brațelor și picioarelor;</li>
        <li>Pierderea cunoştinţei;</li>
        <li>Complicații renale;</li>
        <li>Stop cardiac.</li>
    </ul>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        SOLICITĂ O CONSULTAŢIE TELEFONICĂ CHIAR ASTĂZI!
    </app-advert>
</app-segment>


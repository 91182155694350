import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hbot-si-abcesul-intracranian',
  templateUrl: './hbot-si-abcesul-intracranian.component.html',
  styleUrls: ['./hbot-si-abcesul-intracranian.component.scss']
})
export class HbotSiAbcesulIntracranianComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/HBOT_si_abcesul_intracranian.webp',
        jpegUrl: '/assets/images/docs/2022/HBOT_si_abcesul_intracranian.png',
        title: 'HBOT și abcesul intracranian'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Un abces cerebral sau intracranian este una dintre cele mai grave afecțiuni ale sistemului nervos central. Este o infecție în creier...'
            },
            {
                name: 'keywords', content: 'hbot'
            },
        ]);
        this.title.setTitle('HBOT și abcesul intracranian | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

<div class="container pt-5 pb-5">
    <div *ngIf="image && image.jpegUrl" class="row image-container mb-3">
        <div class="col">
            <picture [style]="{ width: image.width }"
                     class="img-responsive margin-bottom-15">
                <source srcset="{{image.webpUrl}} 1x, {{image.webpUrl}} 2x"
                        type="image/webp">
                <source srcset="{{image.jpegUrl}} 1x, {{image.jpegUrl}} 2x"
                        type="image/jpeg">
                <img [style]="{ width: image.width }"
                     alt="Imagine de ansamblu aparat terapie hiperbară"
                     src="{{image.jpegUrl}}"
                     title="{{image.title}}">
            </picture>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="segment">
                <h1 class="title">
                    <ng-content select="header"></ng-content>
                </h1>
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
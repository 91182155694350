import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hbot-si-tulburarea-de-stres-post-traumatic-tspt',
  templateUrl: './hbot-si-tulburarea-de-stres-post-traumatic-tspt.component.html',
  styleUrls: ['./hbot-si-tulburarea-de-stres-post-traumatic-tspt.component.scss']
})
export class HbotSiTulburareaDeStresPostTraumaticTsptComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/HBOT_si_tulburarea_de_stres_post-traumatic_TSPT.webp',
        jpegUrl: '/assets/images/docs/2022/HBOT_si_tulburarea_de_stres_post-traumatic_TSPT.png',
        title: 'HBOT și tulburarea de stres post-traumatic (TSPT)'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Tulburarea de stres post-traumatic (TSPT) este o afecțiune de sănătate mintală declanșată de un eveniment traumatic. TSPT este cel...'
            },
            {
                name: 'keywords', content: 'hbot'
            },
        ]);
        this.title.setTitle('HBOT și tulburarea de stres post-traumatic (TSPT) | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CeTratamRoutingModule } from './ce-tratam-routing.module';
import { CeTratamComponent } from './ce-tratam/ce-tratam.component';


@NgModule({
    declarations: [
        CeTratamComponent
    ],
    imports: [
        CommonModule,
        CeTratamRoutingModule
    ]
})
export class CeTratamModule {
}

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hbot-si-osteomielita-refractara',
  templateUrl: './hbot-si-osteomielita-refractara.component.html',
  styleUrls: ['./hbot-si-osteomielita-refractara.component.scss']
})
export class HbotSiOsteomielitaRefractaraComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/HBOT_si_osteomielita_(refractara)_(1).webp',
        jpegUrl: '/assets/images/docs/2022/HBOT_si_osteomielita_(refractara)_(1).png',
        title: 'HBOT și osteomielita (refractară)'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Osteomielita este o infecție bacteriană sau fungică a osului care afectează două din 100.000 de persoane în fiecare an. De obicei...'
            },
            {
                name: 'keywords', content: 'hbot'
            },
        ]);
        this.title.setTitle('HBOT și osteomielita (refractară) | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

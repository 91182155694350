<app-segment [image]="imageConfig">
    <header>HBOT și tulburarea de stres post-traumatic (TSPT)</header>

    <p>Tulburarea de stres post-traumatic (TSPT) este o afecțiune de sănătate mintală declanșată de un eveniment traumatic. TSPT este cel
        mai adesea legat de luptă sau război. Cu toate acestea, TSPT apare și după atacuri sexuale, accidente de automobile majore, moartea
        violentă a unei persoane dragi sau chiar supraviețuirea unui dezastru natural. Alianța pentru Tulburarea de Stres Post-Traumatic
        estimează că 70% dintre adulții din SUA experimentează un eveniment traumatic în viața lor. Dintre aceştia, până la 20% dezvoltă
        TSPT. Se estimează că 13 milioane de oameni din SUA suferă de TSPT la un moment dat pe parcursul vieții. În timp ce tratamentele
        tradiționale de psihoterapie și medicaţie pot ajuta, medicina hiperbară își aduce și ea contribuția: HBOT este o nouă terapie care
        promite multe în tratamentul tulburării de stres post-traumatic (TSPT).</p>
    <h2>Cum funcționează HBOT pentru pacienții cu tulburare de stres post-traumatic (TSPT)</h2>
    <p>Multe cazuri de TSPT în rândul veteranilor coexistă adesea cu cazuri de leziuni cerebrale traumatice. Drept urmare, mulți se întreabă
        dacă, în aceste cazuri, TSPT este într-un fel conectat sau rezultatul leziunii creierului. Totuși, nu toate cazurile de TSPT sunt
        legate de leziuni cerebrale. În cazurile în care nu apare nici o traumă la nivelul creierului, testele de neuroimagistică arată că
        TSPT afectează multe zone ale creierului. Această afectare include atrofia hipocampală, activitatea modificată în cortexul insular
        și hipoactivitatea axei hipotalamo-hipofizo-suprarenale. Aceste zone ale creierului disting între condiții sigure și nesigure. Ele
        controlează interpretarea de către creier a stimulilor, precum și condiționarea fricii și interacțiunile cognitive și emoționale. Ca
        urmare, toate aceste zone sunt de obicei anormale în cazurile de TSPT.</p>
    <p>Ședințele de tratament cu oxigen hiperbaric, sau tratamentele HBOT, furnizează 100% oxigen livrat sub presiune crescută, care ajută
        la tratarea tulburărilor de stres post-traumatic sau TSPT. Acest oxigen crescut ajută la vindecarea țesutului cerebral deteriorat,
        îmbunătățește fluxul sanguin, reduce inflamația și stimulează dezvoltarea de țesuturi și vase de sânge noi.</p>
    <h3>Studiile arată eficacitatea HBOT asupra tulburării de stres post-traumatic (TSPT)</h3>
    <p>În ultimii ani, multe studii noi au analizat beneficiile unui plan de tratament cu oxigen hiperbaric și HBOT pentru cei care suferă
        de leziuni cerebrale traumatice și/sau TSPT. Un studiu realizat de Dr. Paul Harsh și publicat în Medical Gas Research a analizat 29
        de militari activi sau în retragere cu leziuni cerebrale traumatice ușoare. Toți participanții au suferit de sindrom post-comoţie,
        unii suferind de TSPT. După finalizarea a 40 de sesiuni de tratament cu oxigen hiperbaric (HBOT), 52% dintre cei care au îndeplinit
        lista de verificare TSPT - Diagnostic militar nu au mai îndeplinit pragul de diagnostic. S-au produs îmbunătățiri în anxietatea
        generală, depresie și simptomele TSPT. Din cei 12 participanți care și-au exprimat gândurile suicidale la începutul studiului, 10 nu
        s-au mai simțit sinucigași. Dr. Harsh a declarat că „Reducerea simptomelor TSPT este una dintre cele mai mari reduceri ale
        simptomelor TSPT într-o perioadă de patru săptămâni, în comparaţie cu orice tratament raportat.”</p>
    <h3>Simptomele TSPT</h3>
    <p>În cazul oricărui eveniment traumatic, coșmarurile sau anxietatea severă sunt frecvente și, în majoritatea cazurilor, sunt de
        așteptat. Dar, cu timpul, majoritatea oamenilor pot procesa trauma și pot merge mai departe cu viața lor. Cu toate acestea, cu TSPT,
        aceste sentimente nu dispar. În multe cazuri, se vor înrăutăți până la punctul în care interferează cu activitățile de zi cu zi. În
        unele cazuri, simptomele nu încep imediat. Cu toate acestea, un eveniment poate apărea la câteva luni după evenimentul traumatic
        inițial care declanșează TSPT.</p>
    <p>Simptomele TSPT se împart în patru tipuri principale:</p>
    <ul>
        <li>Amintiri - Amintirile includ flashback-uri ale imaginilor de la evenimentul către care o persoană se întoarce pentru a-l retrăi.
            Imaginile sau evenimentele de zi cu zi pot readuce amintiri, rezultând reacții emoționale severe sau reacții fizice.
        <li>Evitare - O persoană cu TSPT va încerca să evite să vorbească sau să se gândească la eveniment. De asemenea, poate evita
            oamenii, locurile și lucrurile care îi amintesc de eveniment. Aceasta se poate detașa emoțional, se poate retrage din grupul de
            prieteni și familie și își poate pierde interesul pentru activitățile de zi cu zi.
        <li>Modificări ale dispoziției și gândirii - În cazul unei persoane care suferă de TSPT, este posibil să se observe o schimbare
            negativă a dispoziției sau a gândirii. Aceasta se poate simți foarte negativă cu privire la ea însăși și la viitor. Pot apărea
            probleme cu memoria. Detaşarea de familie și dificultatea de a menține relațiile devin o problemă. Persoana se simte amorțită
            din punct de vedere emoțional, îşi pierde interesul pentru activitățile preferate și îi este greu să se simtă pozitiv în
            legătură cu orice.
        <li>Modificări ale reacțiilor fizice și emoționale - Persoanele care suferă de TSPT pot fi cu ușurință înspăimântate și sunt
            întotdeauna în gardă. Somnul și concentrarea devin dificile. Ele pot părea iritabile, agresive și pot participa la un
            comportament autodistructiv, cum ar fi consumul excesiv de alcool.
    </ul>
    <h3>Tratamente tradiționale pentru tulburarea de stres post-traumatic (TSPT)</h3>
    <p>Principalele două tratamente pentru TSPT sunt psihoterapia și medicaţia. Scopul tratamentului este de a ajuta persoana să învețe
        abilități pentru a face față traumei, precum și să trateze problemele asociate cu TSPT. Aceasta include depresia, anxietatea și
        abuzul de alcool și droguri. Medicamentele includ de obicei antidepresive și medicamente anti-anxietate. Niciun tratament nu merge
        la sigur pentru fiecare pacient, deci este o chestiune de încercare și eroare pentru a vedea ce funcționează.</p>
    <p>Psihoterapeuții folosesc diferite opțiuni de tratament, inclusiv:</p>
    <ul>
        <li>Terapia cognitivă - Această terapie se concentrează direct pe trauma din spatele TSPT. Un terapeut va lucra cu pacienții pentru
            a identifica și a înțelege trauma, schimbând în același timp gândirea și răspunsul comportamental al pacientului la traumă.
        <li>Terapia prin expunere - Terapia prin expunere îi ajută pe oameni să-și înfrunte trauma, expunându-i la aceasta în timp ce se
            află într-un mediu sigur. Această terapie poate folosi stimularea mentală, scrierea sau chiar realitatea virtuală pentru a
            simula evenimentul traumatic. În timp, persoana devine mai puțin sensibilă la evenimentul traumatic.
        <li>Desensibilizare și reprocesare prin mișcarea ochilor (DRMO) - DRMO implică utilizarea terapiei prin expunere. În același timp,
            pacientul se concentrează pe un sunet specific sau mișcare oculară în timp ce apar gânduri traumatice. Acest lucru ajută la
            reprogramarea acțiunilor comportamentale atunci când sunt declanșate amintiri traumatice.
        <li>Antrenamentul de inoculare a stresului (AIS) - Scopul acestei terapii este de a reduce nivelul de anxietate prin predarea
            abilităților de înfruntare a problemelor. Această formă de terapie este adesea utilizată împreună cu alte metode de tratare a
            traumei. Scopul acestei terapii este de a schimba modul în care o persoană reacționează la stres.
        <li>Terapia centrată pe prezent (TCP) - Terapia centrată pe prezent funcționează puțin diferit prin faptul că nu se concentrează
            asupra traumei. În schimb, predă abilități de rezolvare a problemelor pentru a face față traumei.
    </ul>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        Dacă suferi de tulburare de stres post-traumatic (TSPT) și doreşti să vezi cum te poate ajuta medicina hiperbară și ce beneficii
        HBOT există, te rugăm să suni chiar astăzi la cabinetul nostru. În plus, poți face click aici pentru a completa un formular de
        contact pentru mai multe informații.
    </app-advert>
</app-segment>

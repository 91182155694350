<app-segment [image]="imageConfig">
    <header>HBOT și leziunile cerebrale traumatice</header>

    <p>Leziunile cerebrale traumatice sau LCT sunt leziuni sau traumatisme dobândite care afectează creierul. În Statele Unite, aproximativ
        1,7 milioane de LCT se produc în fiecare an și aproape 5,3 milioane de persoane trăiesc cu un handicap ca urmare a unor astfel de
        leziuni sau traumatisme dobândite. În fiecare an, între 80.000 și 90.000 de cazuri noi de handicap sunt rezultatul unor leziuni sau
        traumatisme dobândite de tipul LCT, copiii cu vârsta sub 14 ani și adulții peste 60 de ani fiind cei mai expuși riscului. O leziune
        cerebrală traumatică poate fi ușoară, cu simptome puține sau deloc, cum ar fi o comoție ușoară sau moderată până la severă, cu
        simptome majore, inconștiență, comă și chiar deces. În cazurile de leziuni cerebrale traumatice (LCT), tratamentele tradiționale
        oferă unele beneficii, dar HBOT (tratament cu oxigen hiperbaric) se dovedește a fi o terapie foarte benefică, găsind sprijin din
        partea Departamentului pentru Problemele Veteranilor.</p>
    <p>Tratamentele pentru leziunile cerebrale traumatice depind de amploarea leziunii, de traumatisme și daunele provocate. În cazurile
        ușoare, odihna și medicamentele fără prescripție medicală sunt de obicei suficiente, cu condiția să se monitorizeze eventualele
        simptome suplimentare care ar putea apărea. În cazurile mai severe, tratamentele tradiționale se concentrează pe limitarea riscului
        potențial de complicații suplimentare. În majoritatea cazurilor de leziuni sau traumatisme dobândite de tipul LCT, reabilitarea este
        necesară pentru reînvățarea abilităților pierdute din cauza leziunilor cerebrale, de obicei observate la pacienții cu sindrom
        post-comoţie. În plus, studiile au arătat că procedurile din medicina hiperbară, precum HBOT, se dovedesc benefice pentru leziunile
        cerebrale traumatice (LCT), în toate cele trei stadii. Cu toate acestea, un tratament cu oxigen hiperbaric precoce poate reduce
        severitatea leziunilor cerebrale secundare.</p>
    <h2>Cum se evaluează și diagnostichează LCT</h2>
    <p>Pentru a evalua rapid o posibilă LCT, medicii și profesioniștii din domeniul medical folosesc ceea ce se numește Scala de comă
        Glasgow. Această scală privește trei categorii: răspunsul de deschidere a ochilor, răspunsul verbal și răspunsul motor. Se acordă
        puncte fiecărui răspuns posibil din fiecare categorie. Cu cât punctele sunt mai mari, cu atât este mai puțin gravă leziunea. În
        cazurile severe, CT și RMN vor căuta sângerări în creier, cheaguri, țesut învineţit și tumefacţie. Potrivit Institutului Național
        pentru Tulburări Neurologice și Accident Vascular Cerebral, un test de sânge a fost aprobat în februarie 2018. Indicatorul de
        traumatisme cerebrale măsoară două proteine: UCH-L1 și GFAP. Aceste proteine sunt eliberate atunci când apare o leziune în creier.
        Nivelurile ridicate la acest test pot dezvălui afectări ale creierului cu leziuni intracraniene, de obicei găsite doar cu o scanare
        CT.</p>
    <h2>Studii care arată eficacitate și beneficii HBOT în cazul leziunilor cerebrale traumatice (LCT)</h2>
    <p>Numeroase studii, până în 1964, au analizat tipul de beneficii HBOT pentru pacienții cu leziuni cerebrale traumatice (LCT). Primul
        studiu (Fasano și colab.) din 1964 a arătat efectul terapeutic asupra pacienților cu LCT. Studiile efectuate în 1971, 1977, 1982 și
        1994 au arătat că HBOT a redus presiunea intracraniană, a redus presiunea lichidului cefalorahidian, a îmbunătățit activitatea
        metabolică a substanței cenușii din creier și a îmbunătățit metabolismul glucozei.</p>
    <p>Un studiu realizat în anul 2013 a raportat că LCT au fost principala cauză de deces și invaliditate în Statele Unite. Cercetarea a
        vizat 56 de pacienți care au fost la 1 până la 5 ani după leziuni sau traumatisme și care sufereau de sindrom post-comoţie.
        Rezultatele studiului au arătat că HBOT a indus neuroplasticitatea care a ajutat la repararea funcțiilor creierului. Ca urmare,
        pacienții au constatat o îmbunătățire semnificativă a calității vieții.</p>
    <h2>Cum fac diferenţa medicina hiperbară și HBOT în tratamentul leziunilor cerebrale traumatice (LCT)</h2>
    <p>HBOT și un plan de tratament cu oxigen hiperbaric funcționează în cazurile de leziuni cerebrale traumatice (LCT) prin asistarea
        organismului cu neuroprotecție în creier. Cu cât oxigenul hiperbaric este administrat mai devreme, cu atât este mai eficient în
        prevenirea deteriorărilor cerebrale secundare datorate leziunilor cerebrale traumatice.</p>
    <p>Există cinci mecanisme pe care le oferă HBOT:</p>
    <ul>
        <li>Creșterea oxigenării țesuturilor - 100% oxigen într-un mediu de înaltă presiune pe care HBOT îl oferă inundă corpul cu un nivel
            crescut de oxigen. Acest lucru permite oxigenului să treacă către țesutul cerebral care poate fi nealimentat din cauza arterelor
            și capilarelor deteriorate.
        </li>
        <li>Suprimarea inflamației - În LCT, răspunsul inflamator normal la leziune poate contribui la afectarea secundară a creierului.
            Reducerea răspunsului la inflamație este esențială. HBOT inhibă neutrofilele să adere la țintele lor, crescând citokinele
            antiinflamatoare interleukina-10, reducând microgliozele, reducând nivelul de necroză tumorală factor-a și scăzând expresia
            matricei metaloproteinazei-9.
            Aceste modificări încetinesc răspunsul inflamator normal al organismului.
        </li>
        <li>Scade apoptoza - Nivelurile crescute de oxigen livrate prin tratament cu oxigen hiperbaric și HBOT reduc apoptoza sau moartea
            celulară. Acest lucru ajută la conservarea țesutului cerebral și contribuie la recuperare.
        </li>
        <li>Reduce presiunea intracraniană (PIC) - HBOT joacă un rol critic în reducerea presiunii intracraniene. Scade endotelina. Aceste
            peptide constrâng vasele de sânge și cresc tensiunea arterială, ceea ce la rândul său crește presiunea din creier. HBOT și
            oxigenul hiperbaric îmbunătățesc, de asemenea, fluxul sanguin în interiorul și în jurul creierului, și scad rezistența vasculară
            cerebrală în leziunile cerebrale traumatice (LCT).
        </li>
        <li>Susţine neurogeneza și angiogeneza - Neurogeneza este creșterea de țesut nou, în timp ce angiogeneza este creșterea de noi vase
            de sânge. Nivelurile crescute de oxigen ajută la vindecarea țesuturilor deteriorate, stimulând în același timp creșterea
            țesutului nou. În același mod, susţine dezvoltarea de noi vase de sânge. În timp ce acest mecanism al HBOT este benefic în
            momentul producerii leziunii, acesta oferă, de asemenea, un mare beneficiu pentru cei cu sindrom post-comoţie.
        </li>
    </ul>
    <h3>HBOT, Problemele Veteranilor și Departamentul de Apărare</h3>
    <p>În ciuda numeroaselor studii care arată multitudinea de beneficii HBOT asupra LCT, Departamentul pentru Probleme ale Veteranilor din
        Statele Unite a refuzat să aprobe și să plătească pentru tratament cu oxigen hiperbaric sau HBOT pentru soldații cu LCT și TSPT
        cronice induse de explozie.</p>
    <p>După un studiu realizat de doctorul Paul G. Harch și echipa sa în 2011, punctele de vedere despre HBOT și tratamentul cu oxigen
        hiperbaric pentru LCT au început să se schimbe. Studiul lui Harch, publicat în Journal of Neurotrauma, a tratat 16 veterani activi
        și în retragere cu HBOT. Toți cei 16 au suferit LCT cu cel puțin trei ani înainte de studiu și sufereau de sindrom post-comoţie și
        TSPT. După 40 de ședințe de tratament cu oxigen hiperbaric într-o lună, veteranii au prezentat o îmbunătățire substanțială a
        simptomelor post-comoţie, cum ar fi durerile de cap, funcția cognitivă, creșterea fluxului cerebral și îmbunătățirea calității
        vieții. Câștigurile cognitive au fost atât de incredibile, încât veteranii au obținut o creștere de aproape 15 puncte la testarea
        IQ. În plus față de tratarea LCT, cercetătorii au observat și îmbunătățiri ale simptomelor TSPT.</p>
    <p>Un alt studiu al dr. Harch, publicat în 2017, a analizat 30 de pacienți cu sindrom post-comoţie, cu sau fără TSPT. După tratamentele
        HBOT, veteranii au constatat o îmbunătățire semnificativă a examenelor neurologice, IQ, de memorie, atenție și calitatea vieții. Au
        prezentat, de asemenea, niveluri reduse de anxietate, TSPT, depresie și intenție de suicid.</p>
    <h3>HBOT primește aprobarea pentru tratamentul LCT</h3>
    <p>Cu alte studii care arată rezultate similare, Administrația Veteranilor și Departamentul de Apărare au aprobat și vor plăti pentru
        tratament cu oxigen hiperbaric și terapie HBOT pentru LCT și TSPT în șapte state (AL, FL, GA, MD, NJ, TX și OK) la momentul actual.
        Există 19 Legislatori ai Congresului SUA care încurajează în prezent Administrația Veteranilor și Departamentul de Apărare să
        utilizeze HBOT pentru LCT și TSPT.</p>
    <h3>Ce cauzează LCT?</h3>
    <p>O leziune cerebrală traumatică apare atunci când ceva perturbă funcția normală a creierului. Aceasta se poate întâmpla printr-o
        simplă lovitură la cap după o cădere sau când, de exemplu, fotbaliștii (fotbal american) se ciocnesc, mai întâi cu casca, unul de
        celălalt pe teren. Impactul violent al capului de un alt obiect scutură creierul din interiorul craniului, provocând leziuni sau
        traumatisme. O LCT poate apărea și în cazul unei leziuni care străpunge craniul și țesutul cerebral. Statisticile estimează că 50
        până la 70% din LCT apar în cazul coliziunilor autovehiculelor. La copii, 21% din LCT apar în timpul activităților sportive și
        recreative. În armată, personalul de serviciu activ suferă de LCT din cauza suflului exploziilor. Se crede că unda de presiune care
        apare în timpul unei explozii trece prin creier, rezultând întreruperea funcției creierului. La sugari, agitarea violentă duce la
        sindromul copilului zgâlţâit și la LCT.</p>
    <h3>Stadiile LCT</h3>
    <p>Există trei stadii ale LCT: acut, subacut și cronic. Faza acută are loc în 24 de ore de la leziunea inițială. Stadiul subacut este la
        câteva zile după leziune. Stadiul cronic apare la săptămâni, luni și chiar ani după leziunea inițială. Simptome noi se pot dezvolta
        în toate stadiile, secundare tumefacţiei și scăderii nivelului de oxigen din creier.</p>
    <h3>Simptomele unei LCT minore</h3>
    <p>O LCT ușoară poate apărea dintr-o simplă cădere sau dintr-un accident și, în multe cazuri, o persoană poate să nu prezinte niciun
        simptom în afară de o durere de cap minoră. În majoritatea cazurilor, nu îşi va pierde cunoștința sau îşi poate pierde cunoștința
        doar pentru câteva secunde când se produce leziunea. Cu toate acestea, pot apărea alte simptome în cazul unei LCT minore, de care
        este posibil să nu fie conștientă. Aceste simptome includ:</p>
    <ul>
        <li>Durere de cap</li>
        <li>Greață sau vărsături</li>
        <li>Dificultate la vorbire</li>
        <li>Amețeli sau pierderea echilibrului</li>
        <li>Tulburări de somn - aceasta poate include oboseală, dificultăți de somn sau somn mai mult decât în mod normal</li>
        <li>Întreruperi senzoriale - aceasta poate include vedere încețoșată, sensibilitate la lumină și sunet, ţiuituri în urechi,
            modificări ale gustului și modificări ale mirosului
        </li>
        <li>Probleme cognitive - pierderea memoriei, probleme de concentrare, modificări ale dispoziției, depresie sau senzație de
            anxietate
        </li>
    </ul>
    <h3>Simptomele unor leziuni cerebrale traumatice moderate până la severe</h3>
    <p>LCT moderată până la severă apare în cazul unor leziuni sau traumatisme mai semnificative. În timp ce LCT ușoară afectează temporar
        țesutul cerebral, leziunile moderate până la severe provoacă vânătăi la nivelul creierului, țesuturi rupte, sângerări și alte
        complicații fizice. Cei cu traumatisme cerebrale moderate până la severe vor prezenta aceleași simptome ca și un caz ușor, dar
        într-o măsură mai mare. Alte simptome pot include:</p>
    <ul>
        <li>Pierderea cunoștinței timp de câteva minute până la ore, posibilitatea apariției comei</li>
        <li>Cefalee care continuă să se înrăutățească și să devină mai intensă</li>
        <li>Atacuri sau convulsii</li>
        <li>Vărsături sau greață care nu dispar</li>
        <li>Modificări ale vederii - pupile dilatate, vedere dublă, pierderea mobilităţii ochilor, sensibilitate la lumină și posibilă
            orbire
        </li>
        <li>Pierderea de lichid - Este posibil să se observe un lichid limpede (lichid cefalorahidian) care curge din nas sau urechi</li>
        <li>Incapacitatea de a se trezi din somn</li>
        <li>Amorțeală și slăbiciune la degete și la picioare</li>
        <li>Confuzie și pierderea coordonării</li>
        <li>Modificări comportamentale - proastă dispoziție, agitație</li>
        <li>Modificări ale vorbirii - este posibil să observi vorbirea neclară, incapacitatea de a înțelege cuvintele, incapacitatea de a
            găsi cuvintele pe care doreşti să le spui
        </li>
        <li>Pleoape picate sau slăbiciune facială</li>
        <li>Pierderea controlului intestinului sau al vezicii urinare</li>
        <li>Scăderea ritmului de respirație cu creșterea tensiunii arteriale</li>
    </ul>
    <h3>Tipuri de leziuni și traumatisme observate cu LCT</h3>
    <p>Când vine vorba de LCT, pot apărea multe traumatisme diferite la nivelul craniului și creierului. Contuziile și hematoamele, adesea
        denumite leziuni de masă, sunt zone de traumatisme localizate în zona din jurul creierului sau a țesutului cerebral. Hematoamele
        sunt cheaguri de sânge în creier sau la suprafață. Hematoamele obișnuite asociate cu LCT includ hematoame epidurale care apar între
        stratul protector al creierului și craniu, sau hematoame subdurale care stau direct pe creier. Contuziile sunt vânătăi ale țesutului
        cerebral, unde s-a scurs sânge. În plus, hemoragia sau sângerarea în țesutul cerebral este, de asemenea, frecventă.</p>
    <p>Leziunile difuze sunt puțin mai complicate și adesea nu pot fi văzute prin testare sau imagistică. O leziune axonală difuză afectează
        axonii din celulele nervoase care permit comunicarea. Când aceștia sunt lezaţi, comunicarea este întreruptă sau pierdută complet,
        ducând deseori la dizabilități severe. Ischemia este un aport insuficient de sânge către creier, care limitează adesea aportul de
        oxigen către creier.</p>
    <p>Fracturile craniene sunt frecvente în cazul LCT. Spărturile simple sau fisurile se vindecă de obicei singure. Cu toate acestea, cea
        mai mare preocupare în cazul fracturilor craniene este atunci când acestea pătrund în creier sau apar la baza creierului, lângă
        nervii și arterele majore. Fracturile din apropierea sinusurilor pot duce la scurgerea lichidului cefalorahidian din nas sau urechi.
        În plus, fracturile craniene depresurizate apasă pe creier și deseori necesită o intervenție chirurgicală pentru eliberarea
        presiunii.</p>
    <h3>Complicații asociate cu leziunile cerebrale traumatice (LCT)</h3>
    <p>LCT moderată până la severă poate duce la modificări prelungite sau permanente. Din această cauză, cu cât LCT este mai severă, cu
        atât este mai mare riscul acestor complicații și dizabilități pe termen lung. Unele complicații pot dura perioade scurte, pot fi
        remanente câteva luni sau pot provoca daune permanente.</p>
    <p>Aceste complicații includ:</p>
    <ul>
        <li>Modificări ale cunoştinţei - O LCT severă poate afecta nivelul de cunoştinţă al unei persoane. Aceasta include comă, stare
            vegetativă și chiar moarte cerebrală.
        </li>
        <li>Convulsii - Se pot dezvolta convulsii și sunt denumite epilepsie post-traumatică.</li>
        <li>Infecții - Atunci când craniul se fracturează, lacrimile afectează țesutul protector din jurul creierului, permițând bacteriilor
            să infecteze țesutul.
        </li>
        <li>Hidrocefalie - Lichidul cefalorahidian se acumulează în spațiile din jurul creierului, provocând presiune și tumefacţie.</li>
        <li>Deteriorarea vaselor de sânge - Se pot produce daune vaselor mici și mari găsite în creier. Aceste daune privează creierul și
            țesuturile din jur de oxigen.
        </li>
        <li>Deteriorarea nervilor cranieni - LCT care apare la baza craniului poate susţine leziuni ale nervilor cranieni. Deteriorarea
            acestor nervi poate duce la o varietate de complicații, cum ar fi paralizia.
        </li>
        <li>Probleme cognitive și de comunicare.</li>
        <li>Modificări comportamentale - LCT poate afecta adesea bunăstarea emoțională a unei persoane și poate duce la schimbări
            comportamentale.
            <p>Stări precum depresia, anxietatea, furia, dificultatea de autocontrol și incapacitatea de a face față situațiilor sociale
                sunt frecvente în cazul LCT severe.</p>
        </li>
        <li>Boli degenerative ale creierului - Cercetările arată că LCT repetate cresc riscul de boli degenerative ale creierului.</li>
    </ul>
    <p>Aceste simptome pot apărea în cazul unei LCT moderate până la severe și pot dura de la câteva săptămâni la câteva luni. În acest
        interval de timp, se consideră simptome persistente post-contuzive. Când simptomele durează mai mult de câteva săptămâni sau devin
        permanente, se numeşte sindrom post-comoţie.</p>
    <h3>Tratamente tradiționale ale leziunilor cerebrale traumatice (LCT)</h3>
    <p>Există puține lucruri care pot fi făcute pentru reversarea daunelor inițiale cauzate creierului de la leziuni sau traumatisme.
        Accentul tratamentului inițial pe leziunile cerebrale traumatice (LCT) se pune pe stabilizarea pacientului și concentrarea pe
        reducerea oricăror traumatisme la nivelul creierului din cauza fluxului sanguin slab și a lipsei de oxigen. În cazurile ușoare de
        leziuni cerebrale, odihna și analgezicele fără prescripție medicală sunt suficiente.</p>
    <p>În cazurile mai severe, tratamentele variază și pot include:</p>
    <ul>
        <li>Medicaţia - Medicamentele sunt administrate pentru a reduce posibilitatea apariției complicațiilor. Diureticele ajută la
            reducerea acumulării de lichide în țesuturi și la reducerea presiunii asupra creierului. Deoarece convulsiile sunt frecvente în
            cazul LCT severe, medicamentele anticonvulsive sunt adesea administrate pentru a reduce riscul de convulsii. În cazurile severe
            în care vasele de sânge sunt deteriorate și aportul de oxigen este redus, medicii pot administra medicamente care induc comă.
            Creierul necesită mai puțin oxigen atunci când este în stare de comă.
        </li>
        <li>Intervenţia chirurgicală - Chirurgia de urgență este adesea un tratament necesar în cazurile severe de LCT pentru a reduce
            deteriorarea țesutului cerebral. Poate fi necesar să se îndepărteze cheagurile de sânge care pun presiune pe creier sau să se
            repare fracturile craniene. De asemenea, pentru a opri sângerările excesive din creier sau pentru a crea o fereastră în craniu
            pentru a scurge lichidul cefalorahidian sau pentru a permite mai mult spațiu pentru tumefacţie.
        </li>
        <li>Reabilitarea - Reabilitarea apare de obicei în cazurile severe în care dauna cerebrală a afectat funcția normală. Reabilitarea
            poate include psihiatri, terapie ocupațională, kinetoterapie, logopedie și neuropsihologie, terapeuți recreaționali și multe
            altele.
        </li>
    </ul>
    <h3>Fă următorul pas în planul tău de tratament al LCT</h3>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        Dacă tu sau un membru al familiei ați suferit o leziune cerebrală traumatică (LCT) sau suferiți de sindrom post-comoţie și doriți
        informații despre medicina hiperbară și HBOT, sună astăzi la Dr. Demea și echipa sa. Aceștia vor stabili o primă consultație în care
        Dr. Demea va trece în revistă dosarele medicale și modul în care un tratament cu oxigen hiperbaric vă poate ajuta să vă îmbunătățiți
        calitatea vieții. De asemenea, poți vizita pagina noastră aici pentru a completa un formular de contact și un membru al cabinetului
        te va contacta ulterior.
    </app-advert>
</app-segment>


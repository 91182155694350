<app-segment [image]="imageConfig">
    <header>HBOT și osteomielita (refractară)</header>

    <p>Osteomielita este o infecție bacteriană sau fungică a osului care afectează două din 100.000 de persoane în fiecare an. De obicei,
        osteomielita răspunde bine la antibiotice sau intervenții chirurgicale. Cu toate acestea, osteomielita refractară este o infecție
        cronică care nu răspunde la opțiunile tradiționale de tratament. În aceste cazuri, HBOT este aprobată de FDA pentru a ajuta la
        combaterea infecției cu osteomielită.</p>
    <h2>Tratamentele HBOT și osteomielita</h2>
    <p>În cazurile de osteomielită cronică refractară, rata de remisiune a unui tratament cu oxigen hiperbaric (HBOT) este de 81% până la
        85%, fiind aprobat de FDA. Cu HBOT, un pacient se relaxează într-o cameră hiperbarică și primește 100% oxigen la presiune
        atmosferică crescută. Această procedură inundă corpul cu oxigen, inclusiv zone cum ar fi o infecție osoasă în care alimentarea slabă
        cu sânge limitează nivelurile de oxigen. Majoritatea bacteriilor și ciupercilor se dezvoltă în prezenţa unor cantități reduse de
        oxigen, astfel încât adiţia de oxigen este adesea suficientă pentru a opri răspândirea infecției. Un nivel crescut de oxigen
        stimulează sistemul imunitar să lupte împotriva infecției și stimulează țesutul și vasele de sânge bolnave să se repare și să
        crească. HBOT, ca tratament cu oxigen hiperbaric, reduce de asemenea tumefacţia oaselor și a țesutului înconjurător asociată cu
        osteomielita.</p>
    <h2>Studii privind HBOT și osteomielita</h2>
    <p>Numeroase studii au analizat utilizarea HBOT pentru a trata osteomielita cronică refractară. Un studiu realizat în anul 2003 a
        examinat 14 pacienți cu osteomielită cronică refractară la nivelul a tibiei. Anterior, pacienții au primit tratament antibiotic și
        intervenție chirurgicală fără succes, având un număr mediu de 5,4 operații înainte de a primi un tratament cu oxigen hiperbaric
        HBOT. Cu toate acestea, odată tratată cu o medie de 33,6 tratamente HBOT, infecția s-a vindecat și 11 pacienți nu au mai
        experimentat recurența infecției.</p>
    <p>Un alt raport din 2013 relatează cazul unui pacient cu infecție osoasă ca o complicație a unei fracturi închise. Pacientul a avut mai
        multe comorbidități și nu a răspuns bine la intervențiile chirurgicale sau la terapiile cu antibiotice. Pacientul a început 30 de
        ședințe de tratament cu oxigen hiperbaric la două atmosfere (2 ATA) și s-a observat o îmbunătățire rapidă a stării sale. Ca
        rezultat, pacientul nu a prezentat niciun simptom la controlul de urmărire, peste 10 luni.</p>
    <h3>Ce este osteomielita?</h3>
    <p>În majoritatea cazurilor de osteomielită, organismul responsabil pentru 90% din cazuri este Staphylococcus, în timp ce altele includ
        Haemophilus influenzae și Salmonella. Bacteriile se deplasează prin fluxul sanguin până la os sau se răspândesc din țesutul infectat
        sau plaga deschisă din apropiere. Odată ce bacteriile infectează osul, acestea provoacă tumefacţie. Țesutul tumefiat și puroiul pun
        presiune pe peretele exterior al osului, comprimând astfel vasele de sânge din os și reducând aportul de oxigen. Acest lucru duce la
        moartea osului dacă nu este tratat. În cazurile cronice, acest aport de sânge devine adesea blocat și nu mai permite fluxul de
        sânge. Atunci când fluxul sanguin este întrerupt, globulele albe care luptă împotriva infecției nu mai pot călători la locul
        respectiv și nici antibioticele utilizate pentru a ucide infecția.</p>
    <h3>Cauzele osteomielitei</h3>
    <p>Oasele tale sunt de obicei bine protejate de infecție, dar cu toate acestea, osteomielita se poate produce. Modurile tipice de
        intrare a infecției includ:</p>
    <ul>
        <li>Fluxul sanguin - Stafilococul este cea mai comună bacterie care se răspândește prin fluxul de sânge, deși alte bacterii și
            ciuperci se pot răspândi în același mod. Infecțiile osoase prin fluxul sanguin infectează de obicei capetele oaselor piciorului
            și brațului sau ale vertebrelor.
        </li>
        <li>Contact direct - În această formă, bacteriile sau ciupercile intră prin contact direct cu osul. Acest lucru se poate întâmpla în
            fracturi deschise, proceduri chirurgicale pe os sau orice tip de leziune în care ceva străpunge osul. Osteomielita poate apărea
            și atunci când chirurgii folosesc articulații artificiale sau cleme metalice în timpul unei intervenții chirurgicale pe os.
        </li>
        <li>Răspândirea din țesutul infectat adiacent - Când apare o infecție în țesutul moale, dacă nu este bine tratată, se poate răspândi
            la os în câteva zile sau săptămâni. Infecțiile datorate radioterapiei, cancerului, ulcerelor pielii (cum ar fi ulcerele
            piciorului diabetic), infecțiilor sinusale și infecțiilor dentare sunt, de asemenea, cauze frecvente ale infecțiilor osoase.
        </li>
    </ul>
    <h3>Factori de risc crescuți pentru osteomielită</h3>
    <p>Osteomielita poate afecta persoanele de toate vârstele, deși locațiile infecției pot fi diferite. Adulții au de obicei infecții în
        vertebre și pelvis, în timp ce copiii manifestă mai multe cazuri la capătul oaselor lungi ale brațelor și picioarelor. În timp ce
        oricine poate contracta osteomielită, există câțiva factori care pot crește riscul de infecție. Aceştia includ:</p>
    <ul>
        <li>Diabet
        <li>Pacienți care primesc hemodializă</li>
        <li>Sisteme imune slăbite</li>
        <li>Boala falciformă</li>
        <li>Consumul intravenos de droguri</li>
        <li>Pacienți vârstnici</li>
        <li>Pacienți chirurgicali în care intervenția chirurgicală a implicat osul</li>
        <li>Leziuni osoase traumatice</li>
    </ul>
    <h3>Simptomele osteomielitei</h3>
    <p>Simptomele osteomielitei pot varia în funcție de tipul și localizarea infecției. În unele cazuri, nu apar simptome sau pot trece
        zile, săptămâni sau luni până ca acestea să iasă la suprafață, iar când o fac sunt adesea confundate cu alte afecţiuni posibile.
        Simptomele osteomielitei includ:</p>
    <ul>
        <li>Durere sau sensibilitate în zona infectată - aceasta poate include durere în țesutul moale din jur sau durere osoasă profundă
        </li>
        <li>Tumefacţie - ca la majoritatea infecțiilor osoase, țesutul moale din jurul osului se va umfla, poate deveni de culoare roșie și
            cald la atingere
        </li>
        <li>Febră</li>
        <li>Greață</li>
        <li>Drenajul puroiului - aceasta se produce atunci când se formează un tract sinusal de la os la suprafața pielii, permițând
            drenajul
        </li>
        <li>Disconfort general sau senzație de rău</li>
        <li>Transpirație excesivă sau frisoane</li>
        <li>Dureri în partea de jos a spatelui - aceasta apare în cazul unei infecții a coloanei vertebrale</li>
        <li>Pierderea sau scăderea mobilităţii într-o articulație</li>
        <li>Modificări ale mersului - acest lucru este adesea observat la copiii cu o infecție a oaselor picioarelor. Din cauza infecției,
            ei pot șchiopăta sau refuza să pună greutate pe un picior.
        </li>
    </ul>
    <h3>Tratamente tradiționale ale osteomielitei</h3>
    <p>Tratamentele tradiționale pentru osteomielită vizează bacteriile sau ciupercile cu antibiotice sau medicamente antifungice. Aceste
        medicamente pot fi prescrise luni de zile, în funcție de gravitatea infecției. În unele cazuri, este necesară intervenția
        chirurgicală. Chirurgii vor intra pentru a îndepărta țesutul osos mort, pentru a drena zona infectată, a îndepărta țesutul și osul
        bolnav, a restabili fluxul sanguin sau a îndepărta orice material străin din os.</p>
    <p>În cazurile de osteomielită cronică refractară, intervenția chirurgicală nu este adesea suficientă pentru a opri infecția și, în
        cazurile severe, este adesea necesară amputarea. În acest caz, medicina hiperbară – mai specific tratamentele HBOT – s-a dovedit a
        fi foarte eficientă.</p>


    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        SOLICITĂ O CONSULTAŢIE TELEFONICĂ CHIAR ASTĂZI!
    </app-advert>
</app-segment>


import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
    selector: 'app-hbot-si-pacientii-cu-cancer',
    templateUrl: './hbot-si-pacientii-cu-cancer.component.html',
    styleUrls: ['./hbot-si-pacientii-cu-cancer.component.scss']
})
export class HbotSiPacientiiCuCancerComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/HBOT_si_pacientii_cu_cancer_(1).webp',
        jpegUrl: '/assets/images/docs/2022/HBOT_si_pacientii_cu_cancer_(1).png',
        title: 'HBOT și pacienții cu cancer'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Medicina hiperbară sau, mai specific, HBOT poate juca un rol vital în procesul de tratament al multor tipuri de cancer precum și efectele secundare comune asociate tratamentelor tradiționale pentru cancer.'
            },
            {
                name: 'keywords', content: 'hbot'
            },
        ]);
        this.title.setTitle('HBOT și pacienții cu cancer | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

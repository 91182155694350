import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hbot-si-scleroza-multipla',
  templateUrl: './hbot-si-scleroza-multipla.component.html',
  styleUrls: ['./hbot-si-scleroza-multipla.component.scss']
})
export class HbotSiSclerozaMultiplaComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/HBOT_si_scleroza_multipla.webp',
        jpegUrl: '/assets/images/docs/2022/HBOT_si_scleroza_multipla.png',
        title: 'HBOT şi scleroza multiplă'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Scleroza multiplă sau SM este o boală a sistemului nervos central. SM este o boală imprevizibilă și invalidantă care, potrivit unui studiu...'
            },
            {
                name: 'keywords', content: 'hbot'
            },
        ]);
        this.title.setTitle('HBOT şi scleroza multiplă | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hbot-si-durerile-de-cap-de-tip-migrena',
  templateUrl: './hbot-si-durerile-de-cap-de-tip-migrena.component.html',
  styleUrls: ['./hbot-si-durerile-de-cap-de-tip-migrena.component.scss']
})
export class HbotSiDurerileDeCapDeTipMigrenaComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/HBOT_si_durerile_de_cap_de_tip_migrena.webp',
        jpegUrl: '/assets/images/docs/2022/HBOT_si_durerile_de_cap_de_tip_migrena.png',
        title: 'HBOT și durerile de cap de tip migrenă'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Durerile de cap de tip migrenă nu sunt durerile de cap de zi cu zi. Sunt o tulburare neurologică care afectează aproximativ...'
            },
            {
                name: 'keywords', content: 'hbot'
            },
        ]);
        this.title.setTitle('HBOT și durerile de cap de tip migrenă | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hbot-si-tratamentul-anemiei',
  templateUrl: './hbot-si-tratamentul-anemiei.component.html',
  styleUrls: ['./hbot-si-tratamentul-anemiei.component.scss']
})
export class HbotSiTratamentulAnemieiComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/HBOT_si_tratamentul_anemiei_(2).webp',
        jpegUrl: '/assets/images/docs/2022/HBOT_si_tratamentul_anemiei_(2).png',
        title: 'HBOT și tratamentul anemiei'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Anemia apare atunci când nu sunt suficiente globule roșii pentru a transporta oxigenul în tot corpul. Această lipsă de oxigen...'
            },
            {
                name: 'keywords', content: 'hbot'
            },
        ]);
        this.title.setTitle('HBOT și tratamentul anemiei | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hbot-si-boala-arteriala-periferica',
  templateUrl: './hbot-si-boala-arteriala-periferica.component.html',
  styleUrls: ['./hbot-si-boala-arteriala-periferica.component.scss']
})
export class HbotSiBoalaArterialaPerifericaComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/HBOT_si_boala_arteriala_periferica.webp',
        jpegUrl: '/assets/images/docs/2022/HBOT_si_boala_arteriala_periferica.png',
        title: 'HBOT și boala arterială periferică'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Boala arterială periferică (BAP) reprezintă îngustarea vaselor de sânge la brațe, picioare, stomac sau cap. Ca urmare a acestei...'
            },
            {
                name: 'keywords', content: 'hbot'
            },
        ]);
        this.title.setTitle('HBOT și boala arterială periferică | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

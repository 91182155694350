import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
    selector: 'app-hbot-si-tratamentul-pentru-ingrijirea-plagilor',
    templateUrl: './hbot-si-tratamentul-pentru-ingrijirea-plagilor.component.html',
    styleUrls: ['./hbot-si-tratamentul-pentru-ingrijirea-plagilor.component.scss']
})
export class HbotSiTratamentulPentruIngrijireaPlagilorComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/HBOT_si_tratamentul_pentru_ingrijirea_plagilor_(1).webp',
        jpegUrl: '/assets/images/docs/2022/HBOT_si_tratamentul_pentru_ingrijirea_plagilor_(1).png',
        title: 'HBOT și tratamentul pentru îngrijirea plăgilor'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Când te răneşti, fie că este vorba de o simplă tăietură sau de o leziune sau o plagă mai gravă, corpul tău este conceput să se repare singur. Trece prin patru stadii diferite ...'
            },
            {
                name: 'keywords', content: 'hbot'
            },
        ]);
        this.title.setTitle('HBOT și tratamentul pentru îngrijirea plăgilor | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

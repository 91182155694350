<div class="container pt-4 pb-5">
    <div class="row">
        <div class="col-md-5 box">
            <div class="d-flex justify-content-start mb-3">
                <picture [style]="{height: '110px', width: 'auto'}" class="fixed-width-logo">
                    <source srcset="/assets/images/logo_8.webp 1x, /assets/images/logo_8.webp 2x"
                            type="image/webp">
                    <source srcset="/assets/images/logo_8.png 1x, /assets/images/logo_8.png 2x"
                            type="image/png">
                    <img alt="Oxygen Review Logo"
                         [style]="{height: '110px', width: 'auto'}"
                         src="/assets/images/logo_8.png"
                         title="Oxygen Review Logo Image">
                </picture>
                <!--<img [src]="'/assets/images/logo_8.webp'"
                     [style]="{height: '110px', width: 'auto'}"
                     alt="Oxygen Review Logo"
                     class="fixed-width-logo">-->
                <div class="d-flex flex-column justify-content-center">
                    <div class="mb-0 ml-1">
                        <picture>
                            <source srcset="/assets/images/logo_transparent2.webp 1x, /assets/images/logo_transparent2.webp 2x"
                                    type="image/webp">
                            <source srcset="/assets/images/logo_transparent2.png 1x, /assets/images/logo_transparent2.png 2x"
                                    type="image/png">
                            <img alt="Oxygen Review Text"
                                 src="/assets/images/logo_transparent2.png"
                                 title="Oxygen Review Logo Text">
                        </picture>
                        <!--<img [src]="'/assets/images/logo_transparent2.webp'"
                             alt="Oxygen Review">-->
                    </div>
                    <h5 class="mb-0 subtitle text-center">
                        Terapie Hiperbara
                    </h5>
                </div>
            </div>
            <h1>Clinica Review</h1>
            <p>str. Siretului, nr. 16,<br>Cluj-Napoca, România</p>

            <hr/>

            <h2>Programări</h2>
            <p>Telefon: <a [href]="'tel:0364107206'">0364.107.206</a> sau
                <a [href]="'tel:0745659945'">0745.659.945</a>
                <br>E-mail: vreausamafacbine@terapiehiperbara.com</p>
        </div>
        <div class="col-md-5 box contact-form-container">
            <form (ngSubmit)="onSubmit()"
                  [formGroup]="contactForm"
                  id="contact-form"
                  name="contact-form"
                  novalidate="novalidate">
                <h3>PROGRAMĂRI ONLINE</h3>

                <div class="form-group">
                    <input [ngClass]="{'is-invalid': f.name?.errors && (f.name?.touched || f.name?.dirty)}"
                           autocomplete="off"
                           class="form-control"
                           formControlName="name"
                           id="name"
                           name="name"
                           placeholder="Nume"
                           type="text">
                    <p *ngIf="f.name?.errors && (f.name?.touched || f.name?.dirty)"
                       [ngClass]="{'mb-0': f.name.errors}"
                       class="text-danger">
                        Valoare invalidă
                    </p>
                </div>
                <div class="form-group">
                    <input [ngClass]="{'is-invalid': f.email?.errors && (f.email?.touched || f.email?.dirty)}"
                           autocomplete="off"
                           class="form-control"
                           formControlName="email"
                           id="email"
                           name="email"
                           placeholder="Email"
                           type="email">
                    <p *ngIf="f.email?.errors && (f.email?.touched || f.email?.dirty)"
                       [ngClass]="{'mb-0': f.email.errors}"
                       class="text-danger">
                        Valoare invalidă
                    </p>
                </div>
                <div class="form-group">
                    <input [ngClass]="{'is-invalid': f.phone?.errors && (f.phone?.touched || f.phone?.dirty)}"
                           autocomplete="off"
                           class="form-control"
                           formControlName="phone"
                           id="phone"
                           name="phone"
                           placeholder="Telefon"
                           type="text">
                    <p *ngIf="f.phone?.errors && (f.phone?.touched || f.phone?.dirty)"
                       [ngClass]="{'mb-0': f.phone.errors}"
                       class="text-danger">
                        Valoare invalidă
                    </p>
                </div>
                <div class="form-group">
                    <textarea [ngClass]="{'is-invalid': f.message?.errors && (f.message?.touched || f.message?.dirty)}"
                              autocomplete="off"
                              class="form-control"
                              formControlName="message"
                              id="message"
                              name="message"
                              placeholder="Mesaj"
                              rows="3"></textarea>
                    <p *ngIf="f.message?.errors && (f.message?.touched || f.message?.dirty)"
                       [ngClass]="{'mb-0': f.message.errors}"
                       class="text-danger">
                        Valoare invalidă
                    </p>
                </div>
                <div class="form-group">
                    <input formControlName="agreeWithTerms"
                           id="agreeWithTerms"
                           name="agreeWithTerms"
                           type="checkbox">
                    Prin trimiterea mesajului și a datelor completate mai sus
                    sunt de acord cu <a href="/gdpr" target="_blank">
                    Politica de confidențialitate a datelor cu caracter
                    personal terapiehiperbara.com
                </a>
                </div>
                <div class="form-group">
                    <input [disabled]="contactForm.invalid || !f.agreeWithTerms.value"
                           class="submit btn"
                           name="contact-form-submit"
                           type="submit"
                           value="TRIMITE">
                </div>
            </form>
        </div>
    </div>
</div>

<app-segment [image]="imageConfig">
    <header>HBOT și boala Alzheimer</header>

    <p>Boala Alzheimer este o formă de demență care începe cu pierderea ușoară a memoriei. Ea progresează către pierderea completă a
        memoriei și a capacității de a purta conversații. Potrivit Centrelor de Control și Prevenire a Bolilor, până în cinci milioane de
        persoane din Statele Unite trăiau cu boala Alzheimer în 2014. Această cifră reprezintă 60-80% din toate cazurile de demență. Nu
        există niciun leac și niciun tratament aprobat în prezent care să încetinească progresia bolii. Cu toate acestea, studii recente
        arată că medicina hiperbară, prin intermediul unui tratament cu oxigen hiperbaric sau HBOT, oferă o opțiune importantă de tratament
        pentru cei care suferă de boala Alzheimer.
    </p>

    <h2>HBOT și boala Alzheimer</h2>
    <p>Medicina hiperbară, prin tratamentul HBOT, furnizează 100% oxigen la o presiune crescută a aerului. Acest lucru permite oxigenului să
        inunde sângele, celulele și țesuturile corpului. Deoarece boala Alzheimer este rezultatul anomaliilor din creier, cercetătorii cred
        că inundarea creierului cu niveluri crescute de oxigen va avea rezultate pozitive. Un studiu publicat în 2018 a arătat exact acest
        lucru. Cercetătorii de la Universitatea din Tel Aviv au administrat ședințe de tratament cu oxigen hiperbaric – HBOT – șoarecilor
        transgenici sau șoarecilor proiectați pentru a dezvolta Alzheimer. Au fost supuşi timp de două săptămâni unor sesiuni HBOT zilnice.
        Rezultatele lor arată că tratamentele HBOT reduc deprivarea de oxigen și inflamația din creier. De asemenea, reduc povara plăcilor
        amiloide și prezintă o scădere a încurcăturilor de proteine tau în creier. Cercetătorii au identificat schimbări observabile și
        îmbunătățiri ale simptomelor comportamentale.</p>

    <p>Începerea unui tratament cu oxigen hiperbaric (HBOT) în stadiile incipiente ale bolii Alzheimer, înainte ca celulele creierului să
        sufere daune permanente, oferă cele mai bune rezultate. </p>

    <h2>Ce este boala Alzheimer?</h2>
    <p>Boala Alzheimer nu este o parte normală a îmbătrânirii, deși de obicei nu afectează pacienții decât după vârsta de 65 de ani. Cei
        care o experimentează mai devreme în viață primesc un diagnostic de Alzheimer cu debut precoce. În stadiile incipiente, pierderea
        memoriei este foarte ușoară, deseori ignorată ca simplă îmbătrânire. Pe măsură ce boala progresează, pacienții își pierd capacitatea
        de a purta o conversație normală.</p>
    <p>Cercetătorii cred că boala Alzheimer este rezultatul deteriorării celulelor creierului, principalii vinovați fiind acumularea și
        plăcile de proteine tau. Plăcile sunt depozite de fragmente beta-amiloide care se acumulează și se adună între celulele nervoase.
        Încurcăturile sunt fibre răsucite ale proteinei tau și se acumulează în interiorul celulelor. Aceste plăci și încurcături încep în
        secțiunea de memorie a creierului și continuă să se răspândească. Cercetătorii cred că aceste plăci și încurcături perturbă
        comunicarea normală în celulele nervoase. Acest lucru duce la moartea celulelor și pierderea funcției de memorie în creier. Nu
        există nici un remediu pentru Alzheimer, nici un tratament actual disponibil pentru a ajuta la încetinirea progresiei.</p>

    <h3>Semne și simptome ale bolii Alzheimer</h3>
    <p>În timp ce principalul simptom al bolii Alzheimer este pierderea memoriei, nu este vorba doar de uitarea unor lucruri. Poate
        determina o persoană să repete aceeași întrebare sau să spună aceeaşi poveste în mod repetat. Bolnavii uită conversațiile pe care
        tocmai le-au purtat. O persoană se pierde în locuri cu care era familiarizată în mod normal, de exemplu, pe drumul spre casă de la
        magazin. Uită numele membrilor familiei și obiectele cotidiene. Abilitatea de a-și aminti cuvintele sau frazele potrivite în
        conversații devine imposibilă. Pe lângă problemele de memorie, persoanele cu boala Alzheimer experimentează și alte provocări:</p>
    <ul>
        <li>Gândirea și raționamentul - Concentrarea și gândirea devin problematice, în special în ceea ce priveşte numerele. O persoană
            care obișnuia să se descurce cu sarcinile s-ar putea lupta să facă un singur lucru acum;
        </li>
        <li>Luarea deciziilor de către persoanele cu deficiențe - Capacitatea unei persoane de a lua decizii rezonabile atunci când vine
            vorba de aspecte cum ar fi îmbrăcămintea adecvată de purtat în corespondenţă cu vremea, gătirea unei mese sau chiar capacitatea
            de conducere se modifică;
        </li>
        <li>Efectuarea de sarcini simple - Sarcinile precum jocul sau pașii necesari pentru a găti o masă se pot pierde sau confunda. Chiar
            și sarcinile de bază, cum ar fi îmbrăcarea dimineața, provoacă confuzie;
        </li>
        <li>Modificări comportamentale - Pe măsură ce creierul uită și nu mai funcționează corect, stările de spirit și comportamentul se
            schimbă. Acestea includ depresie, retragere socială, schimbări de dispoziție, furie sau agresivitate inexplicabile, modificări
            ale somnului, pierderea inhibițiilor și deziluzi;
        </li>
        <li>Modificări fizice - Pe măsură ce boala progresează, aceasta începe să afecteze funcția fizică. De exemplu, o persoană poate
            întâmpina probleme de înghițire, probleme de echilibru și pierderea controlului intestinului și vezicii urinare;
        </li>
        <li>Malnutriție și deshidratare - Odată cu pierderea memoriei, oamenii uită să mănânce sau să bea, deoarece cred că deja au mâncat
            sau băut;
        </li>
    </ul>

    <h3>Opțiuni tradiționale de tratament</h3>

    <p>Tratamentul tradițional se concentrează pe încercarea de a îmbunătăți abilitățile cognitive și de a oferi un mediu sigur persoanei.
        Două tipuri de medicamente tratează în prezent simptomele cognitive. Inhibitorii colinesterazei stimulează comunicarea dintre celule
        și îmbunătățesc simptomele neuropsihiatrice. Memantina vizează rețeaua de comunicare a celulelor creierului și, în unele cazuri,
        poate încetini progresia.</p>
    <p>Tratamentul se concentrează, de asemenea, pe crearea unui mediu de acasă sigur pentru pacient. Crearea unei rutine regulate poate
        ajuta la simplificarea vieții. Aranjamentul pentru ca un prieten, un membru al familiei sau un îngrijitor regulat oferă îndrumare și
        securitate unui pacient cu Alzheimer. De asemenea, ajută la evitarea eventualelor accidente, traumatisme sau complicații.</p>
    <p>Dacă iei în considerare opțiunea unui tratament cu oxigen hiperbaric sau HBOT, nu ezita să ne contactezi. Medicina hiperbară prezintă
        beneficii HBOT în boala Alzheimer, iar specialiștii noștri sunt pregătiți să analizeze cu atenție fiecare caz.</p>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        SOLICITĂ O CONSULTAŢIE TELEFONICĂ CHIAR ASTĂZI!
    </app-advert>
</app-segment>


import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hbot-si-grefele-lambourile-compromise',
  templateUrl: './hbot-si-grefele-lambourile-compromise.component.html',
  styleUrls: ['./hbot-si-grefele-lambourile-compromise.component.scss']
})
export class HbotSiGrefeleLambourileCompromiseComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/HBOT_si_grefele_lambourile_compromise.webp',
        jpegUrl: '/assets/images/docs/2022/HBOT_si_grefele_lambourile_compromise.png',
        title: 'HBOT și grefele, lambourile compromise'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Grefele și lambourile cutanate sunt utilizate pentru tratarea rănilor specifice, cum ar fi reconstrucția arsurilor, rănile traumatice, pierderea pielii după o infecție gravă, rănile mari și cancerul de piele.'
            },
            {
                name: 'keywords', content: 'hbot'
            },
        ]);
        this.title.setTitle('HBOT și grefele, lambourile compromise | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hbot-si-tratamentele-pentru-dsr-sau-sdrc',
  templateUrl: './hbot-si-tratamentele-pentru-dsr-sau-sdrc.component.html',
  styleUrls: ['./hbot-si-tratamentele-pentru-dsr-sau-sdrc.component.scss']
})
export class HbotSiTratamentelePentruDsrSauSdrcComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/HBOT_si_tratamentele_pentru_DSR_sau_SDRC.webp',
        jpegUrl: '/assets/images/docs/2022/HBOT_si_tratamentele_pentru_DSR_sau_SDRC.png',
        title: 'HBOT și tratamentele pentru DSR sau SDRC'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Distrofia simpatică reflexă, cunoscută sub denumirea de DSR, este o afecțiune care schimbă viața, caracterizată prin dureri...'
            },
            {
                name: 'keywords', content: 'hbot'
            },
        ]);
        this.title.setTitle('HBOT și tratamentele pentru DSR sau SDRC | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

<app-segment [image]="imageConfig">
    <header>HBOT și boala arterială periferică</header>

    <p>Boala arterială periferică (BAP) reprezintă îngustarea vaselor de sânge la brațe, picioare, stomac sau cap. Ca urmare a acestei
        îngustări, zonele au un flux sanguin scăzut. Ateroscleroza sau acumularea plăcii în pereții arterelor este ceea ce determină
        reducerea fluxului. În cazurile severe, această acumulare poate bloca complet arterele și poate duce la moartea țesuturilor și la
        posibila nevoie de amputare. Tratamentele tradiționale se concentrează pe minimizarea durerii, precum și pe reducerea și oprirea
        progresiei aterosclerozei. Medicina hiperbară, oxigenoterapia hiperbarică sau HBOT, se alătură terapiilor tradiționale pentru a
        îmbunătăți boala arterială periferică și poate reduce ateroscleroza.</p>
    <h2>HBOT și boala arterială periferică (BAP)</h2>
    <p>Dovezile arată că HBOT este eficient în tratarea BAP și, pe lângă faptul că ajută în cazul aterosclerozei, HBOT poate ajuta la
        vindecarea plăgilor pe membrele afectate. HBOT acționează pentru a vindeca țesutul deprivat de oxigen prin inundarea acestuia cu
        niveluri crescute de oxigen, accelerând astfel repararea celulelor. HBOT crește, de asemenea, circulația celulelor stem care vizează
        celulele deteriorate. Cu un tratament cu oxigen hiperbaric, inflamația este redusă, vasele de sânge noi cresc pentru a crește fluxul
        sanguin și, în multe cazuri, ateroscleroza este reversată, permițând revenirea fluxului normal de sânge.</p>
    <h3>Cercetare, HBOT și BAP</h3>
    <p>Multe studii diferite au analizat beneficiile HBOT asupra bolii arteriale periferice și aterosclerozei. Un studiu publicat în 2010 a
        analizat efectele asupra pacienților diabetici cu ateroscleroză și cât de bine a funcționat HBOT pentru reducerea aterosclerozei și
        asigurarea controlului glicemic. Studiul a analizat 28 de pacienți. Toți au primit 100% oxigen la 2,4 atmosfere absolute, de cinci
        ori pe săptămână, timp de un total de 30 de sesiuni de tratament cu oxigen hiperbaric. Ca rezultat, pacienții au prezentat
        inflamație scăzută, niveluri reduse de lipide și niveluri echilibrate ale glicemiei.</p>
    <p>Un studiu realizat în anul 2007 a analizat proprietățile antioxidante și artero-protectoare ale HBOT. Acesta a arătat că tratamentele
        zilnice timp de cinci până la 10 săptămâni au oferit un răspuns antioxidant puternic și tratamentele au dus la o reducere
        semnificativă a conținutului de colesterol aortic. Nivelurile crescute de oxigen acționează pentru a inhiba creșterea plăcii.</p>
    <h3>Ce este boala arterială periferică?</h3>
    <p>Termenul „periferic” se referă la exteriorul inimii, astfel încât boala arterială periferică afectează acele vase de sânge din afara
        inimii, care furnizează sânge brațelor și picioarelor. BAP apare cel mai frecvent în arterele picioarelor și poate duce adesea la
        pierderea simţului și la riscul potențial de nevindecare a leziunii de la labele picioarelor. Simptomele tipice ale BAP includ:</p>
    <ul>
        <li>Durere, amorțeală sau senzație de greutate la nivelul mușchilor picioarelor, mai ales în timpul mersului sau urcatului
            scărilor.
        </li>
        <li>Puls slăbit sau absent la nivelul picioarelor sau labelor picioarelor, deoarece sângele nu circulă.</li>
        <li>Escare sau plăgi deschise la picioare sau labele picioarelor care sunt lente sau nu pot vindeca.</li>
        <li>Culoare albăstruie sau cenușie a pielii.</li>
        <li>Temperatura redusă a membrului afectat, se simte rece la atingere.</li>
        <li>Scăderea ritmului de creștere a unghiilor și a părului pe membrul afectat.</li>
    </ul>
    <p>Factorii de risc crescuți pentru BAP includ vârsta de peste 50 de ani, fumatul, diabetul, un nivel ridicat al colesterolului, bolile
        de inimă, hipertensiunea arterială, antecedentele de accidente vasculare cerebrale și boala renală.</p>
    <h3>Tratamente tradiționale pentru boala arterială periferică</h3>
    <p>Tratamentele tradiționale pentru BAP se concentrează pe reducerea aterosclerozei și încercarea de a preveni blocaje suplimentare.
        Acest lucru se face adesea prin prescripția de medicamente care reduc colesterolul, medicamente pentru hipertensiune arterială,
        controlul și medicația glicemiei și pentru subţierea sângelui. Intervențiile chirurgicale pot include angioplastie pentru
        deschiderea arterei și aplatizarea blocajului. O altă opțiune este operația de bypass pentru a ocoli un vas blocat sau îngustat. În
        plus, un medic poate alege terapia trombolitică, în care injectează un medicament care dizolvă cheagurile în artera blocată.</p>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        Pentru a afla mai multe despre medicina hiperbară, HBOT și boala arterială periferică, fă click aici și completează formularul
        nostru de contact. De asemenea, poți suna la cabinetul nostru chiar astăzi pentru a programa o primă consultație.
    </app-advert>
</app-segment>


import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hbot-si-necroza-radiationala',
  templateUrl: './hbot-si-necroza-radiationala.component.html',
  styleUrls: ['./hbot-si-necroza-radiationala.component.scss']
})
export class HbotSiNecrozaRadiationalaComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/HBOT_si_necroza_radiationala.webp',
        jpegUrl: '/assets/images/docs/2022/HBOT_si_necroza_radiationala.png',
        title: 'HBOT și necroza radiațională'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Necroza radiațională apare ca urmare a radioterapiei pentru cancer. În timp ce radiațiile ţintite se concentrează pe distrugerea celulelor canceroase...'
            },
            {
                name: 'keywords', content: 'hbot'
            },
        ]);
        this.title.setTitle('HBOT și necroza radiațională | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

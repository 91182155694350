<app-segment [image]="imageConfig">
    <header>HBOT și accidente vasculare cerebrale</header>

    <p>Apariția unor accidente vasculare cerebrale are loc atunci când ceva blochează fluxul sanguin către creier, lăsând zona creierului
        fără oxigen. Fără oxigen, celulele creierului mor, provocând o pierdere a funcției în zona corpului controlată de zona deteriorată a
        creierului. HBOT (tratament cu oxigen hiperbaric) oferă oxigen suplimentar creierului și îl ajută să se vindece de accidente
        vasculare cerebrale.</p>
    <p>Potrivit Centrelor pentru Controlul și Prevenirea Bolilor, în SUA, în fiecare an aproape 800.000 de persoane suferă accidente
        vasculare cerebrale. Dintre acestea, peste 600.000 sunt accidente vasculare cerebrale noi, restul persoanelor suferind accidente
        vasculare cerebrale anterioare. Peste 140.000 de decese pe an, în Statele Unite, sunt cauzate de accidente vasculare cerebrale. La
        fiecare 40 de secunde, o persoană din SUA suferă un accident vascular cerebral, iar la fiecare patru minute cineva moare ca urmare a
        acestor accidente vasculare cerebrale.</p>
    <p>Când țesutul cerebral moare, niciun tratament nu poate readuce la viață celulele. Cu toate acestea, medicina hiperbară, în cadrul
        HBOT, creează o îmbunătățire semnificativă a țesutului care înconjoară țesutul cerebral deteriorat, ducând adesea la îmbunătățirea
        funcțiilor creierului și corpului.</p>
    <h2>Cum funcționează HBOT pentru a ajuta pacienții cu accidente vasculare cerebrale?</h2>
    <p>HBOT reușește să îmbunătățească fluxul sanguin către țesuturile cerebrale deteriorate ale pacienților cu accidente vasculare
        cerebrale (AVC). Zona țesutului cerebral mort rezultat din lipsa de aport de sânge este cunoscută sub numele de infarct. Capacitatea
        victimelor unor accidente vasculare cerebrale de a-și reveni în urma unui tratament cu oxigen hiperbaric (sau orice alt tratament
        medical) depinde de mai mulți factori: dimensiunea și localizarea infarctului și starea penumbrei (zona dintre țesuturile moarte și
        creierul neimpactat). Infarctele cerebrale severe provoacă tumefacţii care, de asemenea, intensifică presiunea asupra țesutului
        cerebral viabil rămas.</p>
    <h3>Nivelurile mai ridicate de oxigen fac diferența</h3>
    <p>HBOT inundă plasma sanguină, fluidele limfatice și lichidul cefalorahidian (lichidul limpede, apos care umple creierul și coloana
        vertebrală) cu oxigen pur la presiuni atmosferice mai mari decât cele normale. Corpul primește de 1.500 până la 2.000 de ori
        concentrația de O2 pe care ar primi-o atunci când o persoană respiră aerul normal din încăpere. Acest lucru reduce tumefacţia
        creierului și furnizează substanțe nutritive cruciale materiei cerebrale, mușchilor și oaselor înfometate de oxigen. În multe
        cazuri, cu un plan de tratament cu oxigen hiperbaric și HBOT, funcția persoanelor care au suferit accidente vasculare cerebrale se
        îmbunătățește. Celulele dorminde din penumbră se reactivează, iar în zonă se formează și noi vase capilare. Aceste capilare cresc
        atât fluxul sanguin, cât și nutrienții și transportă deșeurile celulare.</p>
    <h3>Studiile arată beneficii HBOT pentru pacienții cu accidente vasculare cerebrale</h3>
    <p>Numeroase studii au analizat eficacitatea și tipul de beneficii HBOT la pacienții cu accidente vasculare cerebrale și au constatat
        rezultate uimitoare. În 2013, Dr. Shai Efrati de la Facultatea de Medicină Sackler a Universității din Tel Aviv și colegii de
        cercetare au arătat în mod concludent într-un studiu randomizat că nivelurile ridicate de oxigen pot revigora neuronii latenți.
        Participanții la studiu au inclus 74 de pacienți între șase luni și trei ani după accidente vasculare cerebrale, a căror stare nu se
        mai îmbunătățea. Cercetătorii au împărțit participanții în două grupuri - unul care a primit tratament cu oxigen hiperbaric și HBOT
        de la începutul studiului, iar al doilea care nu a primit tratament timp de două luni, a primit apoi o perioadă de două luni
        tratament HBOT. Cercetătorii au folosit o combinație de scanări CT pentru a identifica zonele țesuturilor moarte și scanări SPECT
        pentru a măsura nivelul de activitate metabolică a neuronilor care înconjurau zonele creierului deteriorate.</p>
    <p>Imagistica creierului a arătat o activitate neuronală semnificativ crescută după o perioadă de două luni de tratament cu oxigen
        hiperbaric și HBOT timp de două ore, de cinci ori pe săptămână, comparativ cu perioadele de control de non-tratament. Pacienții au
        simţit îmbunătățiri dramatice care includ reversiunea paraliziei, senzația crescută și capacitatea reînnoită de a utiliza limbajul.
        Și, deși studiul s-a concentrat doar asupra pacienților cu până la trei ani după apariția unor accidente vasculare cerebrale,
        îmbunătățiri similare au fost raportate la pacienții ale căror leziuni cerebrale au avut loc cu până la 20 de ani înainte!</p>
    <h3>Studiile actuale de medicină hiperbară oferă constatări promiţătoare pentru pacienții cu accidente vasculare cerebrale</h3>
    <p>În prezent, există două studii active privind efectele și tipul de beneficii HBOT asupra pacienților cu accidente vasculare cerebrale
        ischemice. Primul studiu este realizat de Universitatea din Columbia Britanică și a început în 2015. Se referă la 140 de pacienți
        care sunt la trei până la 36 de luni post-accident vascular cerebral. Jumătate dintre participanți vor primi tratament cu oxigen
        hiperbaric - HBOT, în timp ce cealaltă primește aer normal. Data de încheiere planificată a acestui studiu a fost decembrie 2019. Al
        doilea studiu este realizat de Spitalul Kasr El Aini, care analizează 60 de pacienți în decurs de 48 de ore de la suferirea unor
        accidente vasculare cerebrale ischemice. Dintre acești 60 de pacienți, 30 vor primi tratament medical convențional, în timp ce
        ceilalți 30 vor primi tratament convențional împreună cu 10 ședințe de HBOT în termen de trei până la cinci zile de la accidentul
        vascular cerebral. Data de încheiere a acestui studiu a fost planificată a fi iunie 2020.</p>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        Dacă eşti interesat să afli cum te poate ajuta medicina hiperbară și HBOT să îţi redobândeşti calitatea vieții în urma unor
        accidente vasculare cerebrale, contactează cabinetul nostru chiar astăzi pentru a stabili o consultație inițială. De asemenea,
        poți
        vizita pagina noastră aici și poţi completa formularul nostru de contact, iar personalul cabinetului te va contacta ulterior.
    </app-advert>


    <h3>Ce este un accident vascular cerebral și care sunt simptomele?</h3>
    <p>Vorbim de accidente vasculare cerebrale sau accidente cerebrovasculare (AVC) atunci când circulația normală a sângelui și oxigenarea
        în creier se opresc din cauza cheagurilor de sânge sau a hemoragiei cerebrale. Această lipsă a fluxului sanguin și a oxigenării a
        determinat moartea rapidă a neuronilor creierului, ducând la pierderea funcției creierului și a corpului. Tratamentul imediat după
        accidente vasculare cerebrale este esențial pentru a minimiza leziunile cerebrale, complicațiile sau decesul. Persoanele care au
        accidente vasculare cerebrale, este posibil să manifeste:</p>
    <ul>
        <li>Dificultate în a vorbi și a înțelege - Persoanele care au sau au avut accidente vasculare cerebrale pot experimenta confuzie.
            Cuvintele pot fi neclare, pot să nu fie în măsură să găsească cuvintele potrivite pentru a exprima ceea ce încearcă să spună sau
            pot avea dificultăți în a înțelege ceea ce li se spune.
        </li>
        <li>Amorțeală sau paralizie la nivelul feței, brațelor sau picioarelor - Acest lucru se întâmplă de obicei pe o parte a corpului.
            Este posibil ca persoanele care au suferit accidente vasculare cerebrale să resimtă amorțeală, slăbiciune sau paralizie
            completă. Dacă bănuieşti un accident vascular cerebral, încearcă să ridici brațele deasupra capului. Dacă un braț începe să
            cadă, este posibil să ai un accident vascular cerebral.
        </li>
        <li>Cefalee - Cefalee bruscă și severă care poate provoca vărsături, amețeli sau pierderea cunoștinței.</li>
        <li>Instabilitate - Persoanele care au accidente vasculare cerebrale pot experimenta amețeli inexplicabile, pierderea echilibrului
            sau pierderea coordonării.
        </li>
        <li>Modificări ale vederii - Un accident vascular cerebral îţi poate afecta vederea, cauzând adesea vedere încețoșată sau dublă.
            Este posibil ca victimele unui AVC să experimenteze senzația de întuneric sau pierderea vederii la unul sau la ambii ochi.
        </li>
        <li>Gură căzută - Persoanele care suferă accidente vasculare cerebrale pot observa că atunci când zâmbesc sau vorbesc, o parte a
            gurii cade.
        </li>
    </ul>
    <p>Acronimul F.A.S.T a fost creat pentru a reaminti oamenilor ce semne trebuie să caute la potențialele victime ale unor accidente
        vasculare cerebrale. F pentru căderea feței, A pentru slăbiciunea brațelor (arms în engleză), S pentru dificultăți de vorbire
        (speaking în engleză) și T pentru timpul de apelare la serviciul de urgenţă.</p>

    <h3>Tipuri de accidente vasculare cerebrale</h3>
    <p>Există trei tipuri principale de accidente vasculare cerebrale, fiecare determinat de modul în care s-a produs blocarea fluxului
        sanguin și lipsa oxigenului. Aceste tipuri sunt:</p>
    <ul>
        <li>Accidente vasculare cerebrale ischemice - Aproximativ 80% din toate accidentele vasculare cerebrale sunt ischemice. Ele apar
            atunci când vasele de sânge care alimentează creierul se îngustează sau se blochează. Acest lucru perturbă fluxul de sânge în
            zonă.
            <p>Există două tipuri principale:</p>
            <ul>
                <li>Embolie cerebrală - Aceasta apare atunci când un cheag se dezvoltă în altă parte a corpului, adesea în inimă. Se
                    eliberează și călătorește prin sânge și în creier. Aici vasele sunt prea mici pentru a putea trece.
                </li>
                <li>Tromboză cerebrală - În acest caz, un tromb sau cheag de sânge se formează într-una din arterele care alimentează
                    creierul. Acest cheag poate fi rezultatul depunerilor de grăsime acumulate în arteră.
                </li>
            </ul>
        </li>
        <li>Accidente vasculare cerebrale hemoragice - Acestea apar atunci când un vas de sânge din creier se rupe sau se scurge și
            reprezintă aproximativ 13% din toate accidentele vasculare cerebrale. Cauzele includ hipertensiunea, o doză prea mare de
            anticoagulante sau anevrisme cerebrale. Tipurile de accidente vasculare cerebrale hemoragice includ:
            <ul>
                <li>Hemoragie intracerebrală - Aceasta apare atunci când un vas de sânge din creier se rupe și umple zona din jurul
                    țesutului cerebral. Acest lucru creează presiune și determină moartea celulelor creierului.
                </li>
                <li> Hemoragie subarahnoidiană - Acestea apar atunci când o arteră de lângă baza creierului izbucnește și umple spațiul
                    dintre creier și craniu. Aceste tipuri de accidente vasculare cerebrale produc adesea o durere de cap severă.
                </li>
            </ul>
        </li>
        <li>Atacuri ischemice tranzitorii (AIT) - AIT sunt considerate accidente vasculare cerebrale minuscule sau de avertizare. La fel ca
            accidentele vasculare cerebrale ischemice, mini cheaguri creează un blocaj temporar, însă fără a rezulta o deteriorare
            permanentă.
        </li>
    </ul>
    <p>Simptomele apar rapid și de obicei se rezolvă în decurs de unul până la cinci minute.</p>

    <h3>Complicații ale accidentelor vasculare cerebrale</h3>
    <p>Cât de multe daune creează accidentul vascular cerebral depinde de timpul cât creierul rămâne fără flux de sânge și oxigen. În plus,
        depinde de partea creierului care este afectată.</p>
    <p>Pacienții pot să nu prezente simptome de lungă durată, în timp ce alții pot prezenta complicații precum:</p>
    <ul>
        <li>Paralizie și pierderea controlului muscular - În urma unor accidente vasculare cerebrale, victimele pot suferi de paralizie, de
            obicei pe o parte a corpului. În plus, imobilitatea poate crește riscul de a dezvolta cheaguri de sânge în venele profunde ale
            picioarelor. A fi imobil poate duce, de asemenea, la slăbiciune musculară și pierderea flexibilității.
        </li>
        <li>Disartrie - Aceasta este incapacitatea de a vorbi sau de a vorbi clar. Acest lucru este frecvent în caz de accidente vasculare
            cerebrale care afectează mușchii din gură și gât.
        </li>
        <li>Disfagie - Disfagia descrie dificultatea înghițirii. Aceasta poate face mâncatul dificil și poate da senzaţia că ai ceva blocat
            în gât.
        </li>
        <li>Afazie - Afazia este pierderea capacității de a înțelege și a exprima vorbirea. Ca urmare, aceasta poate afecta conversația,
            citirea și scrierea.
        </li>
        <li>Pierderea memoriei sau dificultăți de gândire - Pierderea memoriei și dificultăți în gândire, raționament și înțelegerea
            conceptelor sau instrucţiunilor simple.
        </li>
        <li>Schimbări în emoții - Controlul emoțiilor poate deveni dificil. Mulți pacienți suferă de depresie după accidente vasculare
            cerebrale.
        </li>
        <li>Durere - Durerea sau modificările de senzație pot apărea în funcție de locul în care corpul este afectat de accidente vasculare
            cerebrale. Pacienții pot experimenta amorțeală, furnicături, înţepături și sensibilitate extremă la schimbările de temperatură.
        </li>
        <li>Pierderea controlului intestinului și al vezicii urinare - Unele accidente vasculare cerebrale pot afecta mușchii responsabili
            de controlul funcției intestinului și a vezicii urinare. În acest caz, mulți pacienți pot necesita un cateter.
        </li>
        <li>Pierderea capacității de autoîngrijire - Mulți pacienți nu se mai pot îngriji de ei înșiși și, ca urmare, necesită ajutor
            zilnic.
        </li>
    </ul>
    <h3>Tratamente tradiționale pentru accidente vasculare cerebrale</h3>
    <p>Un accident vascular cerebral este considerat o urgență medicală și tratamentul trebuie să înceapă cât mai curând posibil.</p>
    <p>Tratamentele aplicate variază și depind de tipul de accidente vasculare cerebrale. Scopul imediat al tratamentului în cazul unor
        accidente vasculare cerebrale ischemice este de a sparge cheagul ce blochează arterele care furnizează sânge creierului. Dacă
        pacientul ajunge la spital în termen de patru ore de la apariția simptomelor, medicul va administra un activator de plasminogen
        tisular (tPA) într-o venă din braț. Acest medicament este un medicament care dizolvă cheagurile. Dacă pacientul ajunge după patru
        ore, medicamentul antiplachetar sau anticoagulant încearcă să subțieze sângele și să prevină formarea de cheaguri suplimentare.
        Dacă, în schimb, cauza a fost boala arterelor carotide, medicii pot recomanda o endarterectomie carotidă sau angioplastie carotidă
        pentru a deschide arterele blocate.</p>
    <p>Accidentele vasculare cerebrale hemoragice trebuie tratate diferit. Anticoagulantele sau medicamentele antiplachetare ar agrava
        sângerarea. Dacă tensiunea arterială ridicată este cauza sângerării, pot fi prescrise medicamente pentru a preveni sângerarea
        ulterioară. Intervenția chirurgicală este adesea necesară pentru accidente vasculare cerebrale hemoragice. Aceste proceduri sunt
        tăierea anevrismului și embolizarea cu bobină.</p>

    <h3>Clinica noastră oferă tratament post accidente vasculare cerebrale</h3>
    <p>La Clinica Review, oferim tratament pentru completarea HBOT. Acestea includ medicamente eliberate pe bază de rețetă pentru scăderea
        spasmelor membrelor, injecții cu Botox pentru reducerea spasticității și alte medicamente pentru a reduce riscul unor accidente
        vasculare cerebrale suplimentare, cum ar fi Plavix, Aggrenox, Ticlid, Coumadin și aspirină. De asemenea, oferim kinetoterapie pentru
        a completa efectele și seria de beneficii HBOT. Ortopezii prescriu kinetoterapie pentru a restabili forța musculară și mobilitatea
        articulațiilor în urma unei rupturi osoase sau a unei fracturi. În mod similar, pacienții cu accidente vasculare cerebrale necesită
        atât tratament cu oxigen hiperbaric, sau HBOT, cât și kinetoterapie pentru a recâștiga forța și mobilitatea, precum și pentru a
        stabiliza membrele slăbite.</p>
    <p>În timpul HBOT la clinica noastră, vedem frecvent pacienți care progresează de la imobilizarea în scaunul cu rotile până la mersul
        pe jos cu asistență. Unele progrese de la mergătoare la bastoane - sau chiar ajung să meargă complet fără asistență. Alți pacienți
        consideră că vorbirea lor neclară se îmbunătățește atât de mult încât sunt capabili să reia un loc de muncă remunerat. Sărbătorim
        fiecare victorie pe măsură ce monitorizăm cum stima de sine, demnitatea și stabilitatea economică a pacientului se îmbunătățesc!</p>

    <h3>Fă pasul următor chiar astăzi!</h3>
    <p>Ca parte a unei abordări de tratament versatile specifice pentru medicina hiperbară, HBOT poate restabili scopul și funcția vieții
        victimelor. Iar acest aspect este foarte important, atât pentru ei, cât și pentru noi!</p>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        Pentru mai multe informații despre medicina hiperbară și HBOT, precum și despre clinica noastră, contactează-ne chiar astăzi
        pentru a programa o consultație. Dr. Demea și echipa sa îţi vor evalua istoricul medical și vor lucra pentru a crea un plan de
        tratament adaptat nevoilor tale.
    </app-advert>
</app-segment>


<app-segment [image]="imageConfig">
    <header>HBOT și boala Lyme</header>
    <p>Boala Lyme este o infecție răspândită prin mușcăturile de căpușe și este cea mai frecventă boală transmisă prin vectori în Statele
        Unite, responsabilă pentru 95% din toate bolile transmise prin vectori. Potrivit Centrelor pentru Controlul și Prevenirea Bolilor
        din Statelor Unite, aproximativ 30.000 de cazuri de boală Lyme sunt raportat la CDC în fiecare an. Cu toate acestea, modelele lor
        arată că până la 300.000 de persoane contactează boala Lyme în Statele Unite în fiecare an. Vestea bună este medicina hiperbară
        prezintă rezultate promițătoare pentru tratarea bolii Lyme cu ajutorul HBOT – tratament cu oxigen hiperbaric.</p>
    <p>Tratamentul timpuriu cu antibiotice este adesea suficient pentru a vindeca infecția. Cu toate acestea, o întârziere a tratamentului
        sau rezistența la antibiotice duce la boli și complicații pe termen lung. Aici medicina hiperbară și HBOT joacă un rol critic în
        ameliorarea simptomelor și în a ajuta pacienții să își recapete calitatea vieții.</p>
    <h2>Cum poate HBOT să trateze boala Lyme?</h2>
    <p>Terapia cu oxigen hiperbaric sau HBOT oferă un tratament unic pentru boala Lyme pe care terapiile tradiționale nu le oferă. Ca și în
        cazul majorității bacteriilor, cele care cauzează boala Lyme sunt anaerobe. Aceasta înseamnă că sunt incapabile să supraviețuiască
        în medii bogate în oxigen. Deci, în timp ce bacteriile pot dezvolta rezistență la antibiotice tradiționale, ele nu pot prospera în
        prezenţa unui nivel ridicat de oxigen. Prin urmare, are sens faptul că un plan de tratament cu oxigen hiperbaric (HBOT) și oxigenul
        ajută.</p>
    <p>Tratamentele HBOT furnizează concentrații mari de oxigen în medii sub presiune. Oxigenul pătrunde în organism și ucide în mod
        eficient bacteriile cauzatoare de boli. În timp ce terapia tradițională începe cu antibiotice, mulți medici adaugă ședințe de
        tratament cu oxigen hiperbaric (HBOT) în același timp, permițând celor două tratamente să lucreze împreună pentru a vindeca în mod
        eficient pacientul.</p>
    <p>Dar oxigenul hiperbaric nu distruge doar bacteriile asociate bolii Lyme. Concentrațiile ridicate de oxigen ajută la tratarea
        simptomelor și afecțiunilor care, pentru mulți, rămân constante după ce rezultatele testelor nu mai arată o infecție. În cazurile de
        PTLDS (sindromul bolii Lyme post-tratament), HBOT oferă adesea alinare atunci când nimic altceva nu o face. Afecţiuni precum durerea
        articulară și musculară, inflamația cronică, complicațiile cognitive și leziunile nervilor revin adesea la normal în timpul
        tratamentului.</p>
    <h2>Cum funcționează HBOT în corp?</h2>
    <p>Îndepărtarea bacteriilor și a toxinelor - Nivelurile crescute de oxigen din sângele eliberat de un tratament cu oxigen hiperbaric
        (HBOT) distrug bacteriile și virușii, eliminând în același timp metalele grele și toxinele adesea prezente cu boala Lyme.</p>
    <p>Producerea de celule stem - Nivelurile crescute de oxigen din sânge contribuie la stimularea producției de celule stem suplimentare
        în organism. Aceste celule stem caută și repară țesutul deteriorat. Acest lucru ajută la atenuarea durerilor articulare și musculare
        asociate bolii Lyme cronice.</p>
    <p>Vasele de sânge noi și fluxul sanguin mai bun - tratamentele HBOT acționează pentru a reduce semnificativ nivelul inflamației din
        organism, ajutând la creșterea și instalarea de noi vase de sânge pentru a permite sângelui bogat în oxigen să ajungă la țesuturile
        inflamate.</p>
    <p>Stimulează producția de globule albe (leucocite) - Niveluri mai ridicate de oxigen stimulează producția de globule albe și dau un
        impuls sistemului imunitar al organismului. Aceasta, în esență, promovează proprietățile de vindecare ale corpului și corpul începe
        recuperarea.</p>
    <p>Repară funcția creierului - Nivelurile sub presiune ale oxigenului pe care HBOT le furnizează organismului stimulează funcția
        creierului și ajută la restabilirea abilităților cognitive, majoritatea pacienților recuperând cea mai mare parte, dacă nu chiar
        integral, memoria și gândirea funcțională.</p>
    <p>Organele deteriorate se recuperează - În cazurile cronice în care sunt afectate organe precum inima, ficatul și ochii, nivelul
        crescut de oxigen contribuie la vindecare și, în majoritatea cazurilor, organele îşi recapătă la funcția normală.</p>
    <h3>Ce sunt reacțiile Jarisch-Herxheimer?</h3>
    <p>În timp ce tratamentele HBOT specifice din medicina hiperbară tratează în mod eficient boala Lyme, este foarte frecvent ca cei care
        primesc tratament să experimenteze reacții Jarisch-Herxheimer (acestea apar și la pacienții care primesc doze mari de antibiotice).
        Pe măsură ce oxigenul distruge toate bacteriile Borrelia din corp, marea cantitate de bacterii care mor umplu corpul cu toxine.
        Odată ce corpul le elimină, pacienții pot experimenta pierdere a echilibrului, dureri de cap și febră scăzută. Deoarece volumul de
        bacterii moarte crește inflamația în organism, pacienții pot experimenta o agravare a simptomelor bolii înainte de a se însănătoşi.
        Durerile musculare și articulare crescute, afectarea cognitivă și durerile de cap pot crește, dar pe măsură ce corpul elimină
        bacteriile moarte, pacienții raportează ameliorarea simptomelor.</p>
    <h3>Studii privind boala Lyme, oxigenul hiperbaric și tratamentul cu HBOT</h3>
    <p>O lucrare publicată în 1998 de W.P. Fife, Doctor în Medicină, și R.A. Neubauer, Doctor-Medic, a discutat despre utilizarea HBOT și a
        oxigenului hiperbaric în cazul bolii Lyme. Au descoperit că fibroblastele (celulele găsite în țesutul conjunctiv) protejează adesea
        bacteriile Borrelia de antibiotice, făcând ineficiente opțiunile de tratament tradiționale. Deoarece HBOT furnizează niveluri
        ridicate de oxigen sub presiune ridicată, oxigenul pătrunde adânc în țesut și oferă un mediu bogat în oxigen în care bacteriile nu
        pot supraviețui. Studiul a analizat 91 de pacienți care au beneficiat de tratament cu oxigen hiperbaric în timp ce aceștia au
        menținut terapia cu antibiotice. La sfârșitul studiului, 84,8% au arătat o ameliorare semnificativă a simptomelor, precum și
        modificări pozitive de diagnostic în scanările SPECT (un test de imagistică nucleară care produce imagistica 3D a funcției
        creierului).</p>
    <h3>Oxigenul hiperbaric ajută după ani de tratamente eșuate</h3>
    <p>O lucrare din anul 2014 a analizat un raport de caz al unui bărbat în vârstă de 31 de ani care a fost supus ani tratamentelor
        tradiționale cu antibiotice. Aceste serii de tratamente au produs o reducere minoră a simptomelor în timpul tratamentului cu
        antibiotice, dar simptomele au revenit. După ani de tratamente nereușite cu antibiotice, pacientul a primit 30 de ședințe de
        tratament cu oxigen hiperbaric sau HBOT la 2,5 ATA, fiecare sesiune având o durată de 1,5 ore. După primele 10 ședințe HBOT,
        pierderea capacității de gândire și tulburările de somn ale pacientului au dispărut. După 20 de tratamente, amorțeala de la nivelul
        extremităților și zvâcnirile periorbitale au dispărut. Odată finalizate cele 30 de ședințe de tratament cu oxigen hiperbaric,
        durerile articulare și alte simptome musculo-scheletice au dispărut.</p>
    <h3>Te poate ajuta HBOT în legătură cu boala Lyme?</h3>
    <p>După cum arată informațiile și studiile în ceea ce privește medicina hiperbară, oxigenul hiperbaric și HBOT oferă o alternativă
        eficientă sau o terapie adjuvantă pentru boala Lyme.</p>
    <p>Dr. Demea este bucuros să se întâlnească cu tine la o primă consultație, să parcurgă simptomele tale, precum și tratamentele actuale
        și anterioare și modul cum te poate ajuta HBOT și medicina hiperbară. Contactează cabinetul nostru chiar astăzi pentru a stabili o
        consultație inițială. De asemenea, poți face click aici pentru a completa formularul nostru de solicitare.</p>
    <h3>Istoricul bolii Lyme</h3>
    <p>Dr. Allen Steere a identificat prima dată boala Lyme la începutul anilor 1970. El a legat boala de o mușcătură de la căpușa cu
        picioare negre sau căpuşa de căprioară. El a numit-o boala Lyme după Old Lyme, Connecticut, unde medicii au diagnosticat primele
        victime. Cu toate acestea, abia în 1982 cineva a identificat cauza reală. Entomologul născut în Elveția, Willy Burgdorfer, a
        descoperit bacteria (Borrelia burgdorferi) responsabilă de boală și a numit-o după acesta.</p>
    <p>Borrelia burgdorferi este principala cauză a bolii Lyme în Statele Unite. Cu toate acestea, în 2013, oamenii de știință de la clinica
        Mayo au descoperit o a doua specie bacteriană, Borrelia mayonii, responsabilă de boala Lyme în Minnesota, Wisconsin și Dakota de
        Nord. În Europa și Asia, încă două specii de bacterii pot provoca boala Lyme: Borrelia afzelii și Borrelia garinii. Toate cele patru
        specii de bacterii se răspândesc prin căpușa cu picioare negre.</p>
    <h3>Bacteria Borrelia și căpușa cu picioare negre</h3>
    <p>Răspândirea bolii Lyme apare atunci când Borrelia burgdorferi (sau una dintre celelalte trei bacterii) se transferă pintr-o mușcătură
        de căpușă la om. Pe coasta de nord-est şi centrală a Atlanticului și nord-centrul Statelor Unite, aceasta provine de la căpușa cu
        picioare negre sau căpuşa de căprioară (Ixodes scapularis). Pe coasta Pacificului, este răspândită de căpușa cu picioarele negre din
        vest (Ixodes pacificus). Majoritatea cazurilor de infecție sunt transmise prin mușcăturile căpușelor imature sau cele în stadiul de
        nimfă. Aceste căpușe sunt de obicei mici, măsoară mai puțin de 2 mm și sunt foarte greu de văzut.</p>
    <h3>Transmiterea și prevenirea bolii Lyme</h3>
    <p>În Statele Unite, majoritatea cazurilor de boală Lyme apar în statele central-vestice superioare, statele nord-estice superioare și
        statele nord-vestice de pe Coasta de Vest. Căpușele trăiesc în zone umbrite, împădurite sau în zone cu multă iarbă și rămân aproape
        de sol. Se pot agăța de iarbă înaltă, tufişuri și arbuști. Căpușele responsabile de transmiterea bolii Lyme sunt, de obicei, în
        stadiul de nimfă și sunt foarte mici.</p>
    <p>În timp ce pot mușca și se pot atașa oriunde pe om, sunt mai susceptibile să se atașeze în zone ascunse sau păroase, cum ar fi axila,
        zona inghinală și scalpul. Deși este posibil, transmiterea Borrelia burgdorferi nu are loc decât dacă căpușa s-a atașat și s-a
        hrănit mai mult de 36 de ore. Când faci drumeții sau camping, este esențial să te verifici periodic de căpușe. Și, de asemenea, să
        le scoți cât mai curând posibil. Cu cât o căpușă rămâne atașată mai mult timp, cu atât riscul de a dezvolta boala Lyme este mai
        mare.</p>
    <h3>Semne și simptome ale bolii Lyme</h3>
    <p>Boala Lyme este împărțită în trei stadii care sunt evaluate de răspândirea bacteriilor.</p>
    <h3>Stadiile bolii Lyme</h3>
    <ul>
        <li>Stadiul I (boala Lyme localizată) - Acesta este stadiul incipient al bolii, când bacteria este concentrată într-o zonă
            localizată, de obicei locul mușcăturii de căpușă.
        </li>
        <li>Stadiul II (boala Lyme diseminată precoce) - În această etapă bacteriile încep să se răspândească în tot corpul. Pacienții pot
            începe să prezinte simptome suplimentare.
        </li>
        <li>Stadiul III (boala Lyme diseminată târziu) - În acest stadiu, bacteriile sunt răspândite în organism. Dacă tratamentul nu a
            început, pacienții pot prezenta simptome severe și pierderea calității vieții.
        </li>
    </ul>
    <p>Când o căpușă se atașează și se hrănește pe piele, este esențial să îndepărtezi căpușa cât mai curând posibil. Cu cât căpușa stă
        ataşată mai mult timp, cu atât este mai mare riscul de a contracta boala Lyme. După o mușcătură de căpușă, este normal să apară o
        umflătură mică, roșie. Această umflătură roșie se vindecă de obicei singură și nu este un semn al bolii Lyme. Dacă căpușa
        răspândește boala Lyme, simptomele inițiale se vor dezvolta în 30 de zile (media este de o săptămână).</p>
    <h3>Simptome inițiale</h3>
    <ul>
        <li>Eritemul migrator (erupție cutanată) - În termen de trei zile de la mușcătura inițială a căpușei, poți dezvolta o erupție
            cutanată. Se extinde, în cele din urmă se curăță în centru și arată ca un ochi de taur sau o țintă. Această erupție crește încet
            și se poate răspândi la fel de mare cât lăţimea unui picior. Erupția se poate simți caldă la atingere, dar de obicei nu dă
            senzaţie de mâncărime sau dureroasă. Mai presus de toate, această erupție este semnul distinctiv al bolii Lyme și ar trebui să
            fie un indicator pentru a te adresa medicului. Cu toate acestea, deși acesta este principalul semn al bolii Lyme, nu toată lumea
            îl dezvoltă. 20-30 la sută dintre pacienții infectați cu boala Lyme nu dezvoltă niciodată erupții cutanate.
        </li>
        <li>Simptome asemănătoare gripei - se dezvoltă în același timp în care erupția începe să se răspândească. Umflarea ganglionilor
            limfatici este, de asemenea, frecventă.
        </li>
    </ul>
    <p>Dacă știi că ai fost muşcat sau dacă vezi erupția țintă, solicită imediat tratament. Terapia imediată cu antibiotice este adesea
        suficientă pentru a vindeca boala Lyme.</p>
    <h3>Pe măsură ce boala Lyme progresează</h3>
    <p>Dacă nu este tratată, simptomele vor continua să progreseze. Se răspândesc în alte zone ale corpului și adesea devin rezistente la
        tratamentul tradițional cu antibiotice. Simptomele suplimentare care se dezvoltă în lunile de după expunerea inițială includ:</p>
    <ul>
        <li>Răspândirea eritemului migrator (erupție cutanată) - Erupția țintă inițială continuă să se răspândească. În multe cazuri, se pot
            dezvolta erupții țintă suplimentare pe alte zone ale corpului.
        </li>
        <li>Complicații articulare și musculare - Boala Lyme poate ataca articulațiile din corpul dumneavoastră, provocând dureri severe și
            umflături. Deși poate afecta orice articulație din corp, genunchii tind să fie o țintă. Aceasta poate fi denumită artrită Lyme.
            Durerea se poate extinde dincolo de articulații, afectând tendoanele, mușchii și chiar provoacă dureri osoase.
        </li>
        <li>Complicații neurologice - Simptomele neurologice pot apărea la câteva săptămâni de la infecție sau se pot dezvolta luni sau ani
            mai târziu. Boala Lyme poate provoca afectarea mișcării musculare, amorțeală sau slăbiciune în brațe și picioare și dureri ale
            nervilor. Apare și paralizia Bell (paralizie temporară a feței), meningita (inflamația creierului) și inflamația măduvei
            spinării.
        </li>
        <li>Oboseală severă - Aceasta depășește nevoia de a trage un pui de somn sau senzaţia de oboseală excesivă. Acest nivel de oboseală
            poate fi debilitant și afectează foarte mult calitatea vieții.
        </li>
        <li>Implicarea organelor - În cazurile severe, boala Lyme poate afecta diferite organe din corp. Aceasta include inflamația ochilor,
            complicații ale inimii (bătăi neregulate ale inimii) sau inflamația ficatului.
        </li>
        <li>Complicații cognitive - Memorie afectată, confuzie, incapacitate de concentrare și pierderea cuvintelor. Finalizarea sarcinilor
            zilnice poate deveni dificilă.
        </li>
    </ul>
    <h3>Serii de tratamente tradiționale pentru boala Lyme</h3>
    <p>Când vine vorba de boala Lyme, tratamentul precoce este esențial. Tratamentul tradițional este o serie extinsă de antibiotice orale.
        În cazurile mai severe, pot fi administrate antibiotice intravenoase. Când este tratat suficient de devreme, seria de antibiotice
        este adesea suficientă pentru a vindeca boala, iar pacienții se recuperează rapid. În unele cazuri, pacienții vor continua să
        prezinte simptome chiar și după tratament. În aceste cazuri, o serie suplimentară de antibiotice poate ajuta. Cu toate acestea,
        cercetările au arătat că seriile mai lungi de antibiotice rareori oferă beneficii.</p>
    <p>Pacienții care nu răspund la antibiotice sau cei care încep tratamentul târziu în boală sunt adesea incapabili să găsească alinare.
        Dimpotrivă, ei continuă să lupte cu afecțiuni cronice și leziuni permanente ale articulațiilor și ale sistemului nervos.
        Cercetătorii Johns Hopkins au stabilit că mulți pacienți tratați și, conform testelor, vindecați de afecțiune, suferă adesea
        probleme persistente. Cercetările lor au concluzionat că sindromul bolii Lyme post-tratament (PTLDS) este un diagnostic
        credibil.</p>
    <p>Cu toate acestea, pacienții rămân fără tratamente tradiționale eficiente. În astfel de cazuri, medicina hiperbară și HBOT oferă
        adesea alinare acolo unde nimic altceva nu poate.</p>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        SOLICITĂ O CONSULTAŢIE TELEFONICĂ CHIAR ASTĂZI!
    </app-advert>
</app-segment>

import { Subscription } from 'rxjs';

export class SubscriptionsContainer {
    private subscriptions: Subscription[] = [];

    /**
     * Add a new subscription to the array
     */
    set addSubscription(s: Subscription) {
        this.subscriptions.push(s);
    }

    /**
     * Unsubscribe all subscriptions
     */
    dispose() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
}

<app-segment [image]="imageConfig">
    <header>HBOT și tratamentele pentru DSR sau SDRC</header>

    <p>Distrofia simpatică reflexă, cunoscută sub denumirea de DSR, este o afecțiune care schimbă viața, caracterizată prin dureri cronice
        severe, adesea datorate leziunilor nervoase. În 1995, o echipă de cercetători medicali a decis să acorde afecţiunii o nouă denumire
        și să împartă boala în două categorii diferite.</p>
    <p>Noua denumire, Sindromul Dureros Regional Cronic (sau SDRC) împarte boala în SDRC-I și SDRC-II. Mulți pacienți și chiar medici se
        referă în continuare la afecțiune ca DSR. Însă indiferent de cum ai numi-o, aceasta este o afecțiune gravă și debilitantă,
        tratamentele fiind deseori capabile să se concentreze doar pe gestionarea durerii. Din fericire, medicina hiperbară și planurile de
        tratament cu oxigen hiperbaric (HBOT), precum și cercetările actuale în domeniu, sunt promițătoare în tratarea pacienților cu
        DSR/SDRC, ajutându-i să recâștige o oarecare calitate a vieții.</p>

    <h2>Cum poate HBOT să ajute pacienții cu DSR/SDRC</h2>
    <p>Cercetătorii nu înțeleg pe deplin ce cauzează sindromul dureros regional cronic (DSR/SDRC) și durerea asociată cu acesta. Cu toate
        acestea, o trecere în revistă publicată în Asociația Internațională pentru Studiul Durerii, și condusă de Leif Ostergaard a analizat
        posibilele cauze ale durerii asociate cu DSR/SDRC. Cercetătorii au ajuns la concluzia că DSR/SDRC începe atunci când apare
        asemănător sindromului de compartiment după o leziune sau o procedură medicală. Tumefacţia asociată cu o astfel de leziune provoacă
        o perturbare a fluxului sanguin prin capilare.</p>
    <p>Această tulburare duce la reducerea nivelurilor de oxigen în țesuturi, ducând la hipoxie. Lipsa oxigenului descompune țesutul, fie
        acesta din mușchi sau piele. Ca urmare, pacienții suferă de durere, sensibilitate crescută, atrofie musculară și multe alte
        simptome. Din acest motiv, este logic că medicina hiperbară, prin tratamentele cu oxigen hiperbaric și HBOT, poate prezenta succes
        în cazul DSR/SDRC atunci când tratamentele tradiționale eșuează.</p>

    <h2>Ce face HBOT în interiorul corpului</h2>
    <p>Atunci când un pacient se supune unui tratament cu oxigen hiperbaric (HBOT), oxigenul pur sub o presiune mai mare decât cea normală
        inundă corpul. Ca urmare, procedura împinge oxigenul în zone care pot fi lipsite de oxigen. Acest oxigen crescut ajută la reducerea
        edemului, favorizează creșterea capilară și funcționarea corectă. În plus, nivelurile crescute de oxigen ajută la repararea și
        reconstrucția țesuturilor.</p>
    <p>Nivelul crescut de oxigen crescut ajută organismul să se refacă la stadiu sănătos. Cu alte cuvinte, pacienții adesea nu mai prezintă
        tumefacţii, durere și declară o creștere a calității vieții. Mulți pacienți care primesc tratament cu oxigen hiperbaric (HBOT) își
        pot reduce sau elimina nevoia de medicamente analgezice. În plus, aceştia experimentează o gamă ridicată de mobilitate și forță în
        mușchi. În cele din urmă, mulți sunt capabili să se întoarcă la muncă și la rutina normală de zi cu zi.</p>

    <h3>Studiile arată succesul tratamentelor cu oxigen hiperbaric și HBOT și îmbunătățirea stării în DSR/SDRC</h3>
    <p>În timp ce multe studii au analizat pacienții cu sindrom dureros regional cronic (DSR/SDRC) pentru a determina ce poate cauza boala,
        multe altele au analizat diferite tratamente și posibila lor eficacitate în tratarea bolii. Drept urmare, multe dintre aceste studii
        au analizat soluțiile propuse de medicina hiperbară, mai specific oxigenul hiperbaric și tratamentele HBOT, deoarece oxigenul pe
        care îl livrează corpului tratează problemele de fluxul capilar și flux sanguin care cred că se află în spatele DSR/SDRC și a
        durerii pe care o provoacă.</p>

    <h3>O comparație între HBOT și aerul normal</h3>
    <p>O lucrare elaborată în anul 2004, condusă de MZ Kiralp și publicată în Journal of International Medical Research, a realizat un
        studiu dublu-orb, randomizat și controlat cu placebo, despre eficiența HBOT la pacienții cu sindrom dureros regional cronic
        (DSR/SDRC). Studiul a implicat 71 de pacienți, 37 în grupul HBOT și 34 în grupul de control, care au primit doar aer normal.</p>
    <p>Înainte de începerea studiului, pacienții au fost evaluați pentru durere, edem și gama de mobilitate. După 15 ședințe de tratament a
        câte 90 de minute fiecare, acei pacienți care au primit tratamente cu oxigen hiperbaric sau HBOT au prezentat o schimbare
        semnificativă față de cei care nu au primit. O reducere a durerii a fost documentată după prima ședință și toți pacienții au
        prezentat o reducere semnificativă a durerii după toate cele 15 tratamente. A existat, de asemenea, o reducere semnificativă a
        nivelurilor de edem și o creștere a gamei de mobilitate.</p>

    <h3>HBOT reduce riscul de amputare</h3>
    <p>Cu cât este mai lungă perioada în care o persoană are DSR/SDRC, cu atât este mai expusă riscului de modificări ale țesuturilor și ale
        pielii în zona afectată. Aceste modificări pot include ulcerații, răni deschise și celulită. În aceste cazuri, modificările
        țesuturilor pot duce la deteriorare permanentă, necesitând adesea amputarea membrului afectat. În aceste cazuri, medicina hiperbară
        și HBOT au dovedit că reduc nevoia de amputare.</p>
    <p>Un studiu realizat în anul 2016, publicat în Neurology, a analizat utilizarea oxigenului hiperbaric și a HBOT la pacienții cu
        sindromul dureros regional cronic sever (DSR/SDRC) și aceste complicații ale pielii. A arătat că HBOT a redus riscul amputării și a
        tratat în mod eficient celulita, ulcerațiile cutanate și plăgile cu pierderi de substanţă.</p>

    <h3>Un studiu de caz arată o îmbunătățire semnificativă cu HBOT</h3>
    <p>Un studiu de caz din anul 2015 a examinat un bărbat în vârstă de 41 de ani cu SDRC de tip II la piciorul stâng în urma unei fracturi
        a gleznei stângi. Pacientul prezenta dureri severe, tumefacţie, alodinie, o gamă redusă de mobilitate și modificări ale culorii
        pielii. Sarcinile simple, de tipul încălțării șosetelor și pantofilor îi provocau dureri severe. Linia inițială a graficului durerii
        a fost de 7-8/10. Pacientul a fost supus unui număr de 15 sesiuni de tratament HBOT a câte 90 de minute, pe parcursul a trei
        săptămâni.</p>
    <p>După tratamentele HBOT, pacientul a raportat o reducere a durerii cu 30%, îmbunătățirea stării de spirit cu 57%, o creștere cu 44% a
        abilității de mers pe jos și o creștere cu 30% a plăcerii de a trăi. De asemenea, tumefacţia și decolorarea pielii s-au redus. La un
        examen post-tratament după 6 luni, pacientul a raportat dureri intermitente la piciorul stâng, dar numai la nivelul 3 pe o scală de
        10. A reușit să se întoarcă la muncă cu normă întreagă ca pompier și a revenit la jogging de câteva ori pe săptămână.</p>

    <h3>Dr. Demea și succesul său cu HBOT</h3>
    <p>După ce a tratat câțiva pacienți pentru alte afecțiuni, acești pacienți l-au anunțat pe Dr. Demea că, pe lângă faptul că tratamentul
        HBOT a avut succes pentru plăgile lor, au observat, de asemenea, ameliorarea simptomelor DSR/SDRC.</p>
    <p>După ce a descoperit acest fapt, medicul a contactat pacienții anteriori cu DSR, pe care îi tratase ca neurolog, i-a trimis la
        tratamentul tradițional al durerii și le-a cerut să revină pentru tratamentele HBOT. A constatat că după primul sau al doilea
        tratament cu oxigen hiperbaric, 50 până la 75% dintre pacienții cu sindrom dureros regional cronic (DSR/SDRC) își recapătă tiparele
        normale de somn. Până la al patrulea sau al cincilea tratament, culoarea pielii revine la normal și tumefacţia la nivelul membrului
        afectat se reduce. Până la al 10-lea tratament cu oxigen hiperbaric, majoritatea pacienților încep să simtă o reducere a nivelurilor
        de durere.</p>
    <p>Cu o normă de 20 până la 30 de sesiuni de tratament cu oxigen hiperbaric, majoritatea pacienților constată după tratament că durerea
        a dispărut sau a scăzut la 2/10 pe scala standard a durerii. Au fost pacienți imobilizați în scaun cu rotile care au fost capabili
        să se ridice și să plece acasă pe picioare, într-o oarecare măsură.</p>
    <p>Mulți dintre pacienții săi raportează o îmbunătățire semnificativă. Ceața cerebrală se ridică și funcția cognitivă se îmbunătățește,
        la fel și starea lor de spirit. Nivelurile de tumefacţie și edem revin la normal și, după kinetoterapie, majoritatea pacienților
        redobândesc mobilitatea și forța musculară. Mulți se pot întoarce din nou la munca obișnuită. Medicina hiperbară și HBOT ajută la
        îmbunătățirea calității vieții la pacienții cu DSR/SDRC.</p>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        Dacă doreşti să afli cum te pot ajuta tratamentele HBOT și medicina hiperbară în sindromul dureros regional cronic (DSR/SDRC),
        contactează Centrul Medical Review chiar astăzi pentru a stabili o consultație inițială. De asemenea, poți face click aici pentru a
        completa formularul de contact, iar personalul nostru te va contacta ulterior.
    </app-advert>

    <h3>Ce este DSR/SDRC?</h3>
    <p>DSR/SDRC este o afecțiune dureroasă cronică care apare de obicei după o leziune, o intervenție chirurgicală, un accident vascular
        cerebral sau un atac de cord. Se crede că este o funcţionare defectuoasă a sistemului nervos periferic și central. Afecţiunea
        provoacă dureri severe care afectează de obicei un braț sau un picior.</p>
    <p>Durerea asociată cu sindromul dureros regional cronic (DSR/SDRC) depășește cu mult ceea ce ar fi normal pentru orice potențială
        leziune. Este o afecțiune care se află în partea superioară a scalei durerii a Universității McGill, cu un nivel 42 din 50 posibil.
        Nivelul durerii este mai mare decât cel al durerii cauzate de amputare. DSR/SDRC este în vârful scalei durerii, fiind cea mai
        intensă durere experimentată. Din acest motiv, DSR/SDRC este adesea denumită „boala suicidului”, deoarece mulți pacienți se luptă să
        trăiască cu durerea.</p>
    <p>Odată cu schimbarea denumirii din Distrofie Simpatică Regională în Sindromul Dureros Regional Complex, afecțiunea a fost, de
        asemenea, împărțită în două categorii: SDRC-I (categoria considerată anterior DSR) și SDRC-II (cunoscută anterior ca cauzalgie).
        Dacă există o leziune a nervilor confirmată și relaţionată, pacienții sunt clasificați cu SDRC-I. Când simptomele apar fără o
        legătură cu o leziune a nervilor, acestea sunt diagnosticate cu SDRC-II.</p>
    <p>În timp ce principalele denumiri pentru aceste afecţiuni sunt DSR/SDRC, acestea pot fi, de asemenea, denumite algodistrofie,
        algoneurodistrofie, sindromul cauzalgiei, distrofie neurovasculară reflexă și atrofia lui Sudeck.</p>

    <h3>Ce cauzează sindromul dureros regional cronic sau DSR/SDRC?</h3>
    <p>Adevărata cauză a DSR/SDRC nu este clară. În mai mult de 90% din cazuri, aceasta este declanșată de o leziune sau de diverse
        traumatisme. Cu toate acestea, ceea ce face ca o persoană să dezvolte afecțiunea, în timp ce altele nu o fac, este încă o dilemă.
        Cele mai frecvente afecțiuni sau traumatisme care declanșează DSR/SDRC sunt fracturile, entorsele și solicitările musculare,
        leziunile țesuturilor moi (arsuri, tăieturi simple și vânătăi) și intervențiile chirurgicale. Cu toate acestea, chiar și procedurile
        medicale simple, cum ar fi o injecţie sau o sutură, pot declanșa DSR. Atunci când nu pot fi identificate traumatisme, se crede că,
        în general, cauza principală poate fi o infecție, anomalii ale vaselor de sânge sau sindromul canalar (compresia nervilor).</p>

    <h3>Ce cauzează durerea asociată cu DSR?</h3>
    <p>Răspunsul la aceste traumatisme sau afecțiuni este anormal și foarte amplificat. Cauza durerii este afectarea sau funcția anormală a
        fibrelor nervoase senzoriale nemielinizate mici și mielinizate subțiri. Aceste fibre transmit mesaje de durere către vasele de sânge
        din corp. Această comunicare greșită are ca rezultat inflamații și anomalii ale vaselor de sânge. Acest lucru, la rândul său,
        declanșează leziuni ale măduvei spinării și ale creierului.</p>
    <p>Comunicarea necorespunzătoare către vasele de sânge poate duce la dilatarea sau constricția vaselor, care pot înfometa mușchii și
        țesutul de oxigenul și nutrienții necesari. Un studiu sugerează că afecţiunea DSR/SDRC poate fi cauzată de o mică boală a vaselor de
        sânge care afectează alimentarea cu oxigen a celulelor musculare din corp. Tocmai în această posibilă lipsă de oxigen, medicina
        hiperbară și HBOT au demonstrat că joacă un rol important în tratament.</p>

    <h3>Cum se diagnostichează DSR/SDRC?</h3>
    <p>Nu există un singur test pentru diagnosticarea DSR/SDRC, iar diagnosticul se bazează în principal pe un examen fizic și pe istoricul
        medical. Medicii pot comanda o varietate de teste pentru a exclude alte afecţiuni, precum și pentru a căuta indicii care pot indica
        DSR/SDRC. Unele dintre aceste teste includ:</p>
    <ul>
        <li>Excluderea altor afecţiuni - Testarea poate începe prin excluderea altor afecțiuni care pot provoca simptome similare, cum ar fi
            artrita, boala Lyme, diverse boli musculare, neuropatii de fibre mici sau vene coagulate și blocate.
        </li>
        <li>Teste ale sistemului nervos simpatic - Acestea sunt teste care caută modificări ale sistemului nervos simpatic. Testele vor
            măsura modificările temperaturii pielii în zonele afectate. Acestea caută cantități de transpirație în zonele afectate în
            comparație cu zonele normale și monitorizează modificările fluxului sanguin și ale tensiunii arteriale.
        </li>
        <li>Scanări osoase - Acest test caută modificări ale osului unei zone afectate. Există o asociere între DSR/SDRC și excesul de
            resorbție osoasă, în care celulele descompun osul și eliberează calciu în sânge. O scanare osoasă va arăta modificări osoase și
            poate confirma diagnosticul de DSR/SDRC suspectat. În stadiile ulterioare ale bolii, aceste modificări osoase pot apărea și pe
            raze X standard.
        </li>
        <li>Imagistica prin rezonanță magnetică (RMN) - RMN-urile pot prezenta semnale anormale la nivelul pielii, țesuturilor moi,
            articulațiilor, mușchilor, oaselor și măduvei osoase.
        </li>
    </ul>

    <h3>Semne și simptome ale DSR/SDRC</h3>
    <p>Principalul simptom prezent cu DSR/SDRC este durerea severă care poate fi intermitentă sau constantă. Pacienții descriu durerea în
        mai multe moduri, inclusiv arsuri, zvâcnituri, înţepături, compresie intensă sau zdrobire. În multe cazuri, pielea de pe zona
        infectată este dureroasă la atingere. Simptomele pot apărea și dispărea. În plus față de durere, DSR/SDRC poate provoca o varietate
        de simptome diferite, inclusiv:</p>
    <ul>
        <li>Modificări ale temperaturii pielii peste zona afectată, alternând între transpirație și frig.</li>
        <li>Modificări ale aspectului pielii, cum ar fi aspectul strălucitor sau subțire sau modificări de culoare (alb și pătat, roșu sau
            albastru).
        </li>
        <li>Sensibilitate la atingere sau la temperaturi scăzute.</li>
        <li>Tumefierea zonei afectate.</li>
        <li>Modificări ale creșterii părului și a unghiilor.</li>
        <li>Rigiditatea, umflarea și deteriorarea articulațiilor.</li>
        <li>Scăderea mobilității zonei afectate.</li>
        <li>Spasme musculare, tremurături, slăbiciune și atrofie musculară.</li>
    </ul>

    <h3>Opțiuni tradiționale de tratament pentru DSR/SDRC</h3>
    <p>Tratamentul tradițional pentru DSR/SDRC variază de la medicamente la diferite forme de terapie și chiar intervenții chirurgicale.
        Tratamentul timpuriu este adesea mai eficient și poate încetini adesea progresia simptomelor.</p>
    <p>Unele medicamente obișnuite includ:</p>
    <ul>
        <li>Antiinflamatoare nesteroidiene (AINS) - Acestea includ medicamente precum aspirină fără prescripție medicală, ibuprofen și
            naproxen pentru a ajuta la ameliorarea durerii și inflamației.
        </li>
        <li>Corticosteroizi - Medicamentele, cum ar fi prednisolon și metilprednisolon, acționează pentru tratarea inflamației, umflăturilor
            și edemelor în stadiile incipiente ale DSR/SDRC.
        </li>
        <li>Bifosfonați - Aceste medicamente acționează pentru a inhiba activitatea osteoclastelor și s-au dovedit promițătoare în tratarea
            cazurilor de DSR/SDRC.
        </li>
        <li>Antidepresive și anticonvulsivante - Medicamentele, cum ar fi gabapentina, amitriptilina și nortriptilina, sunt eficiente în
            tratarea durerii nervoase.
        </li>
        <li>Opioide - Medicamentele analgezice, cum ar fi oxicodonă, morfină, fentanil și hidrocodonă, ajută la gestionarea durerii.</li>
        <li>Ketamină intravenoasă - Acest anestezic puternic poate oferi o ameliorare substanțială a durerii, dar nu va ajuta la
            îmbunătățirea funcției.
        </li>
    </ul>

    <p>Niciun medicament nu are aprobare specifică din partea FDA pentru tratamentul DSR/SDRC. Nu toate medicamentele disponibile vor
        funcționa în cazul tuturor pacienților. Totul devine o chestiune de încercare și eroare pentru a găsi o combinație care ar putea
        aduce beneficii.</p>

    <h3>Alte opțiuni de tratament pentru DSR/SDRC</h3>
    <ul>
        <li>Kinetoterapie – Se concentrează pe îmbunătățirea mobilităţii și a gamei de mișcări, creșterea forței musculare, reducerea
            durerii și îmbunătățirea fluxului sanguin.
        </li>
        <li>Analgezice locale – Diverse creme și plasturi anestezici locali, cum ar fi lidocaina, pot reduce nivelul durerii și
            hipersensibilitatea.
        </li>
        <li>Stimularea electrică transcutanată a nervului (SETN)– O unitate SETN aplică impulsuri electrice terminațiilor nervoase.</li>
        <li>Terapia în oglindă – Concepută pentru a păcăli creierul. Pacientul se așează în fața unei oglinzi și mișcă membrul sănătos. Dar,
            din cauza oglinzii, creierul îl percepe ca fiind membrul afectat de DSR/SDRC.
        </li>
        <li>Psihoterapia – Durerea și dizabilitatea cauzate de DSR/SDRC duc adesea la depresie, anxietate sau chiar TSPT. Aceste afecţiuni,
            la rândul lor, pot spori percepția durerii. Tratarea oricăror afecțiuni secundare este esențială pentru terapia de ansamblu.
        </li>
        <li>Stimularea măduvei spinării – Stimularea măduvei spinării folosește electrozi de stimulare plasați în coloana vertebrală, lângă
            măduva spinării. Senzația de furnicături pe care o oferă ajută la reducerea nivelului durerii.
        </li>
        <li>Bloc nervos simpatic – Acesta injectează un anestezic lângă coloana vertebrală. Blocul nervos blochează în esență activitatea
            nervilor simpatici și îmbunătățește fluxul sanguin. Totuși, acest tratament nu oferă beneficii pe termen lung.
        </li>
        <li>Simpatectomia chirurgicală – Aceasta este o procedură chirurgicală care distruge nervii din zona afectată. Utilizarea acestei
            proceduri este controversată.
        </li>
        <li>Pompele de medicamente intratecale – Aceasta este o pompă care livrează medicamente direct în fluidul care înconjoară măduva
            spinării. Medicamentele pot include opioide, agenți anestezici și baclofen. Administrarea țintită permite doze mai mici de
            medicamente în timp ce crește eficacitatea și reduce efectele secundare.
        </li>
        <li>Biofeedback – Tehnicile de biofeedback și relaxare permit o mai bună conexiune și conștientizare a propriului corp. Acest lucru
            permite o relație musculară mai bună și reducerea durerii.
        </li>
    </ul>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        Dacă doreşti să afli mai multe despre modul în care medicina hiperbară sau HBOT te poate ajuta să tratezi DSR/SDRC și să recâștigi
        calitatea vieții, sună la cabinetul nostru chiar astăzi și organizează o primă consultație. Dr. Demea se va întâlni cu tine, îţi va
        analiza diagramele medicale și va discuta despre modul în care un tratament cu oxigen hiperbaric (HBOT) te poate ajuta. De asemenea,
        poți completa formularul online aici.
    </app-advert>
</app-segment>


<app-segment [image]="imageConfig">
    <header>HBOT și sindromul post-poliomielitic</header>

    <p>Poliomielita (sau „polio”) este o boală virală care vizează sistemul nervos. Între anii 1940 și 1950, a mutilat nu mai puţin de
        35.000 de persoane pe an în Statele Unite. Vaccinul împotriva poliomielitei, introdus în 1955, a eradicat poliomielita din Statele
        Unite. Cu toate acestea, sindromul post-poliomielitic îi afectează pe cei care au supraviețuit atacului poliomielitei și are impact
        asupra mușchilor care au fost inițial infectați cu poliovirusul. Cauzele sindromului sunt necunoscute, dar mai multe teorii includ
        celulele nervoase suprasolicitate sau posibilele leziuni ale creierului cauzate de infecția inițială. Chiar dacă în prezent nu
        există tratamente farmaceutice care să oprească progresia, unele tratamente, cum ar fi HBOT - tratament cu oxigen hiperbaric, au
        demonstrat că ameliorează simptomele sindromului post-poliomielitic.</p>
    <h2>HBOT și sindromul post-poliomielitic</h2>
    <p>O teorie din spatele sindromului post-poliomielitic se concentrează pe nervi și credința că sindromul post-poliomielitic este
        rezultatul degenerării ganglionilor nervoși. HBOT îmbunătățește proliferarea factorului de creștere a nervilor neurotrofici derivaţi
        pe linie de celule gliale și intensifică informațiile de la noile căi neuronale. Tratamentele HBOT stimulează globulele albe și
        îmbunătățesc sistemul imunitar și încurajează producerea de superoxid dismutază, un antioxidant care ajută la reducerea inflamației.
        Ca urmare, după 20-40 de sesiuni de tratament cu oxigen hiperbaric, mulți pacienți constată o îmbunătățire semnificativă a
        simptomelor post-poliomielită.</p>
    <h3>Ce este sindromul post-poliomielitic?</h3>
    <p>Sindromul post-poliomielitic se referă la o colecție de simptome care se dezvoltă după infecția inițială cu poliovirus. Aceste
        simptome pot dura până la 30 sau 40 de ani pentru a se dezvolta. Simptomele sindromului post-poliomielitic progresează de obicei
        lent și pot include:</p>
    <ul>
        <li>Slăbiciune și durere progresivă a mușchilor și articulațiilor</li>
        <li>Atrofie musculară</li>
        <li>Oboseală și epuizare cu activitate redusă sau inexistentă</li>
        <li>Probleme de respirație sau de înghițire</li>
        <li>Intoleranță la temperaturi scăzute</li>
        <li>Tulburări de somn, cum ar fi apneea în somn</li>
    </ul>
    <p>În cazurile în care slăbiciunea musculară devine severă, complicații precum căderile și fracturile oaselor, malnutriția,
        deshidratarea, pneumonia, insuficiența respiratorie cronică și osteoporoza sunt complicații frecvente.</p>
    <h3>Tratamente tradiționale</h3>
    <p>Nu există nici un remediu pentru sindromul post-poliomielitic, iar tratamentele se concentrează pe minimizarea simptomelor și
        creșterea calității vieții. Tratamentele se concentrează pe minimizarea energiei necesare, cum ar fi utilizarea dispozitivelor de
        asistență, ca bastoanele, mergătoarele și scaunele cu rotile. În plus, kinetoterapia și logopedia pot ajuta la întărirea mușchilor
        și la rezolvarea problemelor de înghițire și de voce. Tratamentele pentru apneea în somn vor ajuta la somn, iar medicamentele, cum
        ar fi analgezicele fără prescripție medicală, pot ajuta la ameliorarea durerilor musculare și articulare.</p>
    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        Pentru mai multe informații despre medicina hiperbară, HBOT și sindromul post-poliomielitic, vizitați pagina noastră de contact
        pentru a completa formularul online. De asemenea, puteți suna la cabinet și pentru a stabili o primă consultaţie la Dr. Demea.
    </app-advert>
</app-segment>


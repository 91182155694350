import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
    selector: 'app-hbot-si-leziunile-cerebrale-traumatice',
    templateUrl: './hbot-si-leziunile-cerebrale-traumatice.component.html',
    styleUrls: ['./hbot-si-leziunile-cerebrale-traumatice.component.scss']
})
export class HbotSiLeziunileCerebraleTraumaticeComponent implements OnInit {
    imageConfig = {
        width: '100%',
        webpUrl: '/assets/images/docs/2022/HBOT_si_leziunile_cerebrale_traumatice.webp',
        jpegUrl: '/assets/images/docs/2022/HBOT_si_leziunile_cerebrale_traumatice.png',
        title: 'HBOT și leziunile cerebrale traumatice'
    };


    constructor(private meta: Meta,
                private router: Router,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Leziunile cerebrale traumatice sau LCT sunt leziuni sau traumatisme dobândite care afectează creierul. În Statele Unite, aproximativ 1,7...'
            },
            {
                name: 'keywords', content: 'hbot'
            },
        ]);
        this.title.setTitle('HBOT și leziunile cerebrale traumatice | Oxygen Review Cluj');
    }

    ngOnInit(): void {
    }
}

<app-segment [image]="imageConfig">
    <header>HBOT și tratamentul fibromialgiei</header>

    <p>Fibromialgia este o tulburare dureroasă cronică care este încă un mister pentru medici. Cercetătorii consideră că este vorba de o
        modificare a neurotransmițătorilor din creier care sunt responsabili de durere. Centrele pentru Controlul și Prevenirea Bolilor
        estimează că până la patru milioane de adulți, sau aproximativ 2% din populația din America, trăiesc cu fibromialgie. În timp ce
        tratamentul se concentrează pe ameliorarea durerii și minimizarea simptomelor, nu există niciun remediu. Cercetările arată că HBOT,
        un tratament datorat eforturilor din medicina hiperbară, reprezintă o nouă opțiune promițătoare pentru cei care suferă de
        fibromialgie.</p>
    <h2>HBOT și fibromialgia</h2>
    <p>Medicina hiperbară prezintă rezultate promițătoare în tratamentul fibromialgiei datorită HBOT. O teorie este că hiperactivitatea din
        căile de procesare a durerii din creier contribuie la fibromialgie. În plus, există, de asemenea, o activitate scăzută în căile de
        inhibare a durerii. Un tratament cu oxigen hiperbaric (HBOT) livrează 100% oxigen sub presiune care ajută la creșterea
        metabolismului celular, reduce moartea celulelor, reduce stresul oxidativ, crește nivelul de neurotrofină și oxid nitric și
        stimulează creșterea celulelor stem neuronale. HBOT induce neuroplasticitatea și corectează activitatea anormală a creierului la
        pacienții cu fibromialgie.</p>
    <h3>Cercetare, HBOT și fibromialgie</h3>
    <p>Un studiu realizat în anul 2015 analizează efectele HBOT asupra pacienților cu fibromialgie. Studiul se referă la 48 de femei cu un
        diagnostic fibromialgic de peste 2 ani. Cercetătorii au împărțit participantele în două grupuri. Unul a fost grupul de tratament
        principal, iar celălalt grupul de tratament încrucișat. Grupul de tratament a primit două luni de ședințe de tratament cu oxigen
        hiperbaric, în total 40 de ședințe. Pacientele nu au primit nicio altă formă de tratament. Grupul încrucișat nu a primit tratament
        cu oxigen hiperbaric în primele două luni și apoi a primit 40 de tratamente HBOT pe parcursul a două luni. Toate pacientele au
        raportat o ameliorare semnificativă a durerii și o reducere a punctelor de presiune. Toate pacientele au fost de acord că au
        experimentat o îmbunătățire semnificativă a calității vieții.</p>
    <p>Cu toate acestea, cele mai semnificative descoperiri ale acestui studiu au provenit din imaginile SPECT. Cercetătorii au descoperit
        că pacienții cu fibromialgie au o activitate crescută în cortexul somatosenzorial și o activitate redusă în cortexurile frontale,
        cingulate, mediale temporale și cerebeloase. După HBOT, scanările SPECT au arătat o activitate redusă în creierul posterior și o
        activitate crescută în lobii frontali, readucând activitatea creierului la normal relativ. Pe scurt, planul de tratament cu oxigen
        hiperbaric (HBOT) a reușit să ajute la restabilirea funcției normale a creierului la acești pacienți fibromialgici.</p>
    <p>Un studiu din 2018 publicat în Annals of the Reumatic Diseases (Analele Bolilor Reumatice) analizează efectele HBOT asupra
        pacienților cu fibromialgie. Studiul a înscris 33 de paciente de sex feminin cu fibromialgie și fiecare a primit 20 de ședințe de
        tratament cu oxigen hiperbaric (HBOT). Studiul a constatat că toate pacientele au prezentat ameliorări semnificative ale tuturor
        simptomelor lor, inclusiv ale durerii.</p>
    <p>Dacă suferi de fibromialgie și doreşti să afli mai multe despre modul în care medicina hiperbară și HBOT îţi pot îmbunătăți calitatea
        vieții, sună chiar astăzi la cabinetul nostru sau fă click aici pentru a completa formularul nostru de contact, iar unul dintre
        angajații cabinetului nostru te va contacta.</p>
    <h2>Ce este fibromialgia?</h2>
    <p>Fibromialgia este o tulburare caracterizată prin dureri musculo-scheletice răspândite. Pentru o lungă perioadă de timp, punctele de
        declanșare au fost principala cerință de diagnostic. Acestea sunt nouă perechi de locații din corp care, atunci când sunt expuse la
        presiune, resimt dureri extreme. Pentru a primi un diagnostic, trebuia să ai 11 dintre aceste puncte, totuși, în prezent există o
        nouă înțelegere a afecţiunii și a faptului că durerea nu este întotdeauna constantă. Din acest motiv, un diagnostic nu mai necesită
        puncte declanșatoare pozitive. Medicii se concentrează acum mai mult pe simptome pentru a confirma fibromialgia. Aceștia caută
        dureri răspândite care durează mai mult de trei luni, prezența simptomelor suplimentare și exclud orice alte posibile afecțiuni
        medicale care stau la baza acestora. În timp ce fibromialgia poate afecta bărbații, femeile și copiii, femeile reprezintă 75-90% din
        toate cazurile.</p>
    <p>Cercetările arată că fibromialgia poate modifica anumite substanțe chimice din creier (neurotransmițători), făcându-le responsabile
        pentru creșterea semnalelor de durere. Fibromialgia pare să apară pe linie de familie, deci există posibilitatea unei mutații
        genetice. Simptomele fibromialgiei încep adesea după o boală gravă, infecție sau după traumatisme, indicând un posibil declanșator
        pentru activarea afecțiunii.</p>
    <h3>Simptomele fibromialgiei</h3>
    <p>În timp ce principalul simptom al fibromialgiei este durerea cronică severă, răspândită, apar simptome suplimentare. Aceste simptome
        suplimentare includ:</p>
    <ul>
        <li>Oboseală extremă</li>
        <li>Depresie și anxietate</li>
        <li>Tulburări de somn</li>
        <li>Ceață fibroasă - probleme cu gândirea, memoria și concentrarea</li>
        <li>Dureri de cap severe</li>
        <li>Furnicături în mâini și picioare</li>
        <li>Durere la nivelul maxilarului, adesea asociată cu sindromul articulației temporomandibulare sau ATM</li>
        <li>Probleme digestive, cum ar fi sindromul intestinului iritabil sau SII</li>
        <li>Rigiditate matinală</li>
        <li>Cicluri menstruale dureroase</li>
    </ul>
    <h3>Tratamente tradiționale ale fibromialgiei</h3>
    <p>Nu există un remediu pentru fibromialgie, prin urmare tratamentul se concentrează pe gestionarea simptomelor și îmbunătățirea
        calității vieții. Medicamentele, cum ar fi analgezicele fără prescripție medicală, antidepresivele și medicamentele anticonvulsive
        ajută la reducerea durerii, tratează problemelor nervilor și ajută la somn. În plus, opțiunile de tratament precum terapia fizică,
        terapia ocupațională și consilierea oferă beneficii. În timp ce aceste tratamente pot oferi calmare, mulți pacienți continuă să
        sufere și caută mereu noi opțiuni.</p>
    <p>Centrul Medical Review din Cluj-Napoca vine în întâmpinarea pacienților care suferă de fibromialgie cu un departament specializat în
        medicina hiperbară și HBOT.</p>
    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        SOLICITĂ O CONSULTAŢIE TELEFONICĂ CHIAR ASTĂZI!
    </app-advert>
</app-segment>


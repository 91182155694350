import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './_common/not-found/not-found.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GdprComponent } from './_common/gdpr/gdpr.component';
import { HbotSiEmboliileAerieneSauGazoaseComponent } from './_pages/hbot-si-emboliile-aeriene-sau-gazoase/hbot-si-emboliile-aeriene-sau-gazoase.component';
import { HbotSiBoalaAlzheimerComponent } from './_pages/hbot-si-boala-alzheimer/hbot-si-boala-alzheimer.component';
import { HbotSiFracturileOsoaseComponent } from './_pages/hbot-si-fracturile-osoase/hbot-si-fracturile-osoase.component';
import { HbotSiTratamentulArsurilorComponent } from './_pages/hbot-si-tratamentul-arsurilor/hbot-si-tratamentul-arsurilor.component';
import { HbotSiPacientiiCuCancerComponent } from './_pages/hbot-si-pacientii-cu-cancer/hbot-si-pacientii-cu-cancer.component';
import { HbotSiIntocticaitaCuMonoxidDeCarbonComponent } from './_pages/hbot-si-intocticaita-cu-monoxid-de-carbon/hbot-si-intocticaita-cu-monoxid-de-carbon.component';
import { HbotSiParaliziaCerebralaComponent } from './_pages/hbot-si-paralizia-cerebrala/hbot-si-paralizia-cerebrala.component';
import { HbotSiMionecrozaClostridialaComponent } from './_pages/hbot-si-mionecroza-clostridiala/hbot-si-mionecroza-clostridiala.component';
import { HbotSiGrefeleLambourileCompromiseComponent } from './_pages/hbot-si-grefele-lambourile-compromise/hbot-si-grefele-lambourile-compromise.component';
import { HbotSiLeziunilePrinZdrobireSindromulDeCompartimentComponent } from './_pages/hbot-si-leziunile-prin-zdrobire-sindromul-de-compartiment/hbot-si-leziunile-prin-zdrobire-sindromul-de-compartiment.component';
import { HbotSiTratamentulFibromialgieiComponent } from './_pages/hbot-si-tratamentul-fibromialgiei/hbot-si-tratamentul-fibromialgiei.component';
import { HbotSiPierdereaSubitaAAuzuluiComponent } from './_pages/hbot-si-pierderea-subita-aauzului/hbot-si-pierderea-subita-aauzului.component';
import { HbotSiAbcesulIntracranianComponent } from './_pages/hbot-si-abcesul-intracranian/hbot-si-abcesul-intracranian.component';
import { HbotSiBoalaLymeComponent } from './_pages/hbot-si-boala-lyme/hbot-si-boala-lyme.component';
import { HbotSiDurerileDeCapDeTipMigrenaComponent } from './_pages/hbot-si-durerile-de-cap-de-tip-migrena/hbot-si-durerile-de-cap-de-tip-migrena.component';
import { HbotSiExpunereaLaMucegaiSiToxicitateDeMucegaiComponent } from './_pages/hbot-si-expunerea-la-mucegai-si-toxicitate-de-mucegai/hbot-si-expunerea-la-mucegai-si-toxicitate-de-mucegai.component';
import { HbotSiSclerozaMultiplaComponent } from './_pages/hbot-si-scleroza-multipla/hbot-si-scleroza-multipla.component';
import { HbotSiOsteomielitaRefractaraComponent } from './_pages/hbot-si-osteomielita-refractara/hbot-si-osteomielita-refractara.component';
import { HbotSiBoalaArterialaPerifericaComponent } from './_pages/hbot-si-boala-arteriala-periferica/hbot-si-boala-arteriala-periferica.component';
import { HbotSiSindromulPostPoliomeliticComponent } from './_pages/hbot-si-sindromul-post-poliomelitic/hbot-si-sindromul-post-poliomelitic.component';
import { HbotSiTulburareaDeStresPostTraumaticTsptComponent } from './_pages/hbot-si-tulburarea-de-stres-post-traumatic-tspt/hbot-si-tulburarea-de-stres-post-traumatic-tspt.component';
import { HbotSiNecrozaRadiationalaComponent } from './_pages/hbot-si-necroza-radiationala/hbot-si-necroza-radiationala.component';
import { HbotSiTratamentelePentruDsrSauSdrcComponent } from './_pages/hbot-si-tratamentele-pentru-dsr-sau-sdrc/hbot-si-tratamentele-pentru-dsr-sau-sdrc.component';
import { HbotSiTratamentulAnemieiComponent } from './_pages/hbot-si-tratamentul-anemiei/hbot-si-tratamentul-anemiei.component';
import { HbotSiAccidenteVasculareCerebraleComponent } from './_pages/hbot-si-accidente-vasculare-cerebrale/hbot-si-accidente-vasculare-cerebrale.component';
import { HbotSiLeziunileCerebraleTraumaticeComponent } from './_pages/hbot-si-leziunile-cerebrale-traumatice/hbot-si-leziunile-cerebrale-traumatice.component';
import { HbotSiTratamentulPentruIngrijireaPlagilorComponent } from './_pages/hbot-si-tratamentul-pentru-ingrijirea-plagilor/hbot-si-tratamentul-pentru-ingrijirea-plagilor.component';
import { HirudoterapieComponent } from './_pages/hirudoterapie/hirudoterapie.component';

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent
    },
    {
        path: 'terapie-cu-oxigen-hiperbaric',
        loadChildren: () =>
            import('./despre-hbot/despre-hbot.module').then(m => m.DespreHbotModule)
    },
    {
        path: 'tratamente-si-beneficii-hbot',
        loadChildren: () =>
            import('./ce-tratam/ce-tratam.module').then(m => m.CeTratamModule)
    },
    {
        path: 'contact',
        loadChildren: () =>
            import('./contact/contact.module').then(m => m.ContactModule)
    },
    {
        path: 'gdpr',
        component: GdprComponent
    },
    {
        path: 'HBOT-si-emboliile-aeriene-sau-gazoase',
        component: HbotSiEmboliileAerieneSauGazoaseComponent
    },
    {
        path: 'HBOT-si-boala-Alzheimer',
        component: HbotSiBoalaAlzheimerComponent
    },
    {
        path: 'HBOT-si-fracturile-osoase',
        component: HbotSiFracturileOsoaseComponent
    },
    {
        path: 'HBOT-si-tratamentul-arsurilor',
        component: HbotSiTratamentulArsurilorComponent
    },
    {
        path: 'HBOT-si-pacientii-cu-cancer',
        component: HbotSiPacientiiCuCancerComponent
    },
    {
        path: 'HBOT-si-intoxicatia-cu-monoxid-de-carbon',
        component: HbotSiIntocticaitaCuMonoxidDeCarbonComponent
    },
    {
        path: 'HBOT-si-tratamentele-pentru-paralizie-cerebrala-la-copii',
        component: HbotSiParaliziaCerebralaComponent
    },
    {
        path: 'HBOT-si-mionecroza-clostridiala',
        component: HbotSiMionecrozaClostridialaComponent
    },
    {
        path: 'HBOT-si-grefele-lambourile-compromise',
        component: HbotSiGrefeleLambourileCompromiseComponent
    },
    {
        path: 'HBOT-si-leziunile-prin-zdrobire-sindromul-de-compartiment',
        component: HbotSiLeziunilePrinZdrobireSindromulDeCompartimentComponent
    },
    {
        path: 'HBOT-si-tratamentul-fibromialgiei',
        component: HbotSiTratamentulFibromialgieiComponent
    },
    {
        path: 'HBOT-si-pierderea-subita-a-auzului',
        component: HbotSiPierdereaSubitaAAuzuluiComponent
    },
    {
        path: 'HBOT-si-abcesul-intracranian',
        component: HbotSiAbcesulIntracranianComponent
    },
    {
        path: 'HBOT-si-boala-lyme',
        component: HbotSiBoalaLymeComponent
    },
    {
        path: 'HBOT-si-durerile-de-cap-de-tip-migrena',
        component: HbotSiDurerileDeCapDeTipMigrenaComponent
    },
    {
        path: 'HBOT-si-expunerea-la-mucegai-si-toxicitate-de-mucegai',
        component: HbotSiExpunereaLaMucegaiSiToxicitateDeMucegaiComponent
    },
    {
        path: 'HBOT-si-scleroza-multipla',
        component: HbotSiSclerozaMultiplaComponent
    },
    {
        path: 'HBOT-si-osteomielita-refractara',
        component: HbotSiOsteomielitaRefractaraComponent
    },
    {
        path: 'HBOT-si-boala-arteriala-periferica',
        component: HbotSiBoalaArterialaPerifericaComponent
    },
    {
        path: 'HBOT-si-sindromul-post-poliomielitic',
        component: HbotSiSindromulPostPoliomeliticComponent
    },
    {
        path: 'HBOT-si-tulburarea-de-stres-post-traumatic-tspt',
        component: HbotSiTulburareaDeStresPostTraumaticTsptComponent
    },
    {
        path: 'HBOT-si-necroza-radiationala',
        component: HbotSiNecrozaRadiationalaComponent
    },
    {
        path: 'HBOT-si-tratamentele-pentru-dsr-sau-sdrc',
        component: HbotSiTratamentelePentruDsrSauSdrcComponent
    },
    {
        path: 'HBOT-si-tratamentul-anemiei',
        component: HbotSiTratamentulAnemieiComponent
    },
    {
        path: 'HBOT-si-accidente-vasculare-cerebrale',
        component: HbotSiAccidenteVasculareCerebraleComponent
    },
    {
        path: 'HBOT-si-leziunile-cerebrale-traumatice',
        component: HbotSiLeziunileCerebraleTraumaticeComponent
    },
    {
        path: 'HBOT-si-tratamentul-pentru-ingrijirea-plagilor',
        component: HbotSiTratamentulPentruIngrijireaPlagilorComponent
    },
    {
        path: 'hirudoterapie',
        component: HirudoterapieComponent
    },
    {path: '**', component: NotFoundComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        enableTracing: false,
        onSameUrlNavigation: 'reload',
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
        scrollOffset: [0, 0],
        useHash: false
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

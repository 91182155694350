import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DespreHbotRoutingModule } from './despre-hbot-routing.module';
import { DespreHbotComponent } from './despre-hbot.component';


@NgModule({
    declarations: [
        DespreHbotComponent
    ],
    imports: [
        CommonModule,
        DespreHbotRoutingModule
    ]
})
export class DespreHbotModule {
}

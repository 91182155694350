<app-segment [image]="imageConfig">
    <header>HBOT şi scleroza multiplă</header>
    <p>Scleroza multiplă sau SM este o boală a sistemului nervos central. SM este o boală imprevizibilă și invalidantă care, potrivit unui
        studiu realizat de Societatea Națională de Scleroză Multiplă, afectează aproape un milion de americani. Nu există nici un remediu
        pentru scleroza multiplă, iar tratamentele tradiționale vizează controlul simptomelor și încetinirea progresiei.</p>
    <p>Deși încă nu au fost aprobate de FDA în Statele Unite, tratamentele HBOT propuse de medicina hiperbară au un istoric de peste 30 de
        ani în tratarea cu succes a pacienților cu scleroză multiplă.</p>
    <h2>Studii timpurii despre HBOT și scleroza multiplă</h2>
    <p>În anii 1970, cercetătorii din patru țări europene au descris câteva beneficii pe care le observaseră în timp ce tratau scleroza
        multiplă cu oxigen hiperbaric. În 1975, Dr. Richard Neubauer, o autoritate de nivel mondial în domeniul HBOT & medicină hiperbară, a
        folosit terapia pentru a trata un pacient cu scleroză multiplă care suferea de inflamație osoasă (osteomielită) la Centrul Medical
        Ocean din Fort Lauderdale. În plus față de reducerea inflamației, HBOT și oxigenul hiperbaric au avut un beneficiu neprevăzut:
        celelalte simptome ale SM ale pacienților s-au ameliorat. Acest lucru a condus la mai multe studii științifice dublu-oarbe.</p>
    <p>Fără un tratament de succes, 80% dintre cei care suferă de scleroză multiplă prezintă simptome progresive și deteriorante. Utilizând
        atât studii clinice dublu-oarbe, cât și longitudinale, Dr. Neubauer a administrat un tratament cu oxigen hiperbaric ajustat
        individual, la presiune scăzută (1,3 până la 1,5 atmosfere), pacienților săi cu scleroză multiplă. Deși starea a 20 până la 30%
        dintre acești pacienți a continuat să se deterioreze, starea a 70 până la 80% nu doar că s-a stabilizat, dar atât leziunile
        țesuturilor, cât și simptomele s-au ameliorat! Ca urmare, a crescut interesul pentru medicina hiperbară și HBOT pentru scleroza
        multiplă.</p>
    <h2>Anii 1980 arată o conexiune pozitivă între oxigenul hiperbaric, HBOT și scleroza multiplă</h2>
    <p>Între 1980 și 1982, doctorii B. H. Fischer, M. Marks și T. Reich de la New York University Medical Center au finalizat primul studiu
        randomizat, dublu-orb, controlat placebo al HBOT pentru 40 de pacienți care suferiseră de scleroză multiplă timp de 11 ani sau mai
        mult. Cercetătorii au împărțit aleatoriu pacienții în două grupuri de câte 20 de persoane. Grupul experimental a respirat oxigen pur
        în timpul ședințelor de tratament cu oxigen hiperbaric (HBOT) timp de 90 de minute, o dată pe zi, pe o perioadă de 20 de zile, iar
        grupului de control i s-a administrat un amestec de 10% oxigen și 90% azot în aceeași perioadă. Trei pacienți din grupul
        experimental au abandonat studiul înainte de finalizare.</p>
    <p>Cercetătorii și-au publicat concluziile în New England Journal of Medicine în 1983. Doisprezece din 17 (70%) dintre participanții la
        studiu care au avut tratament cu oxigen hiperbaric (HBOT) şi-au îmbunătățit starea, doar doi au arătat deteriorare la urmărirea lor
        după un an. Pacienții care au fost cel mai puțin afectați de scleroză multiplă au înregistrat cele mai bune rezultate. Prin
        comparație, dintre pacienții care au primit amestecul de oxigen-azot, un singur pacient din 20 (5%) a resimţit o îmbunătățire a
        stării.</p>
    <h3>Studiile au continuat să arate rezultate pozitive</h3>
    <p>Până în 1984, Dr. Neubauer și alții au studiat impactul HBOT asupra a peste 10.000 de pacienți cu scleroză multiplă din paisprezece
        țări. Dintre acești pacienți, 70% şi-au îmbunătățit funcția creierului, a vezicii urinare și a intestinului, iar spasticitatea
        musculară a scăzut. Douăzeci și cinci la sută (25%) dintre pacienții cu scleroză multiplă au înregistrat îmbunătățiri măsurabile.
        Patruzeci și cinci la sută (45%) dintre pacienți au raportat, de asemenea, că se simt mai bine. La o conferință despre medicina
        hiperbară, Dr. Neubauer a declarat că 600 de pacienți cu scleroză multiplă pe care i-a tratat cu HBOT au cunoscut o îmbunătățire
        obiectivă substanțială. Acesta a menționat, de asemenea, că ședințele de tratament cu oxigen hiperbaric (HBOT) periodice au redus
        atât deteriorarea, cât și frecvența unei recidive.</p>
    <p>În 1986, Neubauer și Kagan au demonstrat că leziunile a 11 din 35 de pacienți cu scleroză multiplă au dispărut după doar o oră de
        tratament cu oxigen hiperbaric. La trei conferințe internaționale separate, Neubauer, Kagan și Gottlieb au prezentat cercetări care
        arată că testele RMN au confirmat o reducere statistic semnificativă atât a numărului, cât și a dimensiunii leziunilor la pacienții
        cu scleroză multiplă care au beneficiat de HBOT, comparativ cu cei care nu au beneficiat.</p>
    <h3>Începutul Federației Centrelor de Tratament a Sclerozei Multiple</h3>
    <p>La Paris, în Franța, Pallotta și alți cercetători au urmărit 22 de pacienți cu scleroză multiplă timp de 8 ani. Fiecare participant a
        primit o serie inițială de 20 de ședințe de tratament cu oxigen hiperbaric HBOT. Doar 11 pacienți cu scleroză multiplă au continuat
        tratamentele, primind periodic câte 2 ședințe HBOT la fiecare 20 de zile. Cei care au avut un tratament cu oxigen hiperbaric
        prelungit au cunoscut o scădere dramatică a recidivelor, în timp ce cei care au beneficiat doar de seria inițială de tratament HBOT
        au constatat că recidivele lor au crescut treptat din nou. Acest lucru a stabilit în mod concludent importanța continuării
        tratamentelor HBOT.</p>
    <p>Pacienții care au resimţit astfel de îmbunătățiri dramatice au înființat o organizație non-profit numită Federația Centrelor de
        Tratament a Sclerozei Multiple, care are acum mai multe unităţi HBOT pe întreg teritoriul Regatului Unit</p>
    <h3>Studii recente și beneficiile continue ale HBOT pentru scleroza multiplă</h3>
    <p>Cel mai recent studiu privind medicina hiperbară, HBOT și scleroza multiplă a venit de la Centrul Național de Terapie a SM din
        Regatul Unit, unde HBOT este utilizat în mod regulat pentru a trata pacienții cu scleroză multiplă.</p>
    <p>Au analizat 117 pacienți care au primit tratament cu oxigen hiperbaric (HBOT) în mod regulat, cel puțin o dată la două săptămâni,
        timp de cinci până la 15 ani. Pacienții care au prezentat cele mai bune rezultate au primit în medie 300 de tratamente pe o perioadă
        de cel puțin 10 ani sau la fiecare două săptămâni. Desigur, pacienții care au primit tratament cu oxigen hiperbaric săptămânal au
        avut rezultate mai bune. Dintre pacienții care au continuat tratamentele regulate, 23% nu au prezentat nicio deteriorare și 7% au
        prezentat îmbunătățiri.</p>
    <h3>Medicina hiperbară, HBOT și tratamentele pentru scleroza multiplă</h3>
    <p>Când bariera hematoencefalică suferă o leziune, țesuturile cerebrale se umflă și împiedică furnizarea de O2, ducând la pierderea
        funcției. Având în vedere acest lucru, oxigenoterapia hiperbarică împinge oxigenul adânc în zone în care sistemul circulator nu
        poate ajunge. Acest lucru permite barierei hematoencefalice și țesuturilor sale subiacente să se vindece și restabilește capacitatea
        sistemului nervos de a transmite cu precizie mesajele. Cât de eficient poate fi un tratament cu oxigen hiperbaric (HBOT) pentru
        scleroza multiplă depinde de situaţia în care celulele lezate au murit cu adevărat sau în care celulele rămân latente, dar viabile.
        Tratamentele HBOT pentru scleroza multiplă produc devreme cele mai bune rezultate, în timp ce potențialul creierului de a restabili
        funcția pierdută este cel mai mare. Chiar dacă HBOT nu poate ajuta toţi pacienţii cu scleroză multiplă, este important să ne amintim
        că până și pacienții cu scleroză multiplă cronică au experimentat îmbunătățiri dramatice.</p>


    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        Dacă doreşti să afli mai multe despre modul în care medicina hiperbară și HBOT te pot ajuta să tratezi scleroza multiplă, sună la
        cabinetul nostru chiar astăzi și stabileşte o consultație inițială. De asemenea, poţi face click aici pentru completa formularul
        nostru de contact.
    </app-advert>

    <h3>Ce este scleroza multiplă?</h3>
    <p>Scleroza multiplă este o boală a sistemului nervos care afectează creierul și măduva spinării. Aceasta afectează teaca de mielină,
        care este materialul protector din jurul celulelor nervoase. Distrugerea acestor învelișuri perturbă semnalele dintre creier și
        corp. Cauza SM este necunoscută, dar este considerată o boală mediată imun. Cu o boală mediată imun, sistemul imunitar al corpului
        atacă celulele sănătoase și, în cazul sclerozei multiple, teaca de mielină a nervilor.</p>

    <p>Simptomele de scleroză multiplă apar de obicei între 20 și 40 de ani și apar mai des la femei decât la bărbați. Un factor de risc
        ciudat pentru scleroza multiplă este latitudinea. Mai exact, persoanele care trăiesc mai departe de Ecuator au un factor de risc mai
        mare de dezvoltare a bolii. Există patru tipuri diferite de scleroză multiplă și simptomele și progresia bolii sunt dependente de
        cantitatea de leziuni ale nervilor și de nervii care sunt afectați.</p>

    <h3>Diferite tipuri de scleroză multiplă</h3>

    <p>Există patru tipuri diferite de scleroză multiplă și fiecare tip este determinat de modul în care boala afectează corpul. Aceste
        patru tipuri includ:</p>
    <ul>
        <li>Scleroză multiplă recidivant-remitentă (SMRR) - SMRR este cea mai frecventă formă de scleroză multiplă, 85% dintre pacienții cu
            scleroză multiplă primind acest diagnostic. Se caracterizează prin atacuri clare, denumite recăderi sau exacerbări. Fiecărui
            atac urmează o perioadă de recuperare sau remisiune. În timpul acestor remisiuni, toate simptomele pot dispărea complet până la
            următorul atac sau unele pot rămâne și deveni permanente.
        </li>
        <li>Scleroză multiplă secundar-progresivă (SMSP) - Scleroza multiplă secundar-progresivă este ca SMRR prin faptul că există perioade
            de recidive și remisiuni, cu toate acestea, pacienții cu SMSP prezintă progresiv agravarea simptomelor și a funcției
            neurologice. 60 până la 70% dintre pacienții cu SMRR progresează în cele din urmă la SMSP.
        </li>
        <li>Scleroză multiplă primar-progresivă (SMPP) - Scleroza multiplă primar-progresivă se caracterizează prin funcții neurologice
            înrăutățite continuu, fără recidive sau remisiuni. Doar 15% dintre pacienții cu scleroză multiplă sunt diagnosticați cu SMPP.
        </li>
        <li>Scleroză multiplă progresiv-recidivantă (SMPR) - Scleroza multiplă progresiv-recidivantă este o formă rară a bolii, afectând
            doar cinci la sută dintre pacienții cu scleroză multiplă. Această formă prezintă agravarea funcției neurologice cu perioade de
            recidive. În timpul acestor recidive, simptomele nu dispar, dar boala nu progresează.
        </li>
    </ul>

    <h3>Simptomele sclerozei multiple</h3>
    <p>Simptomele sclerozei multiple variază între pacienți și depind de localizarea fibrelor nervoase deteriorate. Simptomele frecvente
        includ:</p>
    <ul>

        <li>Amorțeală sau slăbiciune în picioare sau trunchi;</li>
        <li>Pierderea parțială sau completă a vederii;</li>
        <li>Vedere dublă;</li>
        <li>Furnicături sau dureri ale nervilor;</li>
        <li>Semnul lui Lhermitte - o senzație de șoc electric, care apare atunci când gâtul este mișcat;</li>
        <li>Tremor muscular;</li>
        <li>Lipsă de coordonare, amețeli sau un mers nesigur;</li>
        <li>Probleme cu vorbirea;</li>
        <li>Oboseală severă;</li>
        <li>Spasticitate musculară;</li>
        <li>Probleme cu funcția vezicii urinare și a intestinului;</li>
        <li>Modificări ale dispoziției.</li>
    </ul>

    <h3>Tratamente tradiționale pentru scleroza multiplă</h3>
    <p>Nu există nici un remediu pentru scleroza multiplă și tratamentele se concentrează pe încetinirea progresiei și tratarea simptomelor.
        În timpul atacurilor de scleroză multiplă, corticosteroizii sau plasmafereza acționează pentru a reduce durata atacurilor și pentru
        a readuce pacientul într-un stadiu de remisiune. Pentru a încetini progresia bolii, medicii prescriu diverse medicamente care
        modifică boala și imunosupresoare. Kinetoterapia ajută la întărirea mușchilor afectați de boală, precum și la adaptarea la viața de
        zi cu zi. Relaxantele musculare pot ajuta la reducerea spasticității musculare și alte medicamente vizează depresia, durerea și
        problemele de control al vezicii urinare și intestinului.</p>

    <p class="font-weight-bold">Dacă dorești să afli mai multe despre modul în care medicina hiperbară și HBOT pot veni în sprijinul
        pacienților cu scleroză multiplă, te rugăm să ne contactezi!
    </p>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        SOLICITĂ O CONSULTAŢIE TELEFONICĂ CHIAR ASTĂZI!
    </app-advert>
</app-segment>


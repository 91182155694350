<div class="container pt-5 pb-5">
    <div class="row">
        <div class="col">
            <h1>
                Politica privind confidențialitatea datelor cu caracter personal
            </h1>
            <h2>1. General</h2>
            <p>terapiehiperbara.com este proprietatea SC REVIEW SRL. REVIEW SRL oferă servicii de investigații oftalmologice și
                tratamente specifice, în funcție de vârsta și tipologia pacienților.</p>
            <p>REVIEW SRL operează acest site în conformitate cu prevederile Regulamentului general european privind protecția
                datelor 2016/679 ("GDPR") și a tuturor celorlalte dispoziții relevante în domeniul protecției datelor cu caracter personal
                al utilizatorilor acestui site.</p><br>
            <h2>2. Contact</h2>
            <p>Administratorul datelor dumneavoastră personale este REVIEW SRL, cu sediul în localitatea Cluj-Napoca, Strada
                Moților, Nr. 9, Ap. 8, judeţul Cluj, România, CUI 18633811, Nr. Înregistrare în Registrul Comerțului J12/1507/2006.</p>
            <p>Dacă aveți întrebări cu privire la modul în care colectăm, stocăm și folosim informațiile dumneavoastra
                personale, doriți o copie sau ștergerea informațiilor pe care le deținem, vă rugăm să contactați responsabilul cu protecția
                datelor. Puteți scrie la adresa de <b>e-mail vreausamafacbine@terapiehiperbara.com</b>.</p><br>
            <h2>3. Despre ce este acest site?</h2>
            <p>Pe acest site puteți găsi informații despre serviciile de investigații, diagnosticare și tratamente oferite de
                Oxygen Review, despre echipa noastră și alte informații care vă pot ghida în alegerea celei mai bune decizii referitoare la
                sănătatea dumneavoastră, precum și un formular de contact, prin care ne puteți scrie și oferi detalii despre
                dumneavoastră.</p><br>
            <h2>4. Cookie-uri</h2>
            <p>Fișierele cookie sunt fișiere mici cu informații care sunt stocate local în memoria cache a serverului pentru
                colectarea unor date despre utilizatorii site-ului, sau sunt stocate în memoria browserului utilizatorului de internet
                pentru o funcționare facilă a site-ului.</p>
            <p>Site-ul terapiehiperbara.com folosește doar cookie-uri necesare funcționării optime a site-ului și nu stochează date pe
                server decât pe perioada în care dumneavoastră vizitați acest site.</p><br>
            <h2>5. Prelucrarea datelor cu caracter personal de către REVIEW SRL</h2>
            <h3>5.1 Informațiile pe care le colectăm în mod automat când accesați site-ul nostru</h3>
            <p>Nu colectăm automat date personale despre dumneavoastră atunci când vizitați site-ul nostru, decât cu acordul
                dumneavoastră prin utilizarea formularului nostru de la secțiunea de contact.</p>
            <h3>5.2 Informațiile pe care le furnizați prin contactarea noastră intenționată</h3>
            <p>Oferim un formular de contact pe site-ul nostru. REVIEW SRL va stoca datele personale furnizate de dumneavoastră
                atunci când trimiteți formularul de contact completat (de exemplu, nume, prenume, adresa de e-mail și telefon) în sistemul
                său de relații cu clienții.</p>
            <p>REVIEW SRL va folosi astfel datele personale:</p>
            <ul>
                <li>să răspundă solicitării trimise;</li>
                <li>să păstreze datele pentru facilitarea unor programări ulterioare;</li>
                <li>în formă anonimă pentru îmbunătățirea serviciilor noastre;</li>
                <li>pentru a preveni și a identifica abuzul sau frauda.</li>
            </ul>

            <p>Datele colectate prin intermediul formularului de contact nu vor fi transmise terților.</p>


            <h3>5.3 Transferul datelor personale</h3>
            <p>Noi folosim datele dumneavoastră personale doar pentru scopurile identificate și în limitele necesare pentru
                realizarea acestor scopuri. Transferul va avea loc (dacă este cazul) numai în baza unor cerințe legale. Transferurile către
                autoritățile publice și organele administrative au loc numai în domeniul de aplicare a dispozițiilor legale naționale
                obligatorii sau dacă este necesar în cazurile de abuz sau de fraudare pentru executarea silită civilă și penală. Transferul
                în alte scopuri - în special în scopul tranzacționării adreselor - este exclus.</p><br>
            <h2>6. Stocarea datelor</h2>
            <p>Stocăm datele dumneavoastră personale atâta timp cât este necesar pentru a îndeplini scopul pentru care au fost
                colectate, fără a aduce atingere oricăror obligații legale de păstrare, în special în conformitate cu legislația fiscală sau
                contabilă.</p><br>
            <h2>7. Siguranța datelor</h2>
            <p>Pentru a proteja datele personale împotriva pierderii, falsificării sau dezvăluirii către terți neautorizați, am
                luat măsuri organizatorice, tehnice și administrative adecvate. Toți membrii personalului și toate persoanele implicate în
                prelucrarea datelor sunt supuse obligației de a respecta toate legile referitoare la protecția datelor și de a trata
                confidențial datele cu caracter personal.</p><br>
            <h2>8. Drepturile persoanelor vizate</h2>
            <p>Conform GDPR, utilizatorii site-ului, ca persoane vizate, au anumite drepturi, în special dreptul de a accesa,
                corecta, actualiza sau solicita ștergerea datelor personale pe care noi le stocăm. Dacă doriți să exercitați oricare dintre
                aceste drepturi, puteți face acest lucru în orice moment, contactându-ne la <b>adresa vreausamafacbine@terapiehiperbara.com</b>
            </p>
            <p>Puteți contesta prelucrarea datelor dumneavoastră personale, puteți restricționa prelucrarea datelor
                dumneavoastră personale sau să solicitați transferabilitatea datelor dumneavoastră personale. Din nou, puteți exercita
                aceste drepturi contactându-ne la <b>adresa vreausamafacbine@terapiehiperbara.com</b></p>
            <p>În mod similar, dacă am colectat și procesat datele personale cu acordul dumneavoastră, atunci vă puteți retrage
                consimțământul în orice moment doriți. Retragerea consimțământului dumneavoastră nu va afecta legalitatea oricărei
                prelucrări efectuate înainte de retragerea dumneavoastră și nici nu va afecta procesarea datelor dumneavoastră personale
                efectuate în baza unor motive legale de prelucrare, altele decât consimțământul.</p>
            <p>Pentru mai multe informații, vă rugăm să ne contactați la adresa de e-mail de mai sus.</p>
            <p>Responsabilul nostru pentru protecția datelor poate fi contactat la <b>adresa vreausamafacbine@terapiehiperbara.com</b></p>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationStart, Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class AlertService {
    private subject = new Subject<any>();
    private timeRemaining = new Subject<any>();
    private keepAfterNavigationChange: boolean;
    private timer: any;

    constructor(private router: Router) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event && event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next(null);
                    this.timeRemaining.next();
                }
            }
        });
    }

    messageSubject(): Observable<any> {
        return this.subject.asObservable();
    }

    removeMessage(timeOut?: number) {
        if (!timeOut) {
            timeOut = 10000;
        }

        let n = timeOut / 1000;

        const countDown = () => {
            n--;
            if (n <= 0) {
                clearInterval(this.timer);
                this.subject.next(null);
                return this.timeRemaining.next();
            }
            return this.timeRemaining.next({value: n});
        };

        this.timer = setInterval(countDown, 1000);
    }

    error(message: any, keepAfterNavigationChange: boolean = true, timeout: number = 0) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        if (message instanceof HttpErrorResponse) {
            message = message.message || message.statusText || 'Unknown error';
        }
        this.subject.next({type: 'danger', text: message});
        if (timeout > 0) {
            this.removeMessage(timeout);
        }
    }

    success(message: string, keepAfterNavigationChange: boolean = true, timeout: number = 0) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({type: 'success', text: message});
        if (timeout > 0) {
            this.removeMessage(timeout);
        }
    }

    clear() {
        clearInterval(this.timer);
        this.timeRemaining.next();
        this.subject.next();
    }
}

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import * as moment from 'moment';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

    constructor(private meta: Meta,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description', content: 'Îți propunem o nouă abordare pentru sănătatea ta: terapie hiperbară într-un spațiu sigur, confortabil și ultramodern.'
            },
            {name: 'keywords', content: 'terapie hiperbara, camera hiperbarica, camera hiperbarica cluj, camera hiperbara, centrul medical review, terapie hiperbara cluj'},
        ]);
        this.title.setTitle('Terapie Hiperbară Cluj – Centrul Medical Review');
    }

    ngOnInit(): void {
    }

}

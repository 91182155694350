import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { isPlatformBrowser } from '@angular/common';
import { EMPTY } from 'rxjs';

@Injectable()
export class UniversalInterceptor implements HttpInterceptor {
    constructor(@Optional() @Inject(REQUEST) protected request: Request,
                @Inject(PLATFORM_ID) private platformId: object) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (this.request && !isPlatformBrowser(this.platformId)) {
            return EMPTY;
        }
        return next.handle(req);
    }
}

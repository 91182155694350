<app-segment [image]="imageConfig">
    <header>HBOT și durerile de cap de tip migrenă</header>

    <p>Durerile de cap de tip migrenă nu sunt durerile de cap de zi cu zi. Sunt o tulburare neurologică care afectează aproximativ 39 de
        milioane de americani și peste un miliard de oameni din întreaga lume. Migrenele sunt a 6-a afecțiune cea mai invalidantă din lume.
        La fiecare 10 secunde, cineva din Statele Unite solicită asistență medicală de urgență pentru migrene. Companiile americane pierd
        mai mult de 13 miliarde $ în fiecare an, până la 113 milioane de zile de muncă pierdute de către angajații cu migrene. Deși nu
        există un remediu pentru migrene, tratamentul se concentrează pe ameliorarea simptomelor și prevenirea atacurilor suplimentare.</p>
    <p>Medicina hiperbară, prin intermediul HBOT, se dovedește a găsi o soluție în oprirea durerilor de cap de tip migrenă atunci când
        apar.</p>
    <h2>Cum ajută HBOT pacienții cu dureri de cap de tip migrenă</h2>
    <p>Teoriile timpurii speculează migrenele apărute din cauza modificărilor fluxului sanguin către creier. În timp modificări ale fluxului
        sanguin ce se produc și contribuie la durere, credințele actuale sunt că modificările nivelurilor hormonilor, cum ar fi serotonina
        și estrogenul, declanșează migrena. O teorie este că valurile de activitate din celulele creierului declanșează eliberarea de
        serotonină, care la rândul său îngustează vasele de sânge. Când aceste vase de sânge se îngustează, oxigenul către creier este
        redus. Se crede că aceste niveluri reduse de oxigen (hipoxie) sunt responsabile pentru declanșarea migrenelor.</p>
    <p>Cu această hipoxie sau cu niveluri reduse de oxigen, utilizarea unui tratament cu oxigen hiperbaric – HBOT – pentru a aborda
        simptomele migrenei are sens. În timpul tratamentelor HBOT, medicii administrează 100% oxigen la presiune crescută, livrând oxigen
        direct în creier. Acest lucru ajută la reducerea tumefacţiei. Deși HBOT nu va preveni durerile de cap și nu va reduce frecvența,
        mulți pacienți care vin apelează la tratament cu oxigen hiperbaric în timpul unei migrene au constatat că oprește durerea imediat și
        complet.</p>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        SOLICITĂ O CONSULTAŢIE TELEFONICĂ CHIAR ASTĂZI!
    </app-advert>

    <h3>Studii recente privind HBOT și durerile de cap de tip migrenă</h3>
    <p>Numeroase studii au analizat eficacitatea oxigenului hiperbaric și a HBOT asupra simptomelor migrenei și durerii. Un studiu, realizat
        de Dr. William Fife și Dr. Caroline Fife, a analizat 26 de pacienți cu migrenă. Toți pacienții, cu excepția unuia, au experimentat
        ameliorarea completă a durerii în câteva minute de la începerea sesiunii. Doi pacienți au avut paralizie temporară a unei părți a
        feței ca urmare a unei migrene. HBOT a tratat în mod eficient paralizia.</p>
    <p>Un studiu realizat în 1995 a comparat efectele generate de un tratament cu oxigen hiperbaric faţă de cele generate de oxigenul
        normobaric (sau 100% oxigen livrat la presiunea normală a aerului). Studiul a analizat 20 de pacienți, din care 10 au primit 100%
        oxigen la o presiune atmosferică (normobarică), iar ceilalţi 10 au primit 100% oxigen la două atmosfere de presiune (HBOT).
        Rezultatele au arătat că un pacient din grupul normobaric a prezentat ameliorarea simptomelor, în timp ce nouă din cei 10 din
        grupurile HBOT au prezentat ameliorarea simptomelor.</p>
    <p>Într-un studiu din 2008, cercetătorii au analizat și comparat nouă teste cu un total de 201 de participanți. Au fost analizate
        eficacitatea HBOT în tratarea migrenelor și a durerilor de cap cluster. Aceştia au stabilit că HBOT a eliminat în mod eficient
        durerea și simptomele în timpul unui atac de migrenă, dar nu a fost eficient în prevenirea migrenelor.</p>
    <p>Dacă doreşti să afli mai multe despre modul în care medicina hiperbară și HBOT te pot ajuta în tratarea migrenelor, sună la cabinetul
        nostru chiar astăzi. De asemenea, poți face click aici pentru a completa un formular de contact, iar personalul nostru te va
        contacta.</p>
    <h3>Ce este o migrenă?</h3>
    <p>Din punct de vedere clinic, o migrenă este diagnosticată dacă o persoană are cinci sau mai multe dureri de cap neprovocate, care
        durează patru până la 72 de ore sau mai mult și sunt suficient de severe pentru a restricționa sau opri activitatea zilnică. Acestea
        includ simptome precum greață și sensibilitate la lumină și sunet. În majoritatea cazurilor de migrene, durerea este izolată pe o
        parte a capului, cauzând adesea o durere zvâcnitoare. În timp ce aurele sau tulburările vizuale pot apărea în cazul migrenelor, nu
        toți pacienții cu migrenă le experimentează. Se crede că acestea sunt de origine genetică, 90% dintre persoanele care suferă de
        migrenă având antecedente familiale de migrene. Această predispoziție genetică reflectă o hipersensibilitate în anumite celule ale
        creierului, care, atunci când este declanșată de o schimbare a mediului, cum ar fi o schimbare a presiunii barometrice (schimbare
        externă) sau o modificare a nivelului hormonal al corpului (schimbare internă), declanșează durerea.</p>
    <h3>Patru faze diferite ale migrenelor</h3>
    <p>În timp ce migrenele sunt diferite pentru fiecare persoană, acestea prezintă patru faze distincte. Nu fiecare pacient experimentează
        fiecare fază. Cineva poate experimenta trei din cele patru faze cu o migrenă și apoi pe toate patru pentru alta. Aceste patru faze
        includ:</p>
    <ul>
        <li>Prodrom - Aceasta este o etapă de avertizare timpurie cu privire la apariția unei migrene. De obicei, apare cu 24 de ore înainte
            de apariția migrenei, dar poate apărea cu câteva ore până la câteva zile înainte de migrenă. Simptomele includ poftă de
            alimente, retenție de lichide, urinare crescută, căscat incontrolabil și modificări inexplicabile ale dispoziției.
        </li>
        <li>Aură - Nu toți oamenii experimentează etapa de aură. Dacă o resimţi, vei experimenta tulburări vizuale, cum ar fi lumini
            intermitente și luminoase sau vederea de linii în zig-zag. Alte simptome în această fază pot include slăbiciune musculară,
            pierderea vederii, senzații de înţepături în brațe și picioare, dificultăți de vorbire, mișcări intermitente necontrolate și
            zgomote auditive. Aurele pot continua în faza următoare.
        </li>
        <li>Cefalee - Durerea de tip migrenă începe de obicei treptat, durerea intensificându-se în timp. Durerea este durere zvâcnitoare
            sau pulsantă, adesea pe o parte a capului. Simptome suplimentare în această fază includ greață/vărsături, sensibilitate crescută
            la lumină și sunet, vedere încețoșată, senzație de amețeală, leșin și multe altele.
        </li>
        <li>Postdrom - Postdrom, cunoscut și sub numele de mahmureală de migrenă, este etapa de recuperare după ce trece o durere de cap.
            Este posibil să simți epuizare, slăbiciune, confuzie, sensibilitate la lumină și sunet și proastă dispoziţie care durează câteva
            zile. Băutul unei cantităţi mari de apă și odihna ajută la reducerea timpului și a simptomelor.
        </li>
    </ul>
    <h3>Opțiuni tradiționale pentru tratamentul migrenei</h3>
    <p>Nu există nici un remediu pentru migrene. Tratamentul se concentrează pe gestionarea durerii și încercarea de a reduce severitatea și
        frecvența atacurilor. Tratamentul durerii poate include medicamente precum analgezice fără prescripție medicală, triptani, ergoți,
        opioizi și glucocorticoizi. De asemenea, sunt prescrise medicamente anti-greață. Dacă suferi de mai mult de patru migrene pe lună,
        atacurile durează mai mult de 12 ore sau nu resimţi ameliorare după administrarea medicamentelor analgezice, medicul poate lua în
        considerare medicamente zilnice pentru a reduce frecvența și severitatea migrenelor. Aceste medicamente includ medicamente
        cardiovasculare, antidepresive, medicamente anticonvulsive, injecții cu Botox sau Erenumab-Aoe, o injecție o dată pe lună.</p>
    <p class="font-weight-bold">Dacă suferi de migrene și doreşti mai multe informații despre modul în care medicina hiperbară, oxigenul
        hiperbaric și HBOT te pot ajuta, sună la cabinetul nostru chiar astăzi și stabileşte o primă consultație cu Dr. Demea și echipa sa
        sau vizitează pagina noastră <a
            [routerLink]="['/contact']" class="nav-link d-inline-block p-0 cursor-pointer"><i class="fa fa-link mt-0 mb-0"></i> aici</a> și
        completează formularul nostru online. Aceştia îţi vor analiza istoricul medical și te vor ajuta să concepi un plan de tratament cu
        oxigen hiperbaric adaptat problemelor tale.</p>
</app-segment>


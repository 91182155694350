<header class="header">
    <!-- Navbar-->
    <nav class="navbar navbar-expand-xl fixed-top-lg shadow bg-white">
        <div class="container-fluid">
            <!-- Logo -->
            <div class="pr-lg-3 text-center text-lg-left">
                <a class="navbar-brand py-0 d-flex" href="#">
                    <picture class="fixed-width-logo">
                        <source srcset="/assets/images/logo_transparent2021.webp 1x, /assets/images/logo_transparent2021.webp 2x"
                                type="image/webp">
                        <source srcset="/assets/images/logo_transparent2021.png 1x, /assets/images/logo_transparent2021.png 2x"
                                type="image/png">
                        <img alt="Oxygen Review Logo"
                             src="/assets/images/logo_transparent2021.png"
                             title="terapiehiperbara.com">
                    </picture>
                    <!--<img [src]="'/assets/images/logo_transparent2021.webp'"
                         alt="Oxygen Review Logo"
                         class="fixed-width-logo">-->
                </a>
            </div>
            <button aria-controls="navbarCollapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    class="navbar-toggler ml-auto"
                    data-target="#navbarCollapse"
                    data-toggle="collapse"
                    type="button"><i class="fa fa-bars fa-2x"></i></button>
            <!-- Navbar Collapse -->
            <div class="collapse navbar-collapse justify-content-lg-end align-content-lg-end"
                 id="navbarCollapse">
                <div class="d-flex flex-column justify-content-between align-items-lg-end">
                    <p class="text-right pr-2 text-muted">
                        Telefon programări:
                        <a [href]="'tel:0364107206'">0364.107.206</a>,
                        <a [href]="'tel:0745659945'">0745.659.945</a>
                    </p>
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a [routerLink]="['']"
                               data-toggle="collapse"
                               data-target=".navbar-collapse.show"
                               class="nav-link"
                               routerLinkActive="active">Acasă</a></li>
                        <li class="nav-item">
                            <a [routerLink]="['/terapie-cu-oxigen-hiperbaric']"
                               data-toggle="collapse"
                               data-target=".navbar-collapse.show"
                               class="nav-link"
                               routerLinkActive="active">Ce este HBOT?</a></li>
                        <li class="nav-item">
                            <a [routerLink]="['/tratamente-si-beneficii-hbot']"
                               data-toggle="collapse"
                               data-target=".navbar-collapse.show"
                               class="nav-link"
                               routerLinkActive="active">Ce tratează HBOT?</a></li>
                        <li class="nav-item">
                            <a [routerLink]="['/hirudoterapie']"
                               data-toggle="collapse"
                               data-target=".navbar-collapse.show"
                               class="nav-link"
                               routerLinkActive="active">Hirudoterapie</a></li>
                        <!--<li class="nav-item dropdown">
                            <a [routerLink]="['/beneficiile-hbot']"
                               data-target=".navbar-collapse.show"
                               data-toggle="collapse"
                               class="nav-link"
                               routerLinkActive="active">Beneficiile HBOT</a>
                        </li>-->
                        <li class="nav-item">
                            <a [routerLink]="['/contact']"
                               data-target=".navbar-collapse.show"
                               data-toggle="collapse"
                               class="nav-link"
                               routerLinkActive="active">Contact</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
    <!-- /Navbar -->
</header>

<app-segment [image]="imageConfig">
    <header>HBOT și pacienții cu cancer</header>

    <p>Medicina hiperbară sau, mai specific, HBOT poate juca un rol vital în procesul de tratament al multor tipuri de cancer precum și
        efectele secundare comune asociate tratamentelor tradiționale pentru cancer. Pe măsură ce studiile continuă, acestea descoperă, de
        asemenea, că ședințele de tratament cu oxigen hiperbaric pot juca un rol semnificativ în reducerea anumitor tumori cancerigene.
    </p>

    <h2>Rolul pe care îl joacă oxigenul în relaţie cu celulele canceroase și modul în care HBOT ajută</h2>
    <p>Celulele corpului nostru necesită oxigen pentru creștere și, în trecut, timp de mulți ani, medicii credeau că și celulele canceroase
        au nevoie de oxigen pentru a crește, îndepărtându-se astfel de orice tip de tratament cu oxigen. </p>
    <p>În zilele noastre, prin studii minuțioase, medicii au stabilit că celulele canceroase prosperă cel mai bine în medii lipsite de
        oxigen, determinate deseori la hipoxemie (niveluri inadecvate de oxigen în sânge). Deci, spre deosebire de credințele anterioare,
        creșterea nivelului de oxigen, livrat prin HBOT, împiedică creșterea cancerului, iar medicina hiperbară poate asigura beneficii
        pacienților care se confruntă cu boala. De exemplu un studiu din 2009, efectuat de cercetători de la Universitatea Oxford, a arătat
        că injectarea de oxigen în tumorile solide a crescut semnificativ șansele de recuperare a pacienților și a crescut eficacitatea
        tratamentelor de chimioterapie asupra celulelor canceroase.</p>

    <h2>Tipurile specifice de cancer și răspunsul la tratamentele HBOT</h2>

    <p>În majoritatea cazurilor, planul de tratament cu oxigen hiperbaric sau HBOT funcționează ca adjuvant, împreună cu tratamentele
        tradiționale pentru cancer, cum ar fi chimioterapia și radiațiile. Cu toate acestea, studii recente arată rezultate promițătoare,
        conform cărora medicina hiperbară și tratamentele HBOT pot juca un rol crucial în tratamentul împotriva cancerului. Un studiu
        realizat în anul 2010, publicat în Cancer Biology And Therapy (Biologia şi Terapia Cancerului), a arătat că utilizarea terapiei HBOT
        pe o tumoare ovariană la șoareci, administrată zilnic timp de 21 de zile, a redus semnificativ creșterea tumorii. Au făcut un pas
        mai departe și au adăugat un agent de chimioterapie alături de terapia HBOT la alți șoareci. Rezultatele au arătat că respectiva
        combinație a redus volumul tumorii în decurs de două săptămâni.</p>

    <p> Numeroase studii din ultimii ani au analizat legătura dintre medicina hiperbară, tratamentele HBOT și diferitele tipuri de cancer
        cerebral. Într-un studiu realizat în anul 2015, publicat în Oncology Letters, cercetătorii au analizat dacă tratamentele HBOT ar fi
        eficiente pentru pacienții nou diagnosticați cu glioblastom multiform (GBM), un cancer cerebral foarte agresiv cu prognostic slab.
        Aceştia au stabilit că adăugarea tratamentelor HBOT este o opțiune terapeutică eficientă și poate îmbunătăți prognosticul pentru
        pacienți.
    </p>

    <p>Un alt studiu publicat în aprilie 2018 a arătat că utilizarea unui plan de tratament cu oxigen hiperbaric (HBOT) împreună cu
        radioterapia a redus foarte mult rata mortalității în rândul pacienților diagnosticați cu cancer la cap sau gât. În plus, recurența
        tumorii a fost mai puțin probabilă prin adăugarea tratamentelor HBOT.</p>

    <h2>Tratarea complicațiilor legate de cancer cu HBOT</h2>

    <p>Pe lângă faptul că lucrează alături de tratamentele tradiționale pentru combaterea cancerului, HBOT (tratament cu oxigen hiperbaric)
        este, de asemenea, o opțiune pentru multe afecțiuni asociate tratamentului cancerului. Radiațiile și chimioterapia sunt dure pentru
        corp și, deși lucrează pentru a ucide celulele canceroase, creează la rândul lor probleme. Abordăm îndeaproape aceste afecţiuni mai
        jos.</p>

    <h3>Oboseala</h3>
    <p>În acest caz, atât tratamentele tradiționale, cât și cancerul în sine pot provoca oboseală. Deoarece medicina hiperbară a avut
        beneficii HBOT la pacienții care suferă de sindromul de oboseală cronică, pacienții cu cancer găsesc adesea ameliorarea oboselii lor
        debilitante.</p>

    <h3>Anemia</h3>
    <p>Anemia, sau un volum scăzut de celule roșii din sânge, este adesea un efect secundar atât al cancerului, cât și al tratamentelor
        pentru cancer. Deoarece terapia HBOT stimulează măduva osoasă, crește producția de celule roșii din sânge.</p>

    <h3>Mucozita și esofagita</h3>
    <p>Mucozita este inflamația și ulcerarea membranelor din tractul digestiv. Esofagita este inflamația esofagului. Ambele afecțiuni pot fi
        complicații debilitante ale chimioterapiei și ale radiațiilor. Acestea apar cel mai frecvent atunci când medicii folosesc ambele
        opțiuni de terapie coroborate. Tratamentele HBOT acționează pentru a reduce tumefacţia, pentru a accelera vindecarea și pentru a
        combate pseudomonasele și alte infecții bacteriene comune cu aceste afecțiuni.</p>

    <h3>Chemo creierul</h3>
    <p>Tratamentele regulate cu agenți de chimioterapie și radiație pot provoca ceea ce se numește chemo creier sau ceață cerebrală. Aceasta
        este o reducere a memoriei pe termen scurt și lung. În plus, contribuie la reacții întârziate și la alte probleme cognitive. Într-un
        caz, în 1993, o femeie de 56 de ani a fost supusă unei mastectomii, împreună cu chimioterapie și radiaţii. După aceste tratamente,
        memoria și abilitățile sale de gândire critică au scăzut. În 1996, ea nu mai putea lucra. În 2002, a fost tratată cu 20 de ședințe
        de tratament cu oxigen hiperbaric HBOT, de câte o oră fiecare, și a arătat îmbunătățirea semnificativă a memoriei și a gândirii.
        Acest lucru i-a permis să se întoarcă la muncă pentru prima dată în șase ani.</p>

    <h3>Tratamentele pentru cancer, necroză provocată de radiații și HBOT</h3>
    <p>Necroza provocată de radiație este un efect secundar sever al radioterapiei, adesea folosită pentru tratarea tumorilor canceroase. În
        timp ce radiațiile acționează pentru a deteriora celulele canceroase, ele dăunează și celulelor sănătoase și normale. Această
        deteriorare duce la distrugerea severă a țesuturilor și a oaselor. Tipul de necroză provocată de radiații depinde de locul în care
        se află leziunile.</p>
    <p>Dacă radioterapia a tratat tumori pelvine sau abdominale, este posibil să apară sângerări ale vezicii urinare (cistită provocată de
        radiații), intestinului subțire (enterită provocată de radiații) sau ale rectului (proctită provocată de radiații). </p>
    <p>Pentru tumorile capului, este posibil să vedeți necroză în maxilar, dinți și gât.</p>
    <p>În cazurile de tumori ale sânului, plămânului și toracelui, este posibil să se observe necroza țesutului moale în peretele toracic.
        Acest lucru are ca rezultat o gamă redusă de mișcare sau umflarea ganglionilor limfatici.</p>

    <p>HBOT (tratament cu oxigen hiperbaric) a fost utilizat încă din anii 1970 pentru a trata cu mare succes necroza de radiație legată de
        cancer. Deoarece HBOT furnizează 100% oxigen, țesutul deteriorat primește o concentrație de oxigen mult mai mare. Aceasta stimulează
        o mai bună vindecare. Nu numai că nivelul crescut de oxigen repară țesuturile deteriorate, dar ajută și la repararea vaselor de
        sânge deteriorate. </p>

    <p>Te așteptăm la Centrul Medical Review cu o echipă de specialiști în medicina hiperbară, pregătiți să dezvolte un plan de tratament cu
        oxigen hiperbaric care să corespundă nevoilor și situației tale medicale.</p>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        SOLICITĂ O CONSULTAŢIE TELEFONICĂ CHIAR ASTĂZI!
    </app-advert>
</app-segment>


import { APP_ID, Inject, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { isPlatformBrowser, registerLocaleData, TitleCasePipe } from '@angular/common';
import { HeaderComponent } from './_common/header/header.component';
import { FooterComponent } from './_common/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RequestInterceptor } from './_interceptors/request.interceptor';
import { UniversalInterceptor } from './_interceptors/universal.interceptor.service';
import { ResponseInterceptor } from './_interceptors/response.interceptor';
import localeRo from '@angular/common/locales/ro';
import { NotFoundComponent } from './_common/not-found/not-found.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BannerComponent } from './dashboard/banner/banner.component';
import { GdprComponent } from './_common/gdpr/gdpr.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ContactModule } from './contact/contact.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SegmentComponent } from './_common/segment/segment.component';
import { HbotSiEmboliileAerieneSauGazoaseComponent } from './_pages/hbot-si-emboliile-aeriene-sau-gazoase/hbot-si-emboliile-aeriene-sau-gazoase.component';
import { AdvertComponent } from './_common/advert/advert.component';
import { HbotSiBoalaAlzheimerComponent } from './_pages/hbot-si-boala-alzheimer/hbot-si-boala-alzheimer.component';
import { HbotSiFracturileOsoaseComponent } from './_pages/hbot-si-fracturile-osoase/hbot-si-fracturile-osoase.component';
import { HbotSiTratamentulArsurilorComponent } from './_pages/hbot-si-tratamentul-arsurilor/hbot-si-tratamentul-arsurilor.component';
import { HbotSiPacientiiCuCancerComponent } from './_pages/hbot-si-pacientii-cu-cancer/hbot-si-pacientii-cu-cancer.component';
import { HbotSiIntocticaitaCuMonoxidDeCarbonComponent } from './_pages/hbot-si-intocticaita-cu-monoxid-de-carbon/hbot-si-intocticaita-cu-monoxid-de-carbon.component';
import { HbotSiParaliziaCerebralaComponent } from './_pages/hbot-si-paralizia-cerebrala/hbot-si-paralizia-cerebrala.component';
import { HbotSiMionecrozaClostridialaComponent } from './_pages/hbot-si-mionecroza-clostridiala/hbot-si-mionecroza-clostridiala.component';
import { HbotSiGrefeleLambourileCompromiseComponent } from './_pages/hbot-si-grefele-lambourile-compromise/hbot-si-grefele-lambourile-compromise.component';
import { HbotSiLeziunilePrinZdrobireSindromulDeCompartimentComponent } from './_pages/hbot-si-leziunile-prin-zdrobire-sindromul-de-compartiment/hbot-si-leziunile-prin-zdrobire-sindromul-de-compartiment.component';
import { HbotSiTratamentulFibromialgieiComponent } from './_pages/hbot-si-tratamentul-fibromialgiei/hbot-si-tratamentul-fibromialgiei.component';
import { HbotSiPierdereaSubitaAAuzuluiComponent } from './_pages/hbot-si-pierderea-subita-aauzului/hbot-si-pierderea-subita-aauzului.component';
import { HbotSiAbcesulIntracranianComponent } from './_pages/hbot-si-abcesul-intracranian/hbot-si-abcesul-intracranian.component';
import { HbotSiBoalaLymeComponent } from './_pages/hbot-si-boala-lyme/hbot-si-boala-lyme.component';
import { HbotSiDurerileDeCapDeTipMigrenaComponent } from './_pages/hbot-si-durerile-de-cap-de-tip-migrena/hbot-si-durerile-de-cap-de-tip-migrena.component';
import { HbotSiExpunereaLaMucegaiSiToxicitateDeMucegaiComponent } from './_pages/hbot-si-expunerea-la-mucegai-si-toxicitate-de-mucegai/hbot-si-expunerea-la-mucegai-si-toxicitate-de-mucegai.component';
import { HbotSiSclerozaMultiplaComponent } from './_pages/hbot-si-scleroza-multipla/hbot-si-scleroza-multipla.component';
import { HbotSiOsteomielitaRefractaraComponent } from './_pages/hbot-si-osteomielita-refractara/hbot-si-osteomielita-refractara.component';
import { HbotSiBoalaArterialaPerifericaComponent } from './_pages/hbot-si-boala-arteriala-periferica/hbot-si-boala-arteriala-periferica.component';
import { HbotSiSindromulPostPoliomeliticComponent } from './_pages/hbot-si-sindromul-post-poliomelitic/hbot-si-sindromul-post-poliomelitic.component';
import { HbotSiTulburareaDeStresPostTraumaticTsptComponent } from './_pages/hbot-si-tulburarea-de-stres-post-traumatic-tspt/hbot-si-tulburarea-de-stres-post-traumatic-tspt.component';
import { HbotSiNecrozaRadiationalaComponent } from './_pages/hbot-si-necroza-radiationala/hbot-si-necroza-radiationala.component';
import { HbotSiTratamentelePentruDsrSauSdrcComponent } from './_pages/hbot-si-tratamentele-pentru-dsr-sau-sdrc/hbot-si-tratamentele-pentru-dsr-sau-sdrc.component';
import { HbotSiTratamentulAnemieiComponent } from './_pages/hbot-si-tratamentul-anemiei/hbot-si-tratamentul-anemiei.component';
import { HbotSiAccidenteVasculareCerebraleComponent } from './_pages/hbot-si-accidente-vasculare-cerebrale/hbot-si-accidente-vasculare-cerebrale.component';
import { HbotSiLeziunileCerebraleTraumaticeComponent } from './_pages/hbot-si-leziunile-cerebrale-traumatice/hbot-si-leziunile-cerebrale-traumatice.component';
import { HbotSiTratamentulPentruIngrijireaPlagilorComponent } from './_pages/hbot-si-tratamentul-pentru-ingrijirea-plagilor/hbot-si-tratamentul-pentru-ingrijirea-plagilor.component';
import { HirudoterapieComponent } from './_pages/hirudoterapie/hirudoterapie.component';

// the second parameter 'fr' is optional
registerLocaleData(localeRo, 'ro');

@NgModule({
    imports: [
        BrowserModule.withServerTransition({appId: 'o2-review'}),
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        CarouselModule.forRoot(),
        ContactModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        // NgxGoogleAnalyticsModule.forRoot('UA-211000675-1'),
        // NgxGoogleAnalyticsRouterModule.forRoot({ include: [ '/full-uri-match' ] })
    ],
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        NotFoundComponent,
        DashboardComponent,
        BannerComponent,
        GdprComponent,
        SegmentComponent,
        AdvertComponent,
        HbotSiEmboliileAerieneSauGazoaseComponent,
        HbotSiBoalaAlzheimerComponent,
        HbotSiFracturileOsoaseComponent,
        HbotSiTratamentulArsurilorComponent,
        HbotSiPacientiiCuCancerComponent,
        HbotSiIntocticaitaCuMonoxidDeCarbonComponent,
        HbotSiParaliziaCerebralaComponent,
        HbotSiMionecrozaClostridialaComponent,
        HbotSiGrefeleLambourileCompromiseComponent,
        HbotSiLeziunilePrinZdrobireSindromulDeCompartimentComponent,
        HbotSiTratamentulFibromialgieiComponent,
        HbotSiPierdereaSubitaAAuzuluiComponent,
        HbotSiAbcesulIntracranianComponent,
        HbotSiBoalaLymeComponent,
        HbotSiDurerileDeCapDeTipMigrenaComponent,
        HbotSiExpunereaLaMucegaiSiToxicitateDeMucegaiComponent,
        HbotSiSclerozaMultiplaComponent,
        HbotSiOsteomielitaRefractaraComponent,
        HbotSiBoalaArterialaPerifericaComponent,
        HbotSiSindromulPostPoliomeliticComponent,
        HbotSiTulburareaDeStresPostTraumaticTsptComponent,
        HbotSiNecrozaRadiationalaComponent,
        HbotSiTratamentelePentruDsrSauSdrcComponent,
        HbotSiTratamentulAnemieiComponent,
        HbotSiAccidenteVasculareCerebraleComponent,
        HbotSiLeziunileCerebraleTraumaticeComponent,
        HbotSiTratamentulPentruIngrijireaPlagilorComponent,
        HirudoterapieComponent,
    ],
    bootstrap: [AppComponent],
    providers: [
        TitleCasePipe,
        {provide: LOCALE_ID, useValue: 'ro-RO'},
        {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: UniversalInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true},
    ],
})
export class AppModule {
    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        @Inject(APP_ID) private appId: string) {
        const platform = isPlatformBrowser(platformId) ?
            'in the browser' : 'on the server';
        console.log(`Running ${platform} with appId=${appId}`);
    }
}

<app-segment [image]="imageConfig">
    <header>HBOT și mionecroza clostridială</header>

    <p>Mionecroza clostridiană este o infecție bacteriană care pune viața în pericol și se dezvoltă de obicei după un traumatism sau o
        leziune, în special în cazurile în care leziunea provoacă moartea țesutului sau fluxul scăzut de oxigen către țesuturi. Clostridium
        perfringens sau Clostridium septicum cauzează de obicei infecțiile și, în țesuturile cu puțin sau deloc oxigen, se răspândesc foarte
        repede. Cazurile severe duc la deces în decurs de o zi de la infecție. Tratamentul începe de obicei cu drenarea și debridarea
        plăgii, precum și cu antibiotice. În cazul rănilor la extremități sau cu mionecroză extinsă, oxigenul hiperbaric administrat prin
        tratamentele HBOT, împreună cu antibiotice și debridare, ajută la salvarea țesuturilor și la reducerea mortalității din cauze de
        mionecroză clostridială.
    </p>

    <h2>Tratamentul mionecrozei clostridiene include antibiotice, debridare și HBOT</h2>
    <p>Mionecroza clostridiană este o afecțiune care pune viața în pericol, iar tratamentul imediat este esențial. Tratamentul inițial
        constă în administrarea imediată de antibiotice pe cale intravenoasă și debridarea plăgilor. Ideea este de a elimina toate
        țesuturile infectate sau moarte și de a permite țesuturilor sănătoase să se vindece. În cazurile severe, aceasta poate însemna
        amputarea membrelor. În astfel de situaţii, tratamentele HBOT, alături de antibiotice și debridare, pot face o mare diferență. Prin
        inundarea țesutului infectat cu oxigen, HBOT (adică un tratament cu oxigen hiperbaric) distruge în esență mediul deprivat de oxigen
        care permite bacteriilor să înflorească. În acest caz, medicina hiperbară și HBOT reprezintă o opțiune de tratament aprobată de FDA
        pentru mionecroza clostridiană, care îmbunătățesc rata de supraviețuire, ajută la evitarea operaţiilor mutilante, cum ar fi
        amputările, și permit conservarea maximă a țesuturilor.</p>

    <h3>Ce este mionecroza clostridiană?</h3>

    <p>Mionecroza clostridiană este o urgență medicală care pune viața în pericol, în care o infecție clostridiană se răspândește în țesutul
        muscular profund. Se împarte în două forme principale: traumatică și spontană. Principala cauză a formei traumatice este bacteria
        Clostridium perfringens, în timp ce Clostridium septicum este cauza formei spontane.</p>
    <p>Infecțiile traumatice apar atunci când o vătămare sau un traumatism are ca rezultat reducerea sau blocarea fluxului sanguin către
        țesut, cum ar fi o leziune prin zdrobire. Bacteriile intră în rană și, într-un mediu deprivat de oxigen, se răspândesc rapid.
        Această infecție se poate întâmpla și după o procedură chirurgicală.</p>
    <p>Infecțiile spontane apar de obicei la indivizii imunocompromiși sau în afecțiunile medicale subiacente, cum ar fi cancerul de colon
        sau rect, leucemia și diabetul zaharat. Se crede că, în cazul sistemului imunitar slăbit, corpul nu este suficient de puternic
        pentru a lupta cu Clostridium septicum și se populează în intestin, iar fără un sistem imunitar care să îl combată, se răspândește
        rapid.</p>
    <p>Clostridium se găsește aproape peste tot, dar odată ce intră în organism și se răspândește, eliberează gaze și toxine care acționează
        rapid pentru a distruge țesuturile, celulele și vasele de sânge.</p>

    <h3>Simptomele mionecrozei clostridiene</h3>
    <p>Semnele și simptomele variază în funcție de tipul și locația infecției. Debutul rapid al durerii severe este de obicei primul
        simptom, deși o persoană poate prezenta febră, oboseală și deshidratare. Pacienții cu forma spontană pot prezenta dureri musculare
        severe, senzație de greutate, presiune sau amorțeală. De asemenea, pot experimenta confuzie. Pe măsură ce infecția se răspândește,
        simptomele se înrăutățesc și includ:</p>

    <ul>
        <li>Gangrenă gazoasă - Pe măsură ce bacteria crește, produce toxine care eliberează gaze în țesutul muscular profund. Acest gaz
            provoacă rapid moartea țesutului și necroza;
        </li>
        <li>Modificări cutanate - Când infecția începe în țesutul profund, pielea de la suprafață este de obicei neafectată. Pe măsură ce
            infecția se răspândește, pielea poate deveni palidă, căpătând în cele din urmă culoarea roșie sau maro. Pe piele se pot forma
            saci umpluți cu lichid sau aer, numiți bule. În cele din urmă, pielea va deveni neagră sau verde închis, va fi delicată la
            atingere și poate produce un sunet trosnitor la atingere;
        </li>
        <li>Descărcare urât mirositoare și edem;</li>
        <li>Aortita - Aceasta se întâmplă atunci când infecția se răspândește în aortă. Artera se poate bomba sau umfla și poate duce la o
            ruptură aortică;
        </li>
        <li>Sepsis - Sepsisul este o infecție în sânge și poate duce la simptome precum bătăi rapide ale inimii, respirație rapidă, tensiune
            arterială scăzută și modificări ale conștientizării cognitive. Pe măsură ce progresează, poate duce la șoc toxic și insuficienţă
            multiorganică.
        </li>
    </ul>

    <p>Dacă ai nevoie de mai multe informații despre procedurile specifice HBOT sau despre medicina hiperbară îți stăm cu drag la
        dispoziție.</p>


    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        SOLICITĂ O CONSULTAŢIE TELEFONICĂ CHIAR ASTĂZI!
    </app-advert>
</app-segment>


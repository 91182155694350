import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CeTratamComponent } from './ce-tratam/ce-tratam.component';

const routes: Routes = [
    {path: '', component: CeTratamComponent}
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CeTratamRoutingModule {
}

<!-- Footer-->
<footer class="position-relative z-index-10 d-print-none">
    <div class="bg-light">
        <div class="container">
            <div *ngIf="!onContact" class="row py-3">
                <div class="col-lg-3">
                    <p class="h4 text-success">Centrul Medical Review</p>
                    <p>str. Siretului, nr. 16,<br>Cluj-Napoca, România</p>
                </div>
                <div class="col-lg-4">
                    <p class="h4 text-success">Programări Terapie Hiperbară</p>
                    <p class="mb-0 text-left">Telefon:
                        <span class="text-nowrap"><a [href]="'tel:0364107206'">0364.107.206</a>,
                        <a [href]="'tel:0745659945'">0745.659.945</a></span>
                    </p>
                    <p class="text-left"> E-mail: vreausamafacbine@terapiehiperbara.com</p>
                </div>
                <div class="col-lg-2">
                    <p class="h4 text-success">Program</p>
                    <p class="text-left text-break">Luni-Vineri:
                        <span class="text-nowrap">09:00-18:00</span></p>
                </div>
                <div class="col-lg-3">
                    <a [routerLink]="['/gdpr']"
                       [title]="'Citiți detalii despre GDPR'"
                       class="nav-link">Politica de confidențialitate a datelor cu caracter personal și cookies</a>
                </div>
            </div>
        </div>
    </div>


    <!-- Copyright section of the footer-->
    <div class="py-4 font-weight-light bg-success text-white">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 text-center text-md-left">
                    <p class="text-sm mb-md-0">© 2021, Oxygen Review. All rights reserved.</p>
                </div>
            </div>
        </div>
    </div>
</footer>

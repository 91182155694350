<app-segment [image]="imageConfig">
    <header>HBOT și intoxicaţia cu monoxid de carbon</header>

    <p>Monoxidul de carbon sau CO este un gaz incolor, inodor și lipsit de gust care apare atunci când sunt arse produse precum benzina,
        propanul, cărbunele, lemnul sau alte surse de combustibil. În zonele ventilate corespunzător, aceşti vapori se risipesc și nu sunt
        de obicei problematici. Cu toate acestea, în zonele închise, gazul se acumulează și poate provoca simptome grave, inclusiv decesul.
        Din fericire, oxigenul din HBOT, utilizat în medicina hiperbară, tratează intoxicaţia cu monoxid de carbon.
    </p>

    <p>Între 2010 și 2015, Centrele pentru Controlul și Prevenirea Bolilor raportează în SUA 2.244 de decese din cauza intoxicaţiei
        neintenționate cu monoxid de carbon. Numai în 2015 au avut loc 393 de decese. Dintre acestea, 36% au avut loc în lunile de iarnă din
        decembrie, ianuarie și februarie. În plus, peste 20.000 de persoane ajung la camera de urgență cu intoxicaţie cu monoxid de carbon
        în fiecare an. Principalul tratament pentru intoxicaţia cu monoxid de carbon este oxigenul. În multe cazuri, necesitatea unor
        niveluri ridicate de oxigen sub presiune crescută presupune adoptarea unui tratament cu oxigen hiperbaric sau tratament HBOT.</p>


    <h2>Tratamentul intoxicaţiei cu monoxid de carbon CO</h2>
    <p>Scopul tratamentului pentru intoxicaţia cu monoxid de carbon este eliminarea CO din organism și înlocuirea acestuia cu oxigen. În
        cazuri ușoare, o mască de oxigen care furnizează oxigen pur este suficientă. Cu toate acestea, în cazurile severe de intoxicaţie cu
        monoxid de carbon, o mască simplă de aer nu va funcționa. FDA a aprobat HBOT practicat de medicina hiperbară pentru tratamentul
        intoxicaţiei cu monoxid de carbon, în special la pacienții la care o mască de oxigen nu ameliorează simptomele. Un plan de tratament
        cu oxigen hiperbaric (HBOT) este recomandat la pacienții care prezintă complicații cardiopulmonare care pun viața în pericol, durere
        continuă în piept, alterare a cunoştinței, pierderea cunoștinței și o carboxihemoglobină de peste 25%. Utilizarea unui tratament
        hiperbaric HBOT reduce, de asemenea, incidența întârziată a simptomelor neuropsihiatrice.</p>

    <h3>Simptome comune</h3>

    <p>Deoarece monoxidul de carbon este un gaz invizibil fără miros sau gust, majoritatea oamenilor nu sunt conștienți de expunere până la
        apariția simptomelor. Semnele și simptomele includ:</p>
    <ul>
        <li>O durere de cap sâcâitoare;</li>
        <li>Slăbiciune;</li>
        <li>Amețeală;</li>
        <li>Greață sau vărsături;</li>
        <li>Dispnee;</li>
        <li>Confuzie inexplicabilă;</li>
        <li>Vedere neclară;</li>
        <li>Pierderea cunoştinţei;</li>
        <li>Convulsii.</li>
    </ul>

    <p>În cazurile severe de intoxicaţie, simptomele neuropsihiatrice, cum ar fi demența, psihozele, coreea și sindromul amnestic, se pot
        extinde la zile sau săptămâni după expunere și pot fi permanente.</p>
    <p>Dacă aveți oricare dintre aceste simptome și nu aveți nicio altă afecțiune care ar putea provoca simptomele, ieşiţi la aer curat cât
        mai curând posibil. Expunerea pe termen lung la monoxid de carbon poate duce la leziuni cerebrale permanente și chiar la deces. În
        casele în care sunt posibile scurgerile de monoxid de carbon (cum ar fi casele cu încălzire pe gaz sau șemineuri, grătare pe
        cărbune, încălzitoare de apă pe gaz, sobe pe gaz și generatoare portabile), detectoarele de monoxid de carbon vă pot salva
        viața.</p>

    <p>Te așteptăm la Centrul Medical Review din Cluj-Napoca cu specialiști în medicina hiperbară și un departament specializat în HBOT
        care, sub îndrumarea Dr. Mendea, îți poate pregăti un plan de tratament personalizat, adaptat 100% situației tale medicale. </p>

    <app-advert [link]="'/contact'" [title]="'Continuați spre pagina de contact'">
        SOLICITĂ O CONSULTAŢIE TELEFONICĂ CHIAR ASTĂZI!
    </app-advert>
</app-segment>


import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-gdpr',
    templateUrl: './gdpr.component.html',
    styleUrls: ['./gdpr.component.scss']
})
export class GdprComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { InputSanityValidator } from '../../_validators/input-sanity-validator.directive';
import { Meta, Title } from '@angular/platform-browser';
import { AlertService, SiteService } from '../../_services';
import { environment } from '../../../environments/environment';
import { ReCaptchaV3Service } from 'ngx-captcha';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    contactForm: FormGroup;
    siteKey: string = environment.siteKey;

    constructor(private formBuilder: FormBuilder,
                private siteService: SiteService,
                private alert: AlertService,
                private reCaptchaV3Service: ReCaptchaV3Service,
                private meta: Meta,
                private title: Title) {
        this.meta.removeTag('name=\'description\'');
        this.meta.removeTag('name=\'keywords\'');
        this.meta.addTags([
            {
                name: 'description',
                content: 'Contactati-ne pentru o intrevedere cu Dr.Demea si asigurati-va cel mai bun tratament pentru afectiunea Dvs.'
            },
            {name: 'keywords', content: 'Contact HBOT, Programare, Terapie, Oxigen hiperbaric, Numere de telefon'},
        ]);
        this.title.setTitle('Contactati-ne');

        this.contactForm = this.formBuilder.group({
            name: ['', [InputSanityValidator(), Validators.required, Validators.min(3)]],
            email: ['', [InputSanityValidator(), Validators.email]],
            phone: ['', [InputSanityValidator()]],
            message: ['', [InputSanityValidator()]],
            agreeWithTerms: [false, [Validators.required]],
        });
    }

    get f() {
        return this.contactForm.controls;
    }

    get(which: any): FormControl {
        if (typeof which === 'string') {
            return this.f[which] as FormControl;
        }
        return this.contactForm.get(which) as FormControl;
    }


    ngOnInit(): void {
    }

    onSubmit() {
        this.reCaptchaV3Service.execute(this.siteKey, 'login', (token) => {
            console.log('This is the login token: ', token);
            this.siteService.sendMessage(this.contactForm.value, token).subscribe({
                error: (error: any) => {
                    console.log('Error received while sending this email: ', error);
                    this.alert.error('Nu am reușit trimiterea mesajului... Încercați mai târziu...', false, 10000);
                },
                complete: () => {
                    console.log('Email was sent successfully...');
                    this.alert.success('Am trimis mesajul tău! Te vom contacta în cel mai scurt timp posibil...', false, 10000);
                    this.contactForm.reset();
                }
            });
        }, {
            useGlobalDomain: false
        });
    }
}
